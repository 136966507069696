/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValueType } from 'rsuite/esm/Checkbox';
import {
  deleteRejeCtonsultant,
  deleteRejectCorporation,
  deleteRejectSeeker,
  getAdminConsultantRegister,
  getAdminConsultantsRegisters,
  getAdminCorporationsRegisters,
  getAdminFoundersRegisters,
  getAdminIdeaRegisters,
  getAdminInqueryRegisters,
  getAdminInvestmentRegisters,
  getAdminMarketingRegisters,
  getAdminOrganizationalProjectsRegisters,
  getAdminOrganizationalTrainingsRegisters,
  getAdminRecruitmentRegisters,
  getAdminSeekersRegisters,
  getAllComments,
  getConsultantRequest,
  getConsultantsRequests,
  getCorporation,
  getCorporationRequest,
  getCorporationsRequests,
  getFounderRequest,
  getFoundersRequests,
  getIdeaRequest,
  getIdeaRequests,
  getInqueryRequest,
  getInqueryRequests,
  getInvestmentRequest,
  getInvestmentRequests,
  getMarketingRequest,
  getMarketingRequests,
  getOrganizationalProjectsRequest,
  getOrganizationalProjectsRequests,
  getOrganizationalTrainingsRequest,
  getOrganizationalTrainingsRequests,
  getPictures,
  getRecruitmentRequest,
  getRecruitmentRequests,
  getSeekerRequest,
  getSeekersRequests,
  getUserRequests,
  postAdminConsultantRegister,
  postAdminLoginWithPassword,
  postCorporation,
  postJobseeker,
  putAcceptConsultant,
  putAcceptCorporation,
  putAcceptSeeker,
  putAdminConsultantRegister,
  putChangeCommentStatus,
  getPayments,
  putAcceptResume,
  putRejectResume,
  getConsults,
  getSeeker
} from '../core/admin';
import { showToast } from '../utils/toast';
import { AdminConsultantRegisterModel } from '../models/admin';
import { deleteRoleFromUser, postAddRoleToUser } from '../core';
import { STATUS_CONVERTOR } from '../utils/helpers';
import { handleUpdateAny } from './global';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// const handlePostConsultant = async (payload: any, status: ValueType) => {
//   await postConsultant({ ...payload, Status: status });
// };
const handlePutConsultant = async (username: any, status: ValueType) => {
  // await postConsultant({ ...payload, Status: status });
  return handleUpdateAny({ Status: status }, 'Username', username, 'Consultants');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// const tryCreateOrUpdateConsultantByUsername = async (username: any, accept: any, payload = {}) => {
//   try {
//     const { status } = await getCorporation(username);
//     if (status == 200) {
//       return await handlePutConsultant(username, accept);
//     }
//   } catch (e) {
//     console.log(e);
//   }
//   return handlePostConsultant(payload, accept);
// };

export const handleAcceptConsultant = async (
  accept: ValueType,
  username: string,
  whyReject: string,
  wasAccepted: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => {
  try {
    console.log(wasAccepted);
    // todo add status for consultants
    const { data, status } = await putAcceptConsultant(accept, username, whyReject);
    if (status === 200) {
      console.log(accept, STATUS_CONVERTOR[accept]?.stillHasRole);
      if (STATUS_CONVERTOR[accept]?.stillHasRole) {
        // handle if accepted twice from backend too
        // console.log('add role adn create or update');
        // tryCreateOrUpdateConsultantByUsername(username, accept, payload);
        await postAddRoleToUser('Consultant', username);
      } else if (!STATUS_CONVERTOR[accept]?.stillHasRole) {
        console.log('remove role adn update');
        // todo
        await handlePutConsultant(username, accept);
        await deleteRoleFromUser('Consultant', username);
      } else {
        console.log('idk and postupdate update');
        // handlePostCorporation(payload, accept);
        await handlePutConsultant(username, accept);
      }
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleTerminateConsultant = async (username: string, whyReject: string) => {
  try {
    const { data, status } = await deleteRejeCtonsultant(username, whyReject);
    if (status === 200) {
      // if (accept === 'Accepted' && !wasAccepted) {
      //   // handle if accepted twice from backend too
      //   console.log('asadaasasd');
      await deleteRoleFromUser('Consultant', username);
      await handlePutConsultant(username, 'Terminated');

      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetConsultantsRequests = async () => {
  try {
    const { data, status } = await getConsultantsRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetConsults = async () => {
  try {
    const { data, status } = await getConsults();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetConsultantRequest = async (username: string) => {
  try {
    const { data, status } = await getConsultantRequest(username);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminConsultantsRegisters = async () => {
  try {
    const { data, status } = await getAdminConsultantsRegisters();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetAdminConsultantRegister = async (username: string) => {
  try {
    const { data, status } = await getAdminConsultantRegister(username);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePostAdminConsultantRegister = async (payload: AdminConsultantRegisterModel) => {
  try {
    const { data, status } = await postAdminConsultantRegister(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutAdminConsultantRegister = async (payload: AdminConsultantRegisterModel) => {
  try {
    const { data, status } = await putAdminConsultantRegister(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
// admin
export const handleAdminLoginWithPassword = async (username: string, password: string) => {
  try {
    const { data, status } = await postAdminLoginWithPassword(username, password);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// seeker
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleUpdateDesiedFieldsStatus = (status: ValueType, desiredFields: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  desiredFields.forEach((element: any) => {
    return handleUpdateAny({ Status: status }, 'ID', element.ID, 'JobSeekerDesiredFields');
  });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handlePostSeeker = async (payload: any, status: ValueType) => {
  await postJobseeker({ ...payload, Status: status, Birth_Date: new Date(payload.Birth_Date) });
};
// const handlePutSeeker = async (payload: any, status: ValueType) => {
//   await putJobseeker(username, { ...payload, Status: status, Birth_Date: new Date(payload.Birth_Date) });
// };
const handlePutSeeker = async (paylod: any, username: any, status: ValueType) => {
  // await postConsultant({ ...payload, Status: status });
  return handleUpdateAny({ Status: status }, 'Username', username, 'JobSeekers');
};
const handlePutSeekerDesierdFields = async (id: number, status: ValueType) => {
  return handleUpdateAny({ Status: status }, 'ID', id, 'JobSeekerDesiredFields');
};

const tryCreateOrUpdateSeekerByUsername = async (username: any, accept: any, payload: any = {}) => {
  const pld = {
    ...payload,
    MilitaryService: payload?.MilitaryService === null ? 'انتخاب' : payload?.MilitaryService
  };
  try {
    const { status } = await getSeeker(username);
    if (status == 200) {
      return await handlePutSeeker(pld, username, accept);
    }
  } catch (e) {
    console.log(e);
  }
  return handlePostSeeker(pld, accept);
};

export const handleAcceptSeeker = async (
  accept: ValueType,
  username: string,
  whyReject: string,
  wasAccepted: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  desiredFields: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
) => {
  try {
    const { data, status } = await putAcceptSeeker(accept, username, whyReject);
    if (status === 200) {
      await handleUpdateDesiedFieldsStatus(accept, desiredFields);
      // if (STATUS_CONVERTOR[accept]?.stillHasRole) {
      // }
      if (STATUS_CONVERTOR[accept]?.stillHasRole) {
        tryCreateOrUpdateSeekerByUsername(username, accept, payload);
        // handle if accepted twice from backend too
        await postAddRoleToUser('Seeker', username);
      } else if (!STATUS_CONVERTOR[accept]?.stillHasRole) {
        // todo
        tryCreateOrUpdateSeekerByUsername(username, accept, payload);
        await handlePutSeekerDesierdFields(payload.ID, accept);
        await deleteRoleFromUser('Seeker', username);
      } else {
        await handlePutSeekerDesierdFields(payload.ID, accept);
      }
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleTerminateSeeker = async (username: string, whyReject: string) => {
  try {
    const { data, status } = await deleteRejectSeeker(username, whyReject);
    if (status === 200) {
      // if (accept === 'Accepted' && !wasAccepted) {
      //   // handle if accepted twice from backend too
      //   console.log('asadaasasd');
      await deleteRoleFromUser('Seeker', username);
      tryCreateOrUpdateSeekerByUsername(username, 'Terminated', {});
      // remove from list
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetSeekersRequests = async () => {
  try {
    const { data, status } = await getSeekersRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetSeekerRequest = async (username: string) => {
  try {
    const { data, status } = await getSeekerRequest(username);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleGetAdminSeekersRegisters = async (payload: any) => {
  try {
    const { data, status } = await getAdminSeekersRegisters(payload);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: [], ok: true };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// corporation
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handlePostCorporation = async (payload: any, status: ValueType) => {
  await postCorporation({ ...payload, Status: status });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handlePutCorporation = async (username: any, status: ValueType, payload: any) => {
  // await postCorporation({ ...payload, Status: status });
  return handleUpdateAny(
    {
      Description: '',
      FullName: payload.FullName,
      State: payload.State,
      City: payload.City,
      Status: status
    },
    'Username',
    username,
    'Corporation'
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tryCreateOrUpdateCorporationByUsername = async (username: any, accept: any, payload = {}) => {
  try {
    const { status } = await getCorporation(username);
    if (status == 200) {
      return await handlePutCorporation(username, accept, payload);
    }
  } catch (e) {
    console.log(e);
  }
  return handlePostCorporation(payload, accept);
};

export const handleAcceptCorporation = async (
  accept: ValueType,
  username: string,
  whyReject: string,
  wasAccepted: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
) => {
  try {
    const { data, status } = await putAcceptCorporation(accept, username, whyReject);
    if (status === 200) {
      // await handleUpdateDesiedFieldsStatus(accept, desiredFields);
      // todo handle remove role
      if (STATUS_CONVERTOR[accept]?.stillHasRole) {
        // if (STATUS_CONVERTOR[accept]?.stillHasRole && wasAccepted) {
        // handle if accepted twice from backend too
        // console.log(wasAccepted);
        console.log('add role adn create or update');
        // handlePostCorporation(payload, accept);
        tryCreateOrUpdateCorporationByUsername(username, accept, payload);
        await postAddRoleToUser('Corporation', username);
      } else if (!STATUS_CONVERTOR[accept]?.stillHasRole) {
        console.log('remove role adn update');
        // todo
        await handlePutCorporation(username, accept, payload);
        await deleteRoleFromUser('Corporation', username);
      } else {
        console.log('idk and postupdate update');
        // handlePostCorporation(payload, accept);
        await handlePutCorporation(username, accept, payload);
      }

      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleTerminateCorporation = async (username: string, whyReject: string) => {
  try {
    const { data, status } = await deleteRejectCorporation(username, whyReject);
    if (status === 200) {
      // if (accept === 'Accepted' && !wasAccepted) {
      //   // handle if accepted twice from backend too
      //   console.log('asadaasasd');
      await deleteRoleFromUser('Corporation', username);
      await handlePutCorporation(username, 'Terminated', {});

      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetCorporationsRequests = async () => {
  try {
    const { data, status } = await getCorporationsRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetCorporationRequest = async (username: string) => {
  try {
    const { data, status } = await getCorporationRequest(username);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminCorporationsRegisters = async () => {
  try {
    const { data, status } = await getAdminCorporationsRegisters();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInqueryRequests = async () => {
  try {
    const { data, status } = await getInqueryRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInqueryRequest = async (username: string) => {
  try {
    const { data, status } = await getInqueryRequest(username);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminInqueryRegisters = async () => {
  try {
    const { data, status } = await getAdminInqueryRegisters();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetIdeaRequests = async () => {
  try {
    const { data, status } = await getIdeaRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetIdeaRequest = async (username: string) => {
  try {
    const { data, status } = await getIdeaRequest(username);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminIdeaRegisters = async () => {
  try {
    const { data, status } = await getAdminIdeaRegisters();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInvestmentRequests = async () => {
  try {
    const { data, status } = await getInvestmentRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetInvestmentRequest = async (username: string) => {
  try {
    const { data, status } = await getInvestmentRequest(username);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminInvestmentRegisters = async () => {
  try {
    const { data, status } = await getAdminInvestmentRegisters();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetRecruitmentRequests = async () => {
  try {
    const { data, status } = await getRecruitmentRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetRecruitmentRequest = async (username: string) => {
  try {
    const { data, status } = await getRecruitmentRequest(username);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminRecruitmentRegisters = async () => {
  try {
    const { data, status } = await getAdminRecruitmentRegisters();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetMarketingRequests = async () => {
  try {
    const { data, status } = await getMarketingRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetMarketingRequest = async (username: string) => {
  try {
    const { data, status } = await getMarketingRequest(username);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminMarketingRegisters = async () => {
  try {
    const { data, status } = await getAdminMarketingRegisters();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetOrganizationalProjectsRequests = async () => {
  try {
    const { data, status } = await getOrganizationalProjectsRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetOrganizationalProjectsRequest = async (username: string) => {
  try {
    const { data, status } = await getOrganizationalProjectsRequest(username);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminOrganizationalProjectsRegisters = async () => {
  try {
    const { data, status } = await getAdminOrganizationalProjectsRegisters();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetOrganizationalTrainingsRequests = async () => {
  try {
    const { data, status } = await getOrganizationalTrainingsRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetOrganizationalTrainingsRequest = async (username: string) => {
  try {
    const { data, status } = await getOrganizationalTrainingsRequest(username);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminOrganizationalTrainingsRegisters = async () => {
  try {
    const { data, status } = await getAdminOrganizationalTrainingsRegisters();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetFoundersRequests = async () => {
  try {
    const { data, status } = await getFoundersRequests();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetFounderRequest = async (username: string) => {
  try {
    const { data, status } = await getFounderRequest(username);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminFoundersRegisters = async () => {
  try {
    const { data, status } = await getAdminFoundersRegisters();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetUserRequests = async (type: string) => {
  try {
    const { data, status } = await getUserRequests(type);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
      return { data: data.Data, ok: false };
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data?.Message?.FA);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetPictures = async () => {
  try {
    const { data, status } = await getPictures();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      return { data: [], ok: false };
    }
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAllComments = async () => {
  try {
    const { data, status } = await getAllComments();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      return { data: [], ok: false };
    }
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutChangeCommentStatus = async (
  consultId: string | undefined,
  isAccepted: boolean
) => {
  try {
    const { data, status } = await putChangeCommentStatus(consultId, isAccepted);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetPayments = async () => {
  try {
    const { data, status } = await getPayments();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      return { data: [], ok: false };
    }
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutRejectResume = async (username: string) => {
  try {
    const { data, status } = await putRejectResume(username);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      return { data: [], ok: false };
    }
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutAcceptResume = async (username: string) => {
  try {
    const { data, status } = await putAcceptResume(username);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      return { data: [], ok: false };
    }
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
