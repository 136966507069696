// import { useState } from 'react';
import LocationIcon from '../../../assets/icons/consultants/location.svg';
import StarIcon from '../../../assets/icons/consultants/star.svg';
import LikeIcon from '../../../assets/icons/consultants/like.svg';
import CheckIcon from '../../../assets/icons/consultants/check.svg';
import EducationIcon from '../../../assets/icons/consultants/education.svg';
import { Col, FlexboxGrid, Button } from 'rsuite';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { handleGetAdminConsultantRegister } from '../../../api-handler/admin';
import { AdminConsultantRegisterModel } from '../../../models/admin';
import { CustomLoader } from './../../../components/basic/loader';
import { RequestConsultByUser } from './request-consult';
import { useUser } from '../../../contexts/user';
import {
  handleGetConsultantConsultationBackground,
  handleGetConsultantEducationBackground,
  handleGetConsultantSocial,
  handleGetConsultantWorkBackground,
  handleGetUserProfilePictureByUsername
} from '../../../api-handler';
import { ConsultantComments } from './consultant-comments';
import { base64ToJson, HandleNum2persian } from '../../../utils/helpers';
import { UPLOAD_FILE_URL } from '../../../core';
import { ConsultantWorkBackgroundView } from './view/consultant-work-background';
import { Spacer } from '../../../components/basic/spacer';
import { ConsultantEducationBackgroundView } from './view/consultant-education-background';
import { ConsultantConsultationBackgroundView } from './view/consultant-consultation-background';

export const SingleConsultant = () => {
  // const [data, setData] = useState([1, 2, 3]);
  const { isUserRole, user } = useUser();
  const { username } = useParams();
  const navigate = useNavigate();
  const [showRequestConsult, setShowRequestConsult] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AdminConsultantRegisterModel>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [social, setSocial] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [profilePic, setProfilePic] = useState<any>('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetAdminConsultantRegister(username ?? '');
      const res2 = await handleGetConsultantSocial(username ?? '');
      const res3 = await handleGetUserProfilePictureByUsername(username ?? '');

      if (res.ok) {
        setData(res.data);
      }
      if (res2.ok) {
        setSocial(res2.data);
      }
      if (res3.ok) {
        if (res3.data.Status === 'Accepted') {
          setProfilePic(res3.data.PictureLink);
        }
      }
      setLoading(false);
    })();
  }, [username]);

  const [consultantWorkBackground, setConsultantWorkBackground] = useState([]);
  const [consultantEducationBackground, setConsultantEducationBackground] = useState([]);
  const [consultantConsultationBackground, setConsultantConsultationBackground] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await handleGetConsultantWorkBackground(username);
      if (res.ok) {
        setConsultantWorkBackground(res.data);
      } else {
        setConsultantWorkBackground([]);
      }
    })();
  }, [username]);
  useEffect(() => {
    (async () => {
      const res = await handleGetConsultantEducationBackground(username);
      if (res.ok) {
        setConsultantEducationBackground(res.data);
      } else {
        setConsultantEducationBackground([]);
      }
    })();
  }, [username]);
  useEffect(() => {
    (async () => {
      const res = await handleGetConsultantConsultationBackground(username);
      if (res.ok) {
        setConsultantConsultationBackground(res.data);
      } else {
        setConsultantConsultationBackground([]);
      }
    })();
  }, [username]);

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-full">
          {loading ? (
            <CustomLoader />
          ) : data ? (
            <>
              <div className="flex">
                {profilePic ? (
                  <div
                    className="w-[100px] aspect-square rounded-full flex items-center justify-center overflow-hidden"
                    style={{ aspectRatio: 1 }}>
                    <img
                      src={`${UPLOAD_FILE_URL}?${base64ToJson(profilePic)[0]?.url}&Attachment=False`}
                      style={{ aspectRatio: 1 }}
                      alt=""
                      className="w-full"
                    />
                  </div>
                ) : (
                  <div className="w-[100px] aspect-square rounded-full bg-[--rs-primary-50]"></div>
                )}
                <div className="mr-3 mt-2">
                  <h4 className="text-h4 text-[--rs-primary-800]">{data.FullName}</h4>
                  <p className="text-b1 mb-0 mt-1 text-[--rs-primary-400]">{data.Job}</p>
                  <div className="flex justify-between mt-2">
                    <div className="flex items-center">
                      <img src={StarIcon} alt="star" />
                      <span className="text-b2 text-[--rs-primary-500] mr-1">
                        <strong>4.9</strong> (21 نظر)
                      </span>
                    </div>
                    <div className="flex items-center mr-2">
                      <img src={LikeIcon} alt="like" />
                      <span className="text-b2 text-[--rs-success-800] mr-2">
                        100٪پیشنهاد کاربران
                      </span>
                    </div>
                    <div className="flex items-center mr-2">
                      <img src={CheckIcon} alt="check" />
                      <span className="text-b2 text-[--rs-info-700] mr-2">120 نوبت موفق</span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mr-auto mb-10">
                  <div className="flex items-center">
                    <img src={LocationIcon} alt="location" />
                    <p className="text-b1 text-[--rs-primary-400] ml-3">{data.City}</p>
                  </div>
                  <Button
                    appearance="primary"
                    className="mx-auto min-w-[50%] mb-2 inline-block"
                    type="button"
                    disabled={isUserRole('Consultant')}
                    onClick={() => {
                      if (user.isLoggedIn) {
                        const element = document.getElementById('reserve');
                        element?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'end',
                          inline: 'nearest'
                        });
                        setShowRequestConsult(true);
                      } else {
                        navigate(`/auth/login?redirect=/consultants/${username}`);
                      }
                    }}>
                    رزرو وقت مشاوره
                  </Button>
                </div>
              </div>
              <div className="flex items-center mt-6">
                <img src={EducationIcon} alt="education" />
                <p className="text-b1 text-[--rs-primary-400] mr-1 ">{data.Education}</p>
              </div>
              <div className="mt-6 mb-6">
                <h5 className="mb-4 font-bold">زمینه های مشاوره: </h5>
                <p className="text-b1 flex flex-wrap text-[--rs-primary-400] mr-1 ">
                  {data.Category.split(',').map((item: string, index: number) => (
                    <span
                      className="px-4 py-1 bg-[--rs-primary-100] text-[--rs-primary-500] font-bold ml-1 rounded-md"
                      key={index}>
                      {item}{' '}
                    </span>
                  ))}
                </p>
              </div>
              <FlexboxGrid className="mx-auto w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] text-center font-fd">
                <FlexboxGrid.Item
                  as={Col}
                  colspan={12}
                  className="max-w-[100%]  mt-3 border-[2px] border-[--rs-primary-300] p-2 bg-[--rs-primary-50] font-bold text-[--rs-primary-500] border-b-[1px] border-l-[1px]">
                  مدت زمان جلسه
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  as={Col}
                  colspan={12}
                  className="max-w-[100%]  mt-3 border-[2px] border-[--rs-primary-300]  p-2 bg-[--rs-primary-50] font-bold text-[--rs-primary-500] border-b-[1px] border-r-[1px]">
                  قیمت
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  as={Col}
                  colspan={12}
                  className="max-w-[100%] border-[1px] border-[--rs-primary-300]  p-2 border-r-[2px] text-[--rs-primary-400]">
                  <div className="py-[10px]">یک ساعت</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  as={Col}
                  colspan={12}
                  className="max-w-[100%] border-[1px] border-[--rs-primary-300]  p-2 border-l-[2px]">
                  <strong className="text-[--rs-primary-500] ">
                    {data.PriceFor1H.toLocaleString()} تومان <br />
                    <div
                      title={HandleNum2persian(data.PriceFor1H, 'تومان')}
                      className="text-[--rs-primary-300] font-normal"
                      style={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxHeight: '20px',
                        textOverflow: 'ellipsis'
                      }}>{`(${HandleNum2persian(data.PriceFor1H, 'تومان')})`}</div>
                  </strong>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  as={Col}
                  colspan={12}
                  className="max-w-[100%] border-[2px] border-[--rs-primary-300]  p-2 border-t-[1px] border-l-[1px] text-[--rs-primary-400]">
                  <div className="py-[10px]">نیم ساعت</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  as={Col}
                  colspan={12}
                  className="max-w-[100%] border-[2px] border-[--rs-primary-300]  p-2 border-t-[1px] border-r-[1px]">
                  <strong className="text-[--rs-primary-500] ">
                    {data.PriceFor30M.toLocaleString()} تومان <br />
                    <div
                      title={HandleNum2persian(data.PriceFor30M, 'تومان')}
                      className="text-[--rs-primary-300] font-normal"
                      style={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxHeight: '20px',
                        textOverflow: 'ellipsis'
                      }}>{`(${HandleNum2persian(data.PriceFor30M, 'تومان')})`}</div>
                  </strong>
                </FlexboxGrid.Item>
              </FlexboxGrid>
              <h5 className="text-h5 text-[--rs-primary-500] mt-5">درباره من</h5>
              <p className="text-b1 text-[--rs-primary-700] mt-1">{social.Description}</p>
              {/* <h5 className="text-h5 text-[--rs-primary-500] mt-5"></h5>
              <p className="text-b1 text-[--rs-primary-700] mt-1">{data.Description}</p> */}
            </>
          ) : null}
        </div>

        <div id="reserve">
          {showRequestConsult ? (
            <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-ful mt-4">
              <h4>رزرو وقت  مشاوره</h4>
              <RequestConsultByUser consultantUsername={username ?? ''} social={social} />
            </div>
          ) : null}
        </div>
      </FlexboxGrid.Item>
      <Spacer s={4} />
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        {/* <Consultant /> */}
        <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-full">
          <ConsultantWorkBackgroundView data={consultantWorkBackground} />
          <Spacer s={4} />
          <ConsultantEducationBackgroundView data={consultantEducationBackground} />
          <Spacer s={4} />
          <ConsultantConsultationBackgroundView data={consultantConsultationBackground} />
        </div>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        <ConsultantComments />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};
