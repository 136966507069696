/* eslint-disable @typescript-eslint/no-explicit-any */
import { faIR } from 'date-fns-jalali/locale';
import { FormatDateToBeautifulMonthAndYear } from '../../../../utils/helpers';
import { Divider } from 'rsuite';

export const ConsultantWorkBackgroundView = ({ data }: any) => {
  return (
    <>
      {/* <h4 className="mb-4">سوابق شفلی</h4> */}
      <div className="w-full">
        <Divider className="text-[20px] font-bold">سوابق شغلی</Divider>
      </div>
      <ol style={{ listStyle: 'auto', paddingRight: '12px' }}>
        {data?.map((work: any) => {
          return (
            <li key={work.id} className="mb-4">
              <p className="text-[16px]">
                به عنوان <strong>{work.Role}</strong> در{' '}
                <strong>{work.CurrentWorkName ?? '-'}</strong> از{' '}
                <strong>{FormatDateToBeautifulMonthAndYear(work.Start_Date, faIR)}</strong>{' '}
                {work.CurrentWork ? (
                  ''
                ) : (
                  <>
                    به مدت <strong>{work.Duration}</strong>
                  </>
                )}{' '}
                مشغول به کار <strong>{work.CurrentWork ? 'هستم' : 'بوده ام'}</strong>
                <br />
                <strong>{work.CurrentWorkName}</strong>، در کشور <strong>{work.Country}</strong>
                {'، '}
                {work.State ? (
                  <>
                    {' '}
                    استان <strong>{work.State}</strong>،{' '}
                  </>
                ) : (
                  ''
                )}
                شهر <strong>{work.City}</strong>، <strong>{work.Address}</strong> واقع شده است.
              </p>
            </li>
          );
        })}
      </ol>
    </>
  );
};
