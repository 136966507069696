import { useEffect, useState } from 'react';
import { handleGetBoughtResumes } from '../../api-handler';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { FormatDate, sortData } from '../../utils/helpers';
import { Button, Loader } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { faIR } from 'date-fns-jalali/locale';

export const BoughtResume = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  // const [loading] = useState(false);
  const [sortColumn, setSortColumn] = useState('id');
  const [sortType, setSortType] = useState();
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setTableLoading(true);
      const res = await handleGetBoughtResumes();
      if (res.ok) {
        setData(res.data);
      }
      setTableLoading(false);
    })();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSortColumn = (sc: any, st: any) => {
    setTableLoading(true);
    setTimeout(() => {
      setTableLoading(false);
      setSortColumn(sc);
      setSortType(st);
    }, 500);
  };

  return (
    <>
      <h4 className="text-h4">رزومه های خریداری شده</h4>
      <Table
        data={sortData(data, sortColumn, sortType)}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={tableLoading}
        // affixHeader
        renderLoading={() => (
          <div className="flex items-center justify-center h-[100%]">
            <Loader size="md" />
          </div>
        )}
        renderEmpty={() => (
          <div className="flex items-center justify-center h-[100%]">رزومه ای خریداری نشده</div>
        )}
        autoHeight
        affixHorizontalScrollbar>
        <Column width={100} verticalAlign="middle" align="center">
          <HeaderCell>شماره</HeaderCell>
          <Cell dataKey="ID" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نام کاربری</HeaderCell>
          <Cell dataKey="JobSeekerUsername" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نام و نام خانوادگی</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {rowData.UserInfo}</>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center font-fd">
          <HeaderCell>زمان</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <>{FormatDate(rowData.TimeStamp, faIR)}</>;
            }}
          </Cell>
        </Column>
        <Column width={90} verticalAlign="middle" align="center">
          <HeaderCell>مشاهده</HeaderCell>
          <Cell dataKey="">
            {(rowData) => (
              <Button
                appearance="primary"
                size="xs"
                type="button"
                onClick={() => navigate(`/seekers/${rowData.JobSeekerUsername}`)}>
                مشاهده
              </Button>
            )}
          </Cell>
        </Column>
      </Table>
    </>
  );
};
