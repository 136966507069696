// import { Button } from 'rsuite';
// import { useState } from 'react';
import { Button, Divider, Input, Radio, RadioGroup } from 'rsuite';
import { Spacer } from '../../../components/basic/spacer';
import { ConsultantConsultationBackground } from '../../consultants/consultant-consultation-background';
import { ConsultantEducationBackground } from '../../consultants/consultant-education-background';
import { ConsultantPersonal } from '../../consultants/consultant-personal';
import { ConsultantSocial } from '../../consultants/consultant-social';
import { ConsultantWorkBackground } from '../../consultants/consultant-work-background';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  handleAcceptConsultant,
  handleGetConsultantRequest,
  handleTerminateConsultant
} from '../../../api-handler';
import { ValueType } from 'rsuite/esm/Checkbox';
import { AdminRegisterConsultant } from './register-consultatn';
import { useNavigate } from 'react-router-dom';
import { STATUS_CONVERTOR } from '../../../utils/helpers';
import { showToast } from '../../../utils/toast';
import { UserBasicDataView } from '../users/basic-data';

export const AdminRegisterConsultantSingle = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { username } = useParams();
  const [accept, setAccept] = useState<ValueType>('Pending');
  const [wasAccept, setWasAccept] = useState<ValueType>('Pending');
  const [whyReject, setWhyReject] = useState('');

  const onAcceptConsultant = useCallback(async () => {
    if (username) {
      setLoading(true);
      if (accept === 'Terminated') {
        const res = await handleTerminateConsultant(username, whyReject);
        if (res.ok) {
          showToast(
            `وضعیت با موفقیت به "${STATUS_CONVERTOR[accept]?.default}" تغییر کرد`,
            'success'
          );
          navigate('/admin/register-consultant-list');
        }
        setLoading(false);
        return;
      }
      const res = await handleAcceptConsultant(
        accept,
        username,
        whyReject,
        wasAccept === 'Accepted'
      );
      if (res.ok) {
        showToast(`وضعیت با موفقیت به "${STATUS_CONVERTOR[accept]?.default}" تغییر کرد`, 'success');
        navigate('/admin/register-consultant-list');
      }
      setLoading(false);
    }
  }, [username, accept, whyReject, wasAccept]);

  useEffect(() => {
    (async () => {
      const res = await handleGetConsultantRequest(username ?? '');
      if (res.ok) {
        setAccept(res.data.Status);
        setWasAccept(res.data.Status);
        setWhyReject(res.data.Description);
      }
    })();
  }, [username]);

  return (
    <>
      <h4 className="text-h4"></h4>
      {username ? (
        <>
          <UserBasicDataView username={username ?? '-1'} />
          <ConsultantPersonal disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <ConsultantWorkBackground disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <ConsultantEducationBackground disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <ConsultantConsultationBackground
            disabledMode={true}
            isAdmin={true}
            username={username}
          />
          <Spacer s={4} />
          <ConsultantSocial disabledMode={true} isAdmin={true} username={username} />

          <Spacer s={2} />
          <Divider />
          <h4 className="text-h4">وضعیت مورد نظر را انتخاب کنید</h4>
          <RadioGroup
            className="flex mb-4"
            value={accept}
            onChange={(value: ValueType, _event: SyntheticEvent<Element, Event>) =>
              setAccept(value)
            }>
            <div className="flex">
              <Radio className="" value={'Accepted'} dir="rtl">
                تایید
              </Radio>
              <Radio className="" value={'Pending'}>
                در انتظار تایید
              </Radio>
              <Radio className="" value={'Rejected'}>
                نیاز به اصلاح اطلاعات
              </Radio>
              <Radio className="" value={'Suspended'}>
                تعلیق
              </Radio>
              <Radio className="" value={'Terminated'}>
                مختومه (پاک کردن تمام اطلاعات)
              </Radio>
            </div>
          </RadioGroup>
          {accept === 'Accepted' ? (
            <>
              <AdminRegisterConsultant
                username={username}
                onAcceptConsultant={onAcceptConsultant}
              />
              {/* <Button
                appearance="primary"
                className="ml-auto mt-8 w-[120px]"
                type="button"
                onClick={() => onAcceptConsultant()}
                loading={loading}>
                تایید
              </Button> */}
            </>
          ) : accept === 'Rejected' || accept === 'Terminated' || accept === 'Suspended' ? (
            <>
              <span>علت</span>
              <Input as="textarea" value={whyReject} onChange={setWhyReject} rows={4} />
              <Button
                appearance="primary"
                className="mr-auto mt-8 w-[120px]"
                type="button"
                onClick={() => onAcceptConsultant()}
                loading={loading}>
                {accept === 'Terminated' ? 'رد' : 'تعلیق'} مشاور
              </Button>
            </>
          ) : (
            <Button
              appearance="primary"
              className="mr-auto mt-8 w-[120px]"
              type="button"
              onClick={() => onAcceptConsultant()}
              loading={loading}>
              ثبت در انتظار
            </Button>
          )}
        </>
      ) : null}
    </>
  );
};
