import { Button, Col, Form, Input, Schema } from 'rsuite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlexboxGrid } from 'rsuite';

// 'EventTitle',
// 'DateAndTime',
// 'Location',
// 'Topics',
// 'RegistrationDeadline',
// 'Fee',
// 'Speaker',
// 'Capacity'
const { StringType } = Schema.Types;
const model = Schema.Model({
  EventTitle: StringType('').isRequired('الزامی است!'),
  DateAndTime: StringType('').isRequired('الزامی است!'),
  Location: StringType('').isRequired('الزامی است!'),
  Topics: StringType(''),
  Fee: StringType('').isRequired('الزامی است!'),
  Capacity: StringType('').isRequired('الزامی است!'),
  Speaker: StringType('').isRequired('الزامی است!')
});

import { useNavigate, useParams } from 'react-router-dom';
import { showToast } from '../../../utils/toast';
import TextField from '../../../components/form/textfield';
// import ConsultantFieldsOptions from '../../../data/consultant/consultant-fileds.json';
import TextAreaField from './../../../components/form/textarea-field';

import { base64ToJson, convertDateToSlashi, jsonToBase64 } from '../../../utils/helpers';
import Field from '../../../components/form/field';
import { Datepicker } from '@ijavad805/react-datepicker';
import {
  handleDeleteWorkshop,
  handleGetWorkshop,
  handlePostWorkshop,
  handlePutWorkshop
} from '../../../api-handler';
import { UploadField } from '../../../components/form/uploader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AddWorkshop = (props: { editMode?: boolean }) => {
  const { eventID } = useParams();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedWSPICUrl, setUploadedWSPICUrl] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initWSPICJson, setInitWSPICJson] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeStamp, setTimeStamp] = useState<any>(new Date());
  // const [editMode, setEditMode] = useState(false);
  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    if (!formValue) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const WSPICJson: any = [...initWSPICJson];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(uploadedWSPICUrl).forEach((file: any) => {
      WSPICJson.push(uploadedWSPICUrl[file]);
    });
    const WSPICEncoded = jsonToBase64(WSPICJson);

    let payload = {
      ...formValue,
      PosterLink: WSPICEncoded,
      RegistrationDeadline: timeStamp.toISOString()
    };
    if (payload.File) {
      delete payload.File;
    }
    if (props.editMode) {
      payload = { ...payload, eventID };
    }

    setLoading(true);
    if (props.editMode) {
      const res = await handlePutWorkshop(payload);
      if (res.ok) {
        showToast('رویداد با موفقیت بروزرسانی شد!', 'success');
        navigate('/events');
      }
    } else {
      const res = await handlePostWorkshop(payload);
      if (res.ok) {
        showToast('رویداد با موفقیت ثبت شد!', 'success');
        navigate('/events');
      }
    }
    setLoading(false);
  }, [
    formValue,
    formError,
    formRef,
    props.editMode,
    eventID,
    initWSPICJson,
    uploadedWSPICUrl,
    timeStamp
  ]);

  // 'EventTitle',
  // 'DateAndTime',
  // 'Location',
  // 'Topics',
  // 'RegistrationDeadline',
  // 'Fee',
  // 'Speaker',
  // 'Capacity'

  const handleGetInitialData = async (id: string | null | undefined) => {
    setUploadedWSPICUrl({});
    setLoading(true);
    const res = await handleGetWorkshop(id);
    if (res.ok) {
      setFormValue(res.data);
      setTimeStamp(new Date(res.data.RegistrationDeadline));
      setInitWSPICJson(base64ToJson(res.data?.PosterLink));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (props.editMode && eventID) {
      (async () => {
        console.log(eventID);
        await handleGetInitialData(eventID);
      })();
    }
  }, [eventID]);
  return (
    <>
      <h4 className="text-h4">{props.editMode ? 'ویرایش رویداد' : 'افزودن رویداد'}</h4>
      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={model}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="EventTitle"
              label="عنوان رویداد"
              dir="rtl"
              full
              req
              placeholder=""
              accepter={Input}
              type="text"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="Location"
              label="مکان"
              dir="rtl"
              full
              req
              placeholder=""
              accepter={Input}
              type="text"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="DateAndTime"
              label="تاریخ و زمان"
              dir="rtl"
              full
              req
              placeholder="شنبه و یکشنبه 21 و 22 مهر ماه 1403 - ساعت 10 الی 12"
              accepter={Input}
              type="text"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="Speaker"
              label="مدرس/سخنران"
              dir="rtl"
              full
              req
              placeholder="دکتر علی رمضانی"
              accepter={Input}
              type="text"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="Fee"
              label="هزینه نام‌نویسی"
              dir="ltr"
              full
              req
              placeholder="450000"
              accepter={Input}
              type="text"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextField
              name="Capacity"
              label="ظرفیت"
              dir="ltr"
              full
              req
              placeholder="200"
              accepter={Input}
              type="text"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              accepter={Datepicker}
              className="font-fd"
              name="RegistrationDeadline"
              lang={'fa'}
              theme={'green'}
              format={'D MMMM yyyy'}
              value={convertDateToSlashi(timeStamp)}
              input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(val: any) => setTimeStamp(new Date(val))}
              label="تاریخ پایان ثبت نام"
              dir="ltr"
              full
              req
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="Topics"
              label="سرفصل ها"
              dir="rtl"
              full
              req
              placeholder=""
              accepter={Input}
              type="text"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <UploadField
              label="تصویر رویداد"
              req
              full
              setLoadingUpload={setLoadingUpload}
              name="WSPIC"
              fileName="WSPIC"
              domain="Uploads/"
              initJson={initWSPICJson}
              setInitJson={setInitWSPICJson}
              uploadedUrl={uploadedWSPICUrl}
              setUploadedUrl={setUploadedWSPICUrl}
              readOnly={false}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <div className="flex justify-between">
          <Button
            appearance="ghost"
            className="mt-8"
            type="button"
            onClick={async () => {
              await handleDeleteWorkshop(eventID ?? '-1');
              navigate('/events');
            }}
            loading={loading || loadingUpload}>
            حذف رویداد
          </Button>
          <Button
            appearance="primary"
            className="mt-8"
            type="submit"
            loading={loading || loadingUpload}>
            {props.editMode ? 'ویرایش رویداد' : 'ثبت رویداد'}
          </Button>
        </div>
      </Form>
    </>
  );
};
