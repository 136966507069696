/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'rsuite';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Plus, TickSquare } from 'react-iconly';
import { useEffect, useState } from 'react';

export const AfterPayment = ({ isCv, isConsult }: { isCv: boolean; isConsult: boolean }) => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams] = useSearchParams();

  const [eventId, setEventId] = useState<any>();
  const [refID, setRefID] = useState<any>();

  useEffect(() => {
    const status = searchParams.get('status');
    // setOrderId(searchParams.get('order_id') ?? '');
    if (status && (status == '200' || status == '201')) {
      setIsSuccess(true);
      setEventId(searchParams.get('WorkShopID'));
      setRefID(searchParams.get('RefID'));
    } else {
      setIsSuccess(false);
    }
  }, []);
  return (
    <>
      <div className="container mt-3">
        <div>
          <div style={{ minHeight: '60vh' }} className="d-flex flex-column justify-content-between">
            {isSuccess ? (
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ minHeight: '60vh' }}>
                <TickSquare set="bold" size={80} primaryColor="#37e666" />
                <h5 className="mt-4">پرداخت شما با موفقیت انجام شد.</h5>
                <h5 className="mt-4">شماره پرداخت: {refID} </h5>
                <Button
                  onClick={() => {
                    if (isCv) {
                      navigate(`/seekers/${searchParams.get('JobSeekerUsername')}`);
                    } else if (isConsult) {
                      navigate(`/user/consult-sessions/${searchParams.get('ID')}`);
                    } else {
                      navigate(`/events/${eventId}`);
                    }
                  }}
                  className="mt-5"
                  appearance="primary"
                  type="submit">
                  مشاهده {isCv ? 'کارجو' : isConsult ? 'جلسه' : 'رویداد'}
                </Button>
              </div>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ minHeight: '60vh' }}>
                <Plus
                  style={{ transform: 'rotate(45deg)' }}
                  set="bold"
                  size={80}
                  primaryColor="#ff2255"
                />
                <h5 className="mt-4">پرداخت شما ناموفق بود!</h5>
                <Button
                  onClick={() => {
                    if (isCv) {
                      navigate(`/seekers`);
                    } else if (isConsult) {
                      navigate(`/user/requests`);
                    } else {
                      navigate(`/events`);
                    }
                  }}
                  className="mt-5"
                  appearance="primary"
                  type="submit">
                  مشاهده {isCv ? 'کارجوها' : isConsult ? 'جلسات مشاوره' : 'رویدادها'}
                </Button>
                <div className="mt-4">
                  {/* <Button
                    className="login-button mx-1 mt-2 d-block d-sm-inline"
                    style={{
                      fontWeight: 700,
                      minWidth: 150,
                      padding: '15px 20px',
                      borderRadius: 100
                    }}
                    appearance="default"
                    type="submit"
                    loading={loading}
                    disabled={loading}
                    onClick={onCheckout}>
                    دوباره امتحان کنید
                  </Button> */}
                  {/* <Button
                    onClick={() => navigate('/')}
                    className="login-button error-button mx-1 mt-2 d-block d-sm-inline"
                    style={{
                      fontWeight: 700,
                      minWidth: 150,
                      padding: '15px 20px',
                      borderRadius: 100
                    }}
                    appearance="default"
                    type="submit">
                    انصراف
                  </Button> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
