/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, Input, InputPicker, Schema, Checkbox } from 'rsuite';
import TextField from '../../../components/form/textfield';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlexboxGrid } from 'rsuite';
import Field from '../../../components/form/field';
import TextAreaField from '../../../components/form/textarea-field';
import { showToast } from '../../../utils/toast';
import {
  handlePostConsultantWorkBackground,
  handlePutConsultantWorkBackground
} from '../../../api-handler';
import { PostConsultantWorkBackgroundModel } from '../../../models';
import { useNavigate } from 'react-router-dom';
import ConsultantDurationOptions from '../../../data/consultant/consultant-duration.json';
import ConsultantJobOptions from '../../../data/consultant/consultant-job.json';
import { CountryStateCity } from '../../../components/form/country-state-city';
import { ROUTES } from '../../../router';
import { Datepicker } from '@ijavad805/react-datepicker';
import { convertDateToSlashi } from '../../../utils/helpers';

const { StringType } = Schema.Types;
const model = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Role: StringType('').isRequired('الزامی است!'),
  // Country: StringType('').isRequired('الزامی است!'),
  State: StringType(''),
  City: StringType('').isRequired('الزامی است!'),
  // Start_Date: StringType('').isRequired('الزامی است!'),
  Address: StringType('').isRequired('الزامی است!'),
  Duration: StringType('')
});
const defaultFormValue = {
  Name: '',
  Role: '',
  State: '',
  City: '',
  Address: '',
  Duration: '',
  Start_Date: new Date()
};
export const WorkBackgroundForm = (props: {
  readonly?: boolean;
  handleAddDoingData: (workData: PostConsultantWorkBackgroundModel) => void;
  defaultValue?: PostConsultantWorkBackgroundModel | null;
  hideNextButton?: boolean;
  forceReadOnly?: boolean;
  disabledMode?: boolean;
  hasEditMode?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isAdmin: boolean;
  onDelete: (id: string | number) => void;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabledMode, setDisabledMode] = useState(props.hasEditMode);
  const [forceReadOnly] = useState(props.forceReadOnly);
  const [readOnly, setReadOnly] = useState(props.readonly);
  const [currentDoing, setCurrentDoing] = useState(props.defaultValue?.CurrentWork);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>(props.defaultValue ?? defaultFormValue);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultValue, setDefaultValue] = useState<any>(props.defaultValue ?? defaultFormValue);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeStamp, setTimeStamp] = useState<any>(new Date());

  const handleSubmit = useCallback(
    async (isNext: boolean, updateMode: boolean) => {
      if (formRef.current && !formRef.current.check()) {
        console.log('formError', formError);
        return;
      }
      // !start date has problem from backend manage
      if (!formValue) return;
      const payload = {
        ...formValue,
        CurrentWork: currentDoing === true,
        Start_Date: timeStamp,
        // Country: 'ایران'
        Country: formValue['Country-t'] == 'ایران' ? 'ایران' : formValue.Country ?? 'ایران'
      };
      if (payload['Country-t']) delete payload['Country-t'];

      if (!currentDoing) {
        if (!payload.Duration) {
          setFormError((pre) => {
            return { ...pre, Duration: 'الزامی است!' };
          });
          return;
        }
      }
      setLoading(true);
      if (updateMode) {
        const ress = await handlePutConsultantWorkBackground(payload, 'ID', defaultValue.ID);
        if (ress.ok) {
          showToast('فرم به روزرسانی شد!', 'success');
          setDisabledMode(true);
          // setForceReadOnly(true);
          setReadOnly(true);
          setLoading(false);
          setDefaultValue(payload);
          if (isNext) {
            navigate('/consultant/education');
            return;
          }
        }
        setLoading(false);
        return;
      }
      const res = await handlePostConsultantWorkBackground(payload);
      setLoading(false);
      if (res.ok) {
        showToast('سوابق شغلی ثبت شد!', 'success');
        // or getting submitted data from backend
        if (isNext) {
          navigate('/consultant/education');
          return;
        }
        props.handleAddDoingData({ ...payload, ID: res.data.ID });
        setCurrentDoing(false);
        setFormValue(defaultFormValue);
      }
    },
    [formValue, formError, timeStamp, formRef, currentDoing, defaultValue]
  );

  useEffect(() => {
    const startDate: any = props?.defaultValue?.Start_Date ?? null;
    setTimeStamp(startDate ? new Date(startDate) : new Date());
  }, [props.defaultValue]);

  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={model}
      onSubmit={() => handleSubmit(false, false)}
      formValue={formValue}
      checkTrigger="change"
      className="mt-6 text-dark"
      readOnly={forceReadOnly ?? loading}
      disabled={disabledMode ?? props.isAdmin}>
      <FlexboxGrid
        className={'bg-[--rs-primary-50] rounded-md border-[1px] border-[--rs-primary-500] p-3'}>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] !px-0">
          <Field
            name="StudyNow"
            onChange={(value: string | number, checked: boolean) => {
              setCurrentDoing(checked);
            }}
            label=""
            checked={currentDoing}
            accepter={Checkbox}
            dir="rtl"
            full
            readOnly={readOnly}>
            در این محل مشغول به کار هستم.
          </Field>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%] !px-0">
          <FlexboxGrid>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="Name"
                label={currentDoing ? 'نام سازمان / شرکت فعلی' : 'نام سازمان / شرکت'}
                type="string"
                accepter={Input}
                dir="rtl"
                full
                placeholder=""
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <Field
                accepter={InputPicker}
                data={ConsultantJobOptions}
                name="Role"
                label="سمت / شغل"
                dir="rtl"
                full
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <Field
                accepter={Datepicker}
                className="font-fd"
                name="Start_Date"
                lang={'fa'}
                theme={'green'}
                format={'D MMMM yyyy'}
                value={convertDateToSlashi(timeStamp)}
                input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(val: any) => setTimeStamp(new Date(val))}
                label="تاریخ شروع"
                dir="ltr"
                full
                req
              />
            </FlexboxGrid.Item>

            <CountryStateCity
              hasCountry={true}
              setFormValue={setFormValue}
              countryOptions={{
                default: formValue?.Country,
                name: 'Country',
                label: 'کشور',
                colspan: 24,
                sm: 24,
                md: 8,
                readOnly
              }}
              stateOptions={{
                default: formValue?.State,
                name: 'State',
                label: 'استان',
                colspan: 24,
                sm: 24,
                md: 8,
                readOnly
              }}
              cityOptions={{
                default: formValue?.City,
                name: 'City',
                label: 'شهر',
                colspan: 24,
                sm: 24,
                md: 8,
                readOnly
              }}
            />
          </FlexboxGrid>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <TextAreaField
            name="Address"
            label={currentDoing ? 'آدرس محل کار فعلی' : 'آدرس محل کار'}
            dir="rtl"
            full
            req
            placeholder="آدرس را بدون ذکر شهر و استان نوشته و با ، جدا کنید."
            readOnly={readOnly}
          />
        </FlexboxGrid.Item>
        {!currentDoing ? (
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <Field
              name="Duration"
              label="مدت سابقه"
              accepter={InputPicker}
              data={ConsultantDurationOptions}
              dir="rtl"
              full
              req
              readOnly={readOnly}
            />
          </FlexboxGrid.Item>
        ) : null}
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
          {props.isLast && !props.isAdmin ? (
            <div className="flex justify-between">
              {defaultValue.ID ? (
                <Button
                  appearance="primary"
                  type="button"
                  onClick={() => {
                    setDisabledMode(false);
                    // setForceReadOnly(false);
                    setReadOnly(false);
                  }}
                  loading={loading}>
                  ویرایش
                </Button>
              ) : null}
              {!props.isFirst ? (
                <Button
                  appearance="ghost"
                  type="button"
                  className="mr-auto"
                  onClick={() => {
                    props.onDelete(defaultValue.ID ?? null);
                  }}
                  loading={loading}>
                  حذف
                </Button>
              ) : null}
            </div>
          ) : null}
          {props.hasEditMode && !props.isAdmin ? (
            <div>
              {disabledMode ? (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      setDisabledMode(false);
                      // setForceReadOnly(false);
                      setReadOnly(false);
                    }}
                    loading={loading}>
                    ویرایش
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      props.onDelete(defaultValue.ID ?? null);
                    }}
                    loading={loading}>
                    حذف
                  </Button>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      handleSubmit(false, true);
                    }}
                    loading={loading}>
                    به روزرسانی
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      setFormValue(defaultValue);
                      setDisabledMode(true);
                      // setForceReadOnly(true);
                      setReadOnly(true);
                    }}
                    loading={loading}>
                    لغو
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {!props.isLast ?? props.isAdmin ? null : (
        <>
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="ml-auto mt-4"
              type="button"
              onClick={() => handleSubmit(false, false)}
              loading={loading}>
              {props.hideNextButton ? 'ثبت شغل فعلی' : 'افزودن سابقه جدید'}
            </Button>
          </div>
          {props.hideNextButton ? null : (
            <div className="flex justify-between">
              <Button
                appearance="default"
                className="ml-auto mt-8"
                type="button"
                onClick={() => {
                  navigate(ROUTES.roles.reqConsultant.personal);
                }}
                loading={loading}>
                مرحله قبل
              </Button>
              <Button
                appearance="primary"
                className="mr-auto mt-8"
                type="button"
                onClick={() => handleSubmit(true, false)}
                loading={loading}>
                ثبت و مرحله بعد
              </Button>
            </div>
          )}
        </>
      )}
    </Form>
  );
};
