/* eslint-disable @typescript-eslint/no-explicit-any */

import { Divider } from "rsuite";

export const SeekerSkillBackgroundView = ({ data }: any) => {
  return (
    <>
      {/* <h4 className="mb-4">مهارت ها</h4> */}
      <div className="w-full">
        <Divider className="text-[20px] font-bold">مهارت ها</Divider>
      </div>
      <ol style={{ listStyle: 'auto', paddingRight: '12px' }}>
        {data?.map((skill: any) => {
          return (
            <li key={skill.id} className="mb-4">
              <p className="text-[16px]">
                در زمینه <strong>{skill.Skills}</strong> دارای سطح مهارت{' '}
                <strong>{skill.Level}</strong> هستم.
              </p>
              <p className="text-justify">{skill.Description ? <>- {skill.Description}</> : ''}</p>
            </li>
          );
        })}
      </ol>
    </>
  );
};
