import { getUsername } from './config';
import { authNetworkManager, appNetworkManager } from './network-manager';

export const putReqLoginSms = (username: string) =>
  authNetworkManager.put(`/loginws`, { Username: username });
export const postLoginWithSms = (username: string, sMSCode: string) =>
  authNetworkManager.post(`/loginws`, { Username: username, SMSCode: sMSCode });

export const postLoginWithPassword = (username: string, password: string) =>
  authNetworkManager.post(`/loginwp`, { Username: username, Password: password });

export const postLoginWithPassword2 = (username: string, password: string) =>
  appNetworkManager.post(`/loginwp`, { Username: username, Password: password });

export const getUserProfile = (username: string) =>
  appNetworkManager.get(`/users?Username=${username}`);
export const getBoughtResumes = () =>
  appNetworkManager.get(`/readcv?EmployerUsername=${getUsername()}`);

export const getUserRoles = (username: string) => authNetworkManager.get(`/user/role/${username}`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postChangePassword = (payload: any) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return authNetworkManager.post(`/resetpassword`, rest);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putReqChangePassword = (payload: any) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return authNetworkManager.put(`/resetpassword`, rest);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postSignupUser = (payload: any) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return authNetworkManager.post(`/users`, rest);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postSignupUser2 = (payload: any) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/users`, rest);
};

export const getUser = (username: string) => authNetworkManager.get(`/users?Username=${username}`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postAddRoleToUser = (role: string, username: string) => {
  // eslint-disable-next-line no-unused-vars
  return authNetworkManager.post(`/user/role/${username}?Role=${role}`, {});
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteRoleFromUser = (role: string, username: string) => {
  // eslint-disable-next-line no-unused-vars
  return authNetworkManager.delete(`/user/role/${username}?Role=${role}`, {});
};
