/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
// import { useState } from 'react';
// import LocationIcon from '../../../assets/icons/consultants/location.svg';
// import StarIcon from '../../../assets/icons/consultants/star.svg';
// import LikeIcon from '../../../assets/icons/consultants/like.svg';
// import CheckIcon from '../../../assets/icons/consultants/check.svg';
import { Col, FlexboxGrid, Button, SelectPicker, Input, InputPicker } from 'rsuite';
// import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { handleGetAdminSeekersRegisters } from '../../../api-handler/admin';
import { CustomLoader } from './../../../components/basic/loader';
import { getAge, SEEKER_CATGORY2_CONVERTOR } from '../../../utils/helpers';
import { Calendar, Document, Location, User } from 'react-iconly';
import seekerCategoryOptions from '../../../data/seeker/seeker-category.json';
import seekerCategory2Optionss from '../../../data/seeker/seeker-category-2.json';
import MilitaryServiceOptions from '../../../data/global/military-service.json';
import EducationDegreeOptions from '../../../data/global/education-degree.json';
import { openInNewTab } from '../../../components/route-handler/openI-in-new-tab';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const seekerCategory2Options: any = seekerCategory2Optionss;
export const SeekersList = () => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[]>([]);
  const [LayerOne, setLayerOne] = useState<string | null>(null);
  const [LayerTwo, setLayerTwo] = useState<string | null>(null);
  const [LayerThree] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [sex, setSex] = useState('آقا یا خانم');
  const [militaryService, setMilitaryService] = useState('همه موارد');
  const [educationDegree, setEducationDegree] = useState('همه موارد');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  // const [age, setAge] = useState('');

  useEffect(() => {
    (async () => {
      // const payload = {
      //   LayerOne: null,
      //   LayerTwo: null,
      //   LayerThree: null
      // };
      const payload = {
        LayerOne,
        LayerTwo,
        LayerThree
      };
      setLoading(true);
      const res = await handleGetAdminSeekersRegisters(payload);

      console.log(res);
      if (res.ok) {
        const filtered = res.data.filter((item: any) => item.SeekerInfo?.Status === 'Accepted');
        console.log(res.data.filter((item: any) => item.SeekerInfo?.Status != 'Accepted'));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const transformed = filtered.reduce((acc: any, curr: any) => {
          // Skip null users
          if (!curr.SeekerInfo) return acc;

          // Find or create the user object
          const userKey = curr.SeekerInfo.ID;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let user = acc.find((u: any) => u.ID === userKey);

          if (!user) {
            user = {
              ID: userKey,
              Username: curr.Username,
              SeekerInfo: curr.SeekerInfo,
              Layers: []
            };
            acc.push(user);
          }

          // Add the layers for this entry
          user.Layers.push({
            LayerOne: curr.LayerOne,
            LayerTwo: curr.Layertwo,
            LayerThree: curr.LayerThree,
            Status: curr.Status,
            IsLocked: curr.IsLocked
          });

          return acc;
        }, []);
        console.log(transformed);
        setData(transformed);
      }
      setLoading(false);
    })();
  }, [LayerOne, LayerTwo, LayerThree]);

  useEffect(() => {
    setFilteredData(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.filter((item: any) => {
        return (
          (sex === 'آقا یا خانم'
            ? true
            : (sex == 'آقا' && item.SeekerInfo.Sex) || (sex != 'آقا' && !item.SeekerInfo.Sex)) &&
          item.SeekerInfo.CityE.toLowerCase().search(city.trim().toLowerCase()) >= 0 &&
          item.SeekerInfo.StateE.toLowerCase().search(state.trim().toLowerCase()) >= 0 &&
          (militaryService == 'همه موارد'
            ? true
            : item.SeekerInfo.MilitaryService.toLowerCase().search(
                militaryService.trim().toLowerCase()
              ) >= 0) &&
          (educationDegree == 'همه موارد'
            ? true
            : item.SeekerInfo.EduDegree.toLowerCase().search(
                educationDegree.trim().toLowerCase()
              ) >= 0)
        );
      })
    );
  }, [sex, city, state, data, militaryService, educationDegree]);

  return (
    <>
      <h4 className="text-h4 text-[--rs-primary-800] mb-3">لیست کارجو ها</h4>
      <div className="mb-3 flex flex-row w-[50%]">
        <div className="flex flex-col ml-3 w-full">
          <label className="mb-1">سطح 1</label>
          <SelectPicker
            data={seekerCategoryOptions}
            searchable={false}
            value={LayerOne}
            onChange={setLayerOne}
          />
        </div>
        <div className="flex flex-col ml-3 w-full">
          <label className="mb-1">سطح 2</label>
          <SelectPicker
            data={seekerCategory2Options[LayerOne ?? '']}
            searchable={false}
            value={LayerTwo}
            disabled={!LayerOne}
            onChange={setLayerTwo}
          />
        </div>
        {/* <div className="flex flex-col ml-3 w-full">
          <label className="mb-1">سطح 3</label>
          <SelectPicker
            data={seekerCategoryOptions}
            searchable={false}
            value={LayerThree}
            onChange={setLayerThree}
          />
        </div> */}
      </div>
      <FlexboxGrid>
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            <FlexboxGrid.Item
              as={Col}
              colspan={24}
              sm={24}
              md={24}
              lg={4}
              className="max-w-[100%] mb-3">
              <label>
                جنسیت
                <InputPicker
                  data={[
                    {
                      label: 'آقا یا خانم',
                      value: 'آقا یا خانم'
                    },
                    {
                      label: 'آقا',
                      value: 'آقا'
                    },
                    {
                      label: 'خانم',
                      value: 'خانم'
                    }
                  ]}
                  placeholder="جنسیت"
                  dir="rtl"
                  cleanable={false}
                  className="mt-1 mb-4 !w-full"
                  onChange={setSex}
                  value={sex}
                />
              </label>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              as={Col}
              colspan={24}
              sm={24}
              md={24}
              lg={4}
              className="max-w-[100%] mb-3">
              <label>
                وضعیت نظام وظیفه
                <InputPicker
                  data={[
                    {
                      label: 'همه موارد',
                      value: 'همه موارد'
                    },
                    ...MilitaryServiceOptions
                  ]}
                  placeholder="وضعیت نظام وظیفه"
                  dir="rtl"
                  cleanable={false}
                  className="mt-1 mb-4 !w-full"
                  onChange={setMilitaryService}
                  value={militaryService}
                  disabled={sex === 'خانم'}
                />
              </label>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              as={Col}
              colspan={24}
              sm={24}
              md={24}
              lg={4}
              className="max-w-[100%] mb-3">
              <label>
                استان
                <Input
                  type="text"
                  placeholder="استان"
                  className="mt-1 mb-4 !w-full"
                  onChange={setState}
                  value={state}
                />
              </label>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              as={Col}
              colspan={24}
              sm={24}
              md={24}
              lg={4}
              className="max-w-[100%] mb-3">
              <label>
                شهر
                <Input
                  type="text"
                  placeholder="شهر"
                  className="mt-1 mb-4 !w-full"
                  onChange={setCity}
                  value={city}
                />
              </label>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              as={Col}
              colspan={24}
              sm={24}
              md={24}
              lg={4}
              className="max-w-[100%] mb-3">
              <label>
                مدرک تحصیلی
                <InputPicker
                  data={[
                    {
                      label: 'همه موارد',
                      value: 'همه موارد'
                    },
                    ...EducationDegreeOptions
                  ]}
                  placeholder="مدرک تحصیلی"
                  dir="rtl"
                  cleanable={false}
                  className="mt-1 mb-4 !w-full"
                  onChange={setEducationDegree}
                  value={educationDegree}
                />
              </label>
            </FlexboxGrid.Item>
            {filteredData.map((item) => {
              return (
                <FlexboxGrid.Item
                  key={item.ID.toString()}
                  as={Col}
                  colspan={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xlg={6}
                  className={`max-w-[100%] mb-3 ${item.IsLocked == 'Locked' || item.IsLocked == 'LockedWorking' ? 'grayscale' : ''}`}>
                  <div className="bg-[--rs-white] relative !overflow-hidden border-[--rs-primary-300] rounded-lg border-[1px] p-4 w-full">
                    {item.IsLocked == 'Locked' ? (
                      <div
                        className="bg-[--rs-primary-500] text-[--rs-primary-50] text-center px-4 py-3 absolute"
                        style={{
                          transform: 'rotate(-45deg)',
                          zIndex: 2,
                          left: '-35%',
                          top: '8%',
                          width: '100%'
                        }}>
                        درحال برسی است
                      </div>
                    ) : item.IsLocked == 'Working' ? (
                      <div
                        className="bg-[--rs-primary-500] text-[--rs-primary-50] text-center px-4 py-3 absolute"
                        style={{
                          transform: 'rotate(-45deg)',
                          zIndex: 2,
                          left: '-35%',
                          top: '8%',
                          width: '100%'
                        }}>
                        استخدام شده
                      </div>
                    ) : null}
                    <div className="mb-1 mt-2 px-3 py-2 rounded-md bg-[--rs-primary-100] text-[--rs-primary-500] font-bold text-center">
                      <strong>
                        {SEEKER_CATGORY2_CONVERTOR(
                          item.Layers[0]?.LayerOne,
                          item.Layers[0]?.LayerTwo
                        ) ?? '-'}
                      </strong>
                    </div>
                    {/* <div className="mb-1 mt-3 text-[--rs-primary-400] font-bold text-center">
                      <strong>{item.Username}</strong>
                    </div> */}
                    <div className="flex">
                      <div className="mr-3 mt-2">
                        <div className="flex items-center mb-1">
                          <div className="p-2 rounded-full bg-[--rs-primary-50]  text-[--rs-primary-500] ml-2">
                            <User set="bulk" />
                          </div>
                          <p className="text-b1 mb-0 text-[--rs-primary-400]">
                            <strong>جنسیت:</strong> {item.SeekerInfo.Sex === true ? 'آقا' : 'خانم'}
                          </p>
                        </div>
                        {/* <div className="flex items-center mb-1">
                          <div className="p-2 rounded-full bg-[--rs-primary-50]  text-[--rs-primary-500] ml-2">
                            <TwoUsers set="bulk" />
                          </div>
                          <p className="text-b1 mb-0 text-[--rs-primary-400]">
                            <strong>وضیعت نظام وظیفه:</strong>{' '}
                            {item.SeekerInfo.Sex ? item.SeekerInfo.MilitaryService : '-'}
                          </p>
                        </div> */}
                        <div className="flex items-center mb-1">
                          <div className="p-2 rounded-full bg-[--rs-primary-50]  text-[--rs-primary-500] ml-2">
                            <Location set="bulk" />
                          </div>
                          <p className="text-b1 mb-0 text-[--rs-primary-400]">
                            <strong>استان و شهر:</strong> {item.SeekerInfo.StateE}
                            {' - '}
                            {item.SeekerInfo.CityE}
                          </p>
                        </div>
                        <div className="flex items-center mb-1">
                          <div className="p-2 rounded-full bg-[--rs-primary-50]  text-[--rs-primary-500] ml-2">
                            <Calendar set="bulk" />
                          </div>
                          <p className="text-b1 mb-0 text-[--rs-primary-400]">
                            <strong>سن:</strong> {getAge(item.SeekerInfo.Birth_Date)} سال
                          </p>
                        </div>
                        <div className="flex items-center mb-1">
                          <div className="p-2 rounded-full bg-[--rs-primary-50]  text-[--rs-primary-500] ml-2">
                            <Document set="bulk" />
                          </div>
                          <p className="text-b1 mb-0 text-[--rs-primary-400]">
                            <strong>مدرک تحصیلی:</strong> {item.SeekerInfo.EduDegree}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center mt-6">
                      <Button
                        appearance="primary"
                        className="mx-auto min-w-[50%] mb-2 inline-block"
                        type="button"
                        onClick={
                          () => openInNewTab(`/seekers/${item.Username}`)
                          // () => navigate(`/seekers/${item.Username}`)
                        }>
                        مشاهده اطلاعات
                      </Button>
                    </div>
                  </div>
                </FlexboxGrid.Item>
              );
            })}
            {filteredData.length === 0 ? (
              <div className="text-center text-h4 h-[30vh] flex justify-center items-center w-full">
                کارجویی یافت نشد!
              </div>
            ) : null}
          </>
        )}
      </FlexboxGrid>
    </>
  );
};
