import { Button, Col, FlexboxGrid, Form, Input, Schema } from 'rsuite';
import { useCallback, useEffect, useRef, useState } from 'react';

import InstagramIcon from '../../assets/icons/instagram.svg';
import WhatsappIcon from '../../assets/icons/whatsapp.svg';
import LinkedinIcon from '../../assets/icons/linkedin.svg';
import TelegramIcon from '../../assets/icons/telegram.svg';
import GithubIcon from '../../assets/icons/github.svg';
// import KarlancerIcon from '../../assets/icons/karlancer.png';
const { StringType } = Schema.Types;
const model = Schema.Model({
  Instagram: StringType(''),
  WhatsApp: StringType(''),
  Linkedin: StringType(''),
  Telegram: StringType(''),
  Github: StringType(''),
  Karlancer: StringType(''),
  Description: StringType('')
});

import {
  handlePostSeekerSocial,
  handleGetSeekerSocial,
  handlePutSeekerSocial
} from '../../api-handler';
import { showToast } from '../../utils/toast';
import IconTextField from '../../components/form/icon-textfield';
import { useNavigate } from 'react-router-dom';
import { CustomLoader } from '../../components/basic/loader';
import TextAreaField from '../../components/form/textarea-field';
import { ROUTES } from '../../router';
export const SeekerSocial = (props: {
  disabledMode?: boolean;
  isAdmin?: boolean;
  username?: string;
}) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initial, setInitial] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }

    if (!formValue) return;
    const payload = {
      ...formValue,
    };

    setLoading(true);
    if (editMode) {
      const updateRes = await handlePutSeekerSocial(payload, 'ID', initial.ID);
      if (updateRes.ok) {
        showToast('شبکه های اجتماعی با موفقیت بروزرسانی شد!', 'success');
        navigate(ROUTES.roles.reqSeeker.tnc);
      }
      setLoading(false);
      return;
    }
    const res = await handlePostSeekerSocial(payload);
    if (res.ok) {
      showToast('شبکه های اجتماعی با موفقیت ثبت شد!', 'success');
      // todo: navigate to next step
      navigate(ROUTES.roles.reqSeeker.tnc);
    }
    setLoading(false);
  }, [formValue, formError, formRef]);

  const handleGetInitialData = async (username: string | null | undefined) => {
    setLoading(true);
    const res = await handleGetSeekerSocial(username);
    if (res.ok) {
      setInitial(res.data);
      setFormValue({
        ...res.data,
      });
      setEditMode(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetInitialData(props.isAdmin ? props.username : null);
    })();
  }, [props.username]);

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4">
        {props.isAdmin ? 'شبکه‌های اجتماعی' : 'شبکه‌های اجتماعی خود را وارد کنید'}
      </h4>

      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={model}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        disabled={props.disabledMode}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <IconTextField
              name="Instagram"
              label="آی‌دی اینستاگرام"
              type={'text'}
              placeholder="@instagram_id"
              accepter={Input}
              full
              dir="ltr"
              onIconCLick={() => null}
              eyeVisible={false}
              onIcon={<img src={InstagramIcon} />}
              offIcon={<img src={InstagramIcon} />}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <IconTextField
              name="Linkedin"
              label="آی‌دی لینکداین"
              type={'text'}
              placeholder="@linkedin_id"
              accepter={Input}
              full
              dir="ltr"
              onIconCLick={() => null}
              eyeVisible={false}
              onIcon={<img src={LinkedinIcon} />}
              offIcon={<img src={LinkedinIcon} />}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <IconTextField
              name="WhatsApp"
              label="شماره واتساپ"
              type={'text'}
              placeholder="9123456789"
              accepter={Input}
              full
              dir="ltr"
              onIconCLick={() => null}
              eyeVisible={false}
              onIcon={<img src={WhatsappIcon} />}
              offIcon={<img src={WhatsappIcon} />}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <IconTextField
              name="Telegram"
              label="آی‌دی تلگرام"
              type={'text'}
              placeholder="@telegram_id"
              accepter={Input}
              full
              dir="ltr"
              onIconCLick={() => null}
              eyeVisible={false}
              onIcon={<img src={TelegramIcon} />}
              offIcon={<img src={TelegramIcon} />}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <IconTextField
              name="Github"
              label="نام کاربری گیتهاب"
              type={'text'}
              placeholder="github_username"
              accepter={Input}
              full
              dir="ltr"
              onIconCLick={() => null}
              eyeVisible={false}
              onIcon={<img src={GithubIcon} />}
              offIcon={<img src={GithubIcon} />}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
            <TextAreaField name="Description" label="درباره من" dir="rtl" full req placeholder="" />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        {!props.disabledMode ? (
          <div className="flex justify-between">
            <Button
              appearance="default"
              className="ml-auto mt-8"
              type="button"
              onClick={() => {
                navigate(ROUTES.roles.reqSeeker.desire);
              }}
              loading={loading}>
              مرحله قبل
            </Button>
            <Button appearance="primary" className="mr-auto mt-8" type="submit" loading={loading}>
              مرحله بعد
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
};
export default SeekerSocial;
