import { Button, Loader } from 'rsuite';
import { useEffect, useState } from 'react';
// import { FlexboxGrid } from 'rsuite';
import { FormatDateTime, sortData } from '../../../utils/helpers';

import { Table, Cell, Column, HeaderCell } from 'rsuite-table';
import { handleGetWorkshopBoughts } from '../../../api-handler';
import { useParams } from 'react-router-dom';
import { faIR } from 'date-fns-jalali/locale';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AdminWorkshopUsersList = () => {
  const { EventID } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  // const [loading] = useState(false);
  const [sortColumn, setSortColumn] = useState('id');
  const [sortType, setSortType] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const [defaultData, setDefaultData] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setTableLoading(true);
    setTimeout(() => {
      setTableLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };
  useEffect(() => {
    (async () => {
      setTableLoading(true);
      const res = await handleGetWorkshopBoughts(EventID);
      if (res.ok) {
        setData(res.data);
      }
      setTableLoading(false);
    })();
  }, [EventID]);

  // const [search, setSearch] = useState("");

  // useEffect(() => {
  //   setData(defaultData.filter(d=>(d.Book_Name.toLowerCase().search(search.toLowerCase())>=0)))
  // }, [search]);

  return (
    <>
      <h4 className="text-h4 mb-6">لیست افراد ثبتنامی</h4>

      <Table
        data={sortData(data, sortColumn, sortType)}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={tableLoading}
        // affixHeader
        renderLoading={() => (
          <div className="flex items-center justify-center h-[100%]">
            <Loader size="md" />
          </div>
        )}
        renderEmpty={() => (
          <div className="flex items-center justify-center h-[100%]">ثبتنامی موجود نیست</div>
        )}
        autoHeight
        affixHorizontalScrollbar>
        {/* <Column width={100} verticalAlign="middle" align="center">
          <HeaderCell>شماره</HeaderCell>
          <Cell dataKey="ID" />
        </Column> */}
        <Column minWidth={120} flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>شماره پرداخت</HeaderCell>
          <Cell dataKey="RefID" />
        </Column>
        <Column minWidth={120} flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>کد ملی</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {rowData.UserInfo?.NID}</>;
            }}
          </Cell>
        </Column>
        <Column minWidth={150} flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نام کاربری</HeaderCell>
          <Cell dataKey="Username" />
        </Column>
        <Column width={150} verticalAlign="middle" align="center">
          <HeaderCell>نام</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {rowData.UserInfo?.FirstName}</>;
            }}
          </Cell>
        </Column>
        <Column width={120} verticalAlign="middle" align="center">
          <HeaderCell>نام خانوادگی</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {rowData.UserInfo?.LastName}</>;
            }}
          </Cell>
        </Column>
        {/* <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>وضعیت</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {STATUS_CONVERTOR[rowData.Status]?.short}</>;
            }}
          </Cell>
        </Column> */}
        <Column minWidth={180} flexGrow={1} verticalAlign="middle" align="center font-fd">
          <HeaderCell>زمان نام نویسی</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <>{FormatDateTime(rowData.TimeStamp, faIR)}</>;
            }}
          </Cell>
        </Column>
        <Column width={90} verticalAlign="middle" align="center">
          <HeaderCell>مشاهده</HeaderCell>
          <Cell dataKey="">
            {(rowData) => (
              <Button
                appearance="primary"
                size="xs"
                type="button"
                onClick={() => navigate(`/admin/profile/${rowData.Username}`)}>
                مشاهده
              </Button>
            )}
          </Cell>
        </Column>
      </Table>
    </>
  );
};
