import { Button, Form, Input, Schema } from 'rsuite';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconTextField from '../../../../components/form/icon-textfield';
import { Hide, Show } from 'react-iconly';
import { handleAdminLoginWithPassword } from '../../../../api-handler';
import { useUser } from '../../../../contexts/user';
import TextField from '../../../../components/form/textfield';

const { StringType } = Schema.Types;
const model = Schema.Model({
  Username: StringType('').isRequired('لطفا نام کاربری را وارد کنید.'),
  Password: StringType('').isRequired('رمز عبور را وارد کنید.')
});

export const LoginAdmin = () => {
  const { setToken, setUserTrigger, userTrigger } = useUser();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    console.log('submitted', formValue);
    setLoading(true);
    const res = await handleAdminLoginWithPassword(formValue.Username, formValue.Password);
    if (res.ok) {
      // set token and user data
      setToken(res.data.Data, formValue.Username, 'yes');
      setUserTrigger(!userTrigger);
      navigate('/admin/roles-requests');
    }
    setLoading(false);
  }, [formValue, formError, formRef]);

  //
  const [visible, setVisible] = useState(false);
  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={model}
      onSubmit={handleSubmit}
      checkTrigger="change"
      readOnly={loading}
      className="mt-8 text-dark">
      <TextField
        name="Username"
        label="نام کاربری"
        type="text"
        accepter={Input}
        dir="ltr"
        placeholder=""
      />
      <IconTextField
        name="Password"
        label="رمز عبور"
        type={visible ? 'text' : 'password'}
        accepter={Input}
        dir="ltr"
        onIconCLick={() => setVisible(!visible)}
        eyeVisible={visible}
        onIcon={<Show size="small" />}
        offIcon={<Hide size="small" />}
      />
      <Button
        appearance="primary"
        type="submit"
        className="w-[50%] mx-auto !block mt-8"
        loading={loading}>
        ورود
      </Button>
    </Form>
  );
};
