/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, Input, InputPicker, Checkbox, Toggle } from 'rsuite';
import TextField from '../../../components/form/textfield';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlexboxGrid } from 'rsuite';
import Field from '../../../components/form/field';
import TextAreaField from '../../../components/form/textarea-field';
import { showToast } from '../../../utils/toast';
import {
  handlePostSeekerWorkBackground,
  handlePutSeekerWorkBackground
} from '../../../api-handler';
import {
  PostSeekerWorkBackgroundModel,
  defaultSeekerWorkBackgroundFormValue,
  seekerWorkBackgroundFormModel
} from '../../../models';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router';
import { CountryStateCity } from './../../../components/form/country-state-city';
import { convertDateToSlashi } from '../../../utils/helpers';
import { Datepicker } from '@ijavad805/react-datepicker';
import LastSalaryOptions from '../../../data/seeker/last-salary.json';
import DurationOptions from '../../../data/seeker/duration.json';
import TimeInsuranceOptions from '../../../data/seeker/time-insurance.json';
import RoleOptions from '../../../data/seeker/role.json';

export const WorkBackgroundForm = (props: {
  readonly?: boolean;
  handleAddDoingData: (workData: PostSeekerWorkBackgroundModel) => void;
  handleUpdateDoingData: (workData: PostSeekerWorkBackgroundModel) => void;
  defaultValue?: PostSeekerWorkBackgroundModel | null;
  hideNextButton?: boolean;
  forceReadOnly?: boolean;
  disabledMode?: boolean;
  hasEditMode?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isNotBought?: boolean;
  isAdmin: boolean;
  onDelete: (id: string | number) => void;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabledMode, setDisabledMode] = useState(props.hasEditMode);
  const [forceReadOnly] = useState(props.forceReadOnly);
  const [readOnly, setReadOnly] = useState(props.readonly);
  const [currentDoing, setCurrentDoing] = useState(props.defaultValue?.CurrentWork);
  const [agreeToShow, setAgreeToShow] = useState(props.defaultValue?.AgreeToShow);
  const [wasInsured, setWasInsured] = useState(props.defaultValue?.Insurance);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>(
    props.defaultValue ?? defaultSeekerWorkBackgroundFormValue
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultValue, setDefaultValue] = useState<any>(
    props.defaultValue ?? defaultSeekerWorkBackgroundFormValue
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeStamp, setTimeStamp] = useState<any>(new Date());

  const normalizeFormValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fv: any) => {
      const payload = {
        ...fv,
        CurrentWork: currentDoing === true,
        AgreeToShow: agreeToShow === true,
        Insurance: wasInsured === true,
        Start_Date: timeStamp,
        // Country: 'ایران'
        Country: formValue['Country-t'] == 'ایران' ? 'ایران' : formValue.Country ?? 'ایران'
      };
      if (payload['Country-t']) delete payload['Country-t'];

      if (!currentDoing) {
        if (!payload.Duration) {
          setFormError((pre) => {
            return { ...pre, Duration: 'الزامی است!' };
          });
          return;
        }
        if (!payload.ReasonLeaveJob) {
          setFormError((pre) => {
            return { ...pre, ReasonLeaveJob: 'الزامی است!' };
          });
          return;
        }
      }
      // todo remove unused fields
      return payload;
    },
    [currentDoing, agreeToShow, wasInsured, timeStamp]
  );
  const handleSubmit = useCallback(
    async (isNext: boolean, updateMode: boolean, isSkip = false) => {
      if (isSkip) {
        navigate(ROUTES.roles.reqSeeker.educationBackground);
      }
      if (formRef.current && !formRef.current.check()) {
        console.log('formError', formError);
        return;
      }
      if (!formValue) return;

      const payload = normalizeFormValue(formValue);
      if (!payload) return;

      setLoading(true);
      if (updateMode) {
        const ress = await handlePutSeekerWorkBackground(payload, 'ID', payload.ID);
        if (ress.ok) {
          showToast('فرم به روزرسانی شد!', 'success');
          setDisabledMode(true);
          // setForceReadOnly(true);
          setReadOnly(true);
          setLoading(false);
          setDefaultValue(payload);
          if (isNext) {
            navigate(ROUTES.roles.reqSeeker.educationBackground);
            return;
          }
          props.handleUpdateDoingData({ ...payload });
        }
        setLoading(false);
        return;
      }
      const res = await handlePostSeekerWorkBackground(payload);
      setLoading(false);
      if (res.ok) {
        showToast('سوابق شغلی ثبت شد!', 'success');
        // or getting submitted data from backend
        if (isNext) {
          navigate(ROUTES.roles.reqSeeker.educationBackground);
          return;
        }
        props.handleAddDoingData({ ...payload, ID: res.data.ID });
        setCurrentDoing(false);
        setWasInsured(false);
        setAgreeToShow(false);
        setFormValue(defaultSeekerWorkBackgroundFormValue);
      }
    },
    [formValue, formError, timeStamp, formRef, currentDoing, wasInsured, agreeToShow]
  );

  useEffect(() => {
    const startDate: any = props?.defaultValue?.Start_Date ?? null;
    setTimeStamp(startDate ? new Date(startDate) : new Date());
  }, [props.defaultValue]);
  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={seekerWorkBackgroundFormModel}
      onSubmit={() => handleSubmit(false, false)}
      formValue={formValue}
      checkTrigger="change"
      className="mt-6 text-dark"
      readOnly={forceReadOnly ?? loading}
      disabled={disabledMode}>
      <FlexboxGrid
        className={'bg-[--rs-primary-50] rounded-md border-[1px] border-[--rs-primary-500] p-3'}>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] !px-0">
          <Field
            name="CurrentWork"
            onChange={(value: string | number, checked: boolean) => {
              setCurrentDoing(checked);
            }}
            label=""
            checked={currentDoing}
            accepter={Checkbox}
            dir="rtl"
            full
            readOnly={readOnly}>
            در این محل مشغول به کار هستم.
          </Field>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%] !px-0">
          <FlexboxGrid>
            {props.isNotBought ? null : (
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <TextField
                  name="CurrentWorkName"
                  label={currentDoing ? 'نام سازمان / شرکت فعلی' : 'نام سازمان / شرکت'}
                  type="string"
                  accepter={Input}
                  dir="rtl"
                  full
                  placeholder=""
                  req
                  readOnly={readOnly}
                />
              </FlexboxGrid.Item>
            )}
            {props.isNotBought ? null : (
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <TextField
                  name="CurrentWorkNumber"
                  label={currentDoing ? 'شماره تلفن محل کار فعلی' : 'شماره تلفن محل کار'}
                  type="string"
                  accepter={Input}
                  dir="ltr"
                  full
                  placeholder=""
                  req
                  readOnly={readOnly}
                />
              </FlexboxGrid.Item>
            )}
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <Field
                accepter={InputPicker}
                data={RoleOptions}
                name="Role"
                label="سمت / شغل"
                dir="rtl"
                full
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>

            <CountryStateCity
              hasCountry={true}
              setFormValue={setFormValue}
              countryOptions={{
                default: formValue?.Country,
                name: 'Country',
                label: 'کشور',
                colspan: 24,
                sm: 24,
                md: 8,
                readOnly
              }}
              stateOptions={{
                default: formValue?.State,
                name: 'State',
                label: 'استان',
                colspan: 24,
                sm: 24,
                md: 8,
                readOnly
              }}
              cityOptions={{
                default: formValue?.City,
                name: 'City',
                label: 'شهر',
                colspan: 24,
                sm: 24,
                md: 8,
                readOnly
              }}
            />
          </FlexboxGrid>
        </FlexboxGrid.Item>
        {props.isNotBought ? null : (
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="Address"
              label={currentDoing ? 'آدرس محل کار فعلی' : 'آدرس محل کار'}
              dir="rtl"
              full
              req
              placeholder="آدرس را بدون ذکر شهر و استان نوشته و با ، جدا کنید."
              readOnly={readOnly}
            />
          </FlexboxGrid.Item>
        )}

        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%] !px-0">
          <FlexboxGrid>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <Field
                accepter={Datepicker}
                className="font-fd"
                name="Start_Date"
                lang={'fa'}
                theme={'green'}
                format={'D MMMM yyyy'}
                value={convertDateToSlashi(timeStamp)}
                input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(val: any) => setTimeStamp(new Date(val))}
                label="تاریخ شروع"
                dir="ltr"
                full
                req
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <Field
                name="LastSalary"
                label="آخرین حقوق"
                accepter={InputPicker}
                data={LastSalaryOptions}
                dir="rtl"
                full
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              {!currentDoing ? (
                <Field
                  name="Duration"
                  label="مدت سابقه"
                  accepter={InputPicker}
                  data={DurationOptions}
                  dir="rtl"
                  full
                  req
                  readOnly={readOnly}
                />
              ) : null}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <div className="mb-4">مشمول بیمه</div>
              <Field
                accepter={Toggle}
                name="Insurance"
                onChange={(checked: boolean) => {
                  setWasInsured(checked);
                }}
                label=""
                dir="rtl"
                full
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            {!currentDoing ? (
              <>
                {wasInsured ? (
                  <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                    <Field
                      accepter={InputPicker}
                      data={TimeInsuranceOptions}
                      name="TimeInsurance"
                      label="مدت زمان بیمه"
                      dir="rtl"
                      full
                      req
                      readOnly={readOnly}
                    />
                  </FlexboxGrid.Item>
                ) : null}
              </>
            ) : null}
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] !px-0">
              {/* <Field
                name="AgreeToShow"
                onChange={(value: string | number, checked: boolean) => {
                  setAgreeToShow(checked);
                }}
                label=""
                checked={agreeToShow}
                accepter={Checkbox}
                dir="rtl"
                full
                readOnly={readOnly}>
                در پروفایل من نشان داده شود.
              </Field> */}
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid.Item>
        {!currentDoing ? (
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField
              name="ReasonLeaveJob"
              label="علت ترک شغل"
              dir="rtl"
              full
              req
              placeholder=""
              readOnly={readOnly}
            />
          </FlexboxGrid.Item>
        ) : null}
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
          {props.isLast && !props.isAdmin ? (
            <div className="flex justify-between">
              {defaultValue.ID ? (
                <Button
                  appearance="primary"
                  type="button"
                  onClick={() => {
                    setDisabledMode(false);
                    // setForceReadOnly(false);
                    setReadOnly(false);
                  }}
                  loading={loading}>
                  ویرایش
                </Button>
              ) : null}
              {!props.isFirst ? (
                <Button
                  appearance="ghost"
                  type="button"
                  className="mr-auto"
                  onClick={() => {
                    props.onDelete(defaultValue.ID ?? null);
                  }}
                  loading={loading}>
                  حذف
                </Button>
              ) : null}
            </div>
          ) : null}
          {props.hasEditMode && !props.isAdmin ? (
            <div>
              {disabledMode ? (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      setDisabledMode(false);
                      // setForceReadOnly(false);
                      setReadOnly(false);
                    }}
                    loading={loading}>
                    ویرایش
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      props.onDelete(defaultValue.ID ?? null);
                    }}
                    loading={loading}>
                    حذف
                  </Button>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      handleSubmit(false, true);
                    }}
                    loading={loading}>
                    به روزرسانی
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      setFormValue(defaultValue);
                      setDisabledMode(true);
                      // setForceReadOnly(true);
                      setReadOnly(true);
                    }}
                    loading={loading}>
                    لغو
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {!props.isLast ? null : (
        <>
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="ml-auto mt-4"
              type="button"
              onClick={() => handleSubmit(false, false)}
              loading={loading}>
              {props.hideNextButton ? 'ثبت شغل فعلی' : 'افزودن سابقه جدید'}
            </Button>
          </div>
          {props.hideNextButton ? null : (
            <div className="flex justify-between">
              <Button
                appearance="default"
                className="ml-auto mt-8"
                type="button"
                onClick={() => {
                  navigate(ROUTES.roles.reqSeeker.personal);
                }}
                loading={loading}>
                مرحله قبل
              </Button>
              <div>
                {props.isFirst ? (
                  <Button
                    appearance="default"
                    className="mr-auto mt-8 ml-2"
                    type="button"
                    onClick={() => handleSubmit(true, false, true)}
                    loading={loading}>
                    سابقه شغلی ندارم
                  </Button>
                ) : null}
                <Button
                  appearance="primary"
                  className="mr-auto mt-8"
                  type="button"
                  onClick={() => handleSubmit(true, false)}
                  loading={loading}>
                  ثبت و مرحله بعد
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </Form>
  );
};
