import { Button, Col, FlexboxGrid } from 'rsuite';
import { ReactComponent as UserIcon } from '../../assets/icons/roles/user.svg';
import { ReactComponent as VendorIcon } from '../../assets/icons/roles/vendor.svg';
import { ReactComponent as ConsultantIcon } from '../../assets/icons/roles/consultant.svg';
import { ReactComponent as CorporateIcon } from '../../assets/icons/roles/corporate.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/icons/roles/projects.svg';
import { ReactComponent as TrainingsIcon } from '../../assets/icons/roles/trainings.svg';
import { ReactComponent as MarketingIcon } from '../../assets/icons/roles/marketing.svg';
import { ReactComponent as InqueryIcon } from '../../assets/icons/roles/inquery.svg';
import { ReactComponent as IdeaIcon } from '../../assets/icons/roles/idea.svg';
import { ReactComponent as InvestmentIcon } from '../../assets/icons/roles/investment.svg';
import { ReactComponent as RecruitmentIcon } from '../../assets/icons/roles/recruitment.svg';

import { Link, useNavigate } from 'react-router-dom';
import {
  handleGetConsultantRegisterRequest,
  handleGetSeekerRegisterRequest,
  handleGetCorporationRegisterRequest
} from '../../api-handler';
import { useState, useEffect } from 'react';
import { CustomLoader } from '../../components/basic/loader';
import { STATUS_CONVERTOR } from '../../utils/helpers';
import { useModal } from '../../contexts/modal';
import { ROUTES } from '../../router';

export const ChooseRole = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [consultantRegisterRequest, setConsultantRegisterRequest] = useState<string>('Null');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [consultantRegisterReason, setConsultantRegisterReason] = useState<any>({});

  const [seekerRegisterRequest, setSeekerRegisterRequest] = useState<string>('Null');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [seekerRegisterReason, setSeekerRegisterReason] = useState<any>({});

  const [corporationRegisterRequest, setCorporationRegisterRequest] = useState<string>('Null');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [corporationRegisterReason, setCorporationRegisterReason] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const { handleOpen } = useModal();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetConsultantRegisterRequest();
      const res2 = await handleGetSeekerRegisterRequest();
      const res3 = await handleGetCorporationRegisterRequest();
      if (res.ok) {
        setConsultantRegisterRequest(res.data.Status);
        setConsultantRegisterReason(res.data);
      }
      if (res2.ok) {
        setSeekerRegisterRequest(res2.data.Status);
        setSeekerRegisterReason(res2.data);
      }
      if (res3.ok) {
        setCorporationRegisterRequest(res3.data.Status);
        setCorporationRegisterReason(res3.data);
      }
      setLoading(false);
    })();
  }, []);

  const handleReservedoctor = (value: string) => {
    return async (val = value) => {
      navigate(`/${val}/personal`);
    };
  };

  return (
    <>
      <h4 className="text-h4">لطفا نقش درخواستی مورد نظر خود را انتخاب کنید</h4>
      {loading ? (
        <CustomLoader />
      ) : (
        <FlexboxGrid className="mt-12">
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            md={12}
            lg={12}
            xl={3}
            className="max-w-[100%] mb-2"></FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            md={12}
            lg={12}
            xl={6}
            className="max-w-[100%] mb-2">
            <div
              className={`border-[1px] border-[--rs-primary-500] ${
                !STATUS_CONVERTOR[seekerRegisterRequest]?.isEditAvailable ? 'opacity-50' : null
              } bg-[--rs-primary-50] hover:bg-[--rs-primary-100] flex flex-col items-center rounded-lg pb-6  cursor-pointer`}>
              <div
                className="flex items-center flex-col w-full pt-5"
                onClick={() => {
                  if (!STATUS_CONVERTOR[seekerRegisterRequest]?.isEditAvailable) {
                    navigate('/choose-role');
                  } else {
                    navigate('/seeker/first');
                  }
                }}>
                <h3 className="text-h3 mb-4 text-[--rs-primary-800]">کارجو</h3>
                <div className="w-full h-auto mx-auto">
                  <UserIcon className="mx-auto" />
                </div>
              </div>
              <span className="font-bold">
                {STATUS_CONVERTOR[seekerRegisterRequest]?.default ?? <br />}{' '}
                {STATUS_CONVERTOR[seekerRegisterRequest]?.isEditAvailable ? (
                  <span
                    className="text-[--rs-primary-400] hover:text-[--rs-primary-500]"
                    onClick={() =>
                      handleOpen(
                        handleReservedoctor,
                        'seeker',
                        `${seekerRegisterReason.Description}`,
                        `علت ${STATUS_CONVERTOR[seekerRegisterRequest]?.short}`,
                        'اصلاح اطلاعات',
                        'بستن'
                      )
                    }>
                    {seekerRegisterReason.Description ? '(مشاهده جزییات)' : <br />}
                  </span>
                ) : null}
              </span>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            md={12}
            lg={12}
            xl={6}
            className="max-w-[100%] mb-2">
            <div
              className={`border-[1px] border-[--rs-primary-500] ${
                !STATUS_CONVERTOR[corporationRegisterRequest]?.isEditAvailable ? 'opacity-50' : null
              } bg-[--rs-primary-50] hover:bg-[--rs-primary-100] flex flex-col items-center rounded-lg pb-6  cursor-pointer`}>
              <div
                className="flex items-center flex-col w-full pt-5"
                onClick={() => {
                  if (!STATUS_CONVERTOR[corporationRegisterRequest]?.isEditAvailable) {
                    navigate('/choose-role');
                  } else {
                    navigate('/corporation/first');
                  }
                }}>
                <h3 className="text-h3 mb-4 text-[--rs-primary-800]">کسب و کار</h3>
                <div className="w-full h-auto mx-auto">
                  <VendorIcon className="mx-auto" />
                </div>
              </div>
              <span className="font-bold">
                {STATUS_CONVERTOR[corporationRegisterRequest]?.default ?? <br />}{' '}
                {STATUS_CONVERTOR[corporationRegisterRequest]?.isEditAvailable ? (
                  <span
                    className="text-[--rs-primary-400] hover:text-[--rs-primary-500]"
                    onClick={() =>
                      handleOpen(
                        handleReservedoctor,
                        'corporation',
                        `${corporationRegisterReason.Description}`,
                        `علت ${STATUS_CONVERTOR[corporationRegisterRequest]?.short}`,
                        'اصلاح اطلاعات',
                        'بستن'
                      )
                    }>
                    {corporationRegisterReason.Description ? '(مشاهده جزییات)' : <br />}
                  </span>
                ) : null}
              </span>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            md={12}
            lg={12}
            xl={6}
            className="max-w-[100%] mb-2">
            <div
              className={`border-[1px] border-[--rs-primary-500] ${
                !STATUS_CONVERTOR[consultantRegisterRequest]?.isEditAvailable ? 'opacity-50' : null
              } bg-[--rs-primary-50] hover:bg-[--rs-primary-100] flex flex-col items-center rounded-lg pb-6  cursor-pointer`}>
              <div
                className="flex items-center flex-col w-full pt-5"
                onClick={() => {
                  if (!STATUS_CONVERTOR[consultantRegisterRequest]?.isEditAvailable) {
                    navigate('/choose-role');
                  } else {
                    navigate('/consultant/first');
                  }
                }}>
                <h3 className="text-h3 mb-4 text-[--rs-primary-800]">مشاور</h3>
                <div className="w-full h-auto mx-auto">
                  <ConsultantIcon className="mx-auto" />
                </div>
              </div>
              <span className="font-bold">
                {STATUS_CONVERTOR[consultantRegisterRequest]?.default ?? <br />}{' '}
                {STATUS_CONVERTOR[consultantRegisterRequest]?.isEditAvailable ? (
                  <span
                    className="text-[--rs-primary-400] hover:text-[--rs-primary-500]"
                    onClick={() =>
                      handleOpen(
                        handleReservedoctor,
                        'consultant',
                        `${consultantRegisterReason.Description}`,
                        `علت ${STATUS_CONVERTOR[consultantRegisterRequest]?.short}`,
                        'اصلاح اطلاعات',
                        'بستن'
                      )
                    }>
                    {consultantRegisterReason.Description ? '(مشاهده جزییات)' : <br />}
                  </span>
                ) : null}
              </span>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            md={12}
            lg={12}
            xl={3}
            className="max-w-[100%] mb-2"></FlexboxGrid.Item>
        </FlexboxGrid>
      )}

      <h4 className="text-h3 mt-4">درخواست ها</h4>

      <FlexboxGrid className="mt-4">
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <div
            className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
            <div className="flex items-center flex-col w-full pt-5">
              <h3 className="text-h4 mb-4 text-[--rs-primary-800]">پروژه های سازمانی</h3>
              <div className="w-full h-auto mx-auto">
                <ProjectsIcon className="mx-auto" />
              </div>
              <div className="mt-2 flex gap-2">
                <Link to={ROUTES.roles.reqOrganizationalProjects.personal}>
                  <Button appearance="primary" size="sm">
                    ثبت درخواست
                  </Button>
                </Link>
                <Link to={ROUTES.roles.reqOrganizationalProjects.list}>
                  <Button appearance="ghost" size="sm">
                    مشاهده درخواست ها
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <div
            className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
            <div className="flex items-center flex-col w-full pt-5">
              <h3 className="text-h4 mb-4 text-[--rs-primary-800]">دریافت سرمایه</h3>
              <div className="w-full h-auto mx-auto">
                <CorporateIcon className="mx-auto" />
              </div>
              <div className="mt-2 flex gap-2">
                <Link to={ROUTES.roles.reqFounder.personal}>
                  <Button appearance="primary" size="sm">
                    ثبت درخواست
                  </Button>
                </Link>
                <Link to={ROUTES.roles.reqFounder.list}>
                  <Button appearance="ghost" size="sm">
                    مشاهده درخواست ها
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <div
            className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
            <div className="flex items-center flex-col w-full pt-5">
              <h3 className="text-h4 mb-4 text-[--rs-primary-800]">آموزش های سازمانی</h3>
              <div className="w-full h-auto mx-auto">
                <TrainingsIcon className="mx-auto" />
              </div>
              <div className="mt-2 flex gap-2">
                <Link to={ROUTES.roles.reqOrganizationalTrainings.personal}>
                  <Button appearance="primary" size="sm">
                    ثبت درخواست
                  </Button>
                </Link>
                <Link to={ROUTES.roles.reqOrganizationalTrainings.list}>
                  <Button appearance="ghost" size="sm">
                    مشاهده درخواست ها
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <div
            className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
            <div className="flex items-center flex-col w-full pt-5">
              <h3 className="text-h4 mb-4 text-[--rs-primary-800]">بازاریابی و فروش</h3>
              <div className="w-full h-auto mx-auto">
                <MarketingIcon className="mx-auto" />
              </div>
              <div className="mt-2 flex gap-2">
                <Link to={ROUTES.roles.reqMarketing.personal}>
                  <Button appearance="primary" size="sm">
                    ثبت درخواست
                  </Button>
                </Link>
                <Link to={ROUTES.roles.reqMarketing.list}>
                  <Button appearance="ghost" size="sm">
                    مشاهده درخواست ها
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <div
            className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
            <div className="flex items-center flex-col w-full pt-5">
              <h3 className="text-h4 mb-4 text-[--rs-primary-800]">استعلام و ارائه خدمات</h3>
              <div className="w-full h-auto mx-auto">
                <InqueryIcon className="mx-auto" />
              </div>
              <div className="mt-2 flex gap-2">
                <Link to={ROUTES.roles.reqInquery.personal}>
                  <Button appearance="primary" size="sm">
                    ثبت درخواست
                  </Button>
                </Link>
                <Link to={ROUTES.roles.reqInquery.list}>
                  <Button appearance="ghost" size="sm">
                    مشاهده درخواست ها
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <div
            className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
            <div className="flex items-center flex-col w-full pt-5">
              <h3 className="text-h4 mb-4 text-[--rs-primary-800]"> تبدیل ایده به محصول</h3>
              <div className="w-full h-auto mx-auto">
                <IdeaIcon className="mx-auto" />
              </div>
              <div className="mt-2 flex gap-2">
                <Link to={ROUTES.roles.reqIdea.personal}>
                  <Button appearance="primary" size="sm">
                    ثبت درخواست
                  </Button>
                </Link>
                <Link to={ROUTES.roles.reqIdea.list}>
                  <Button appearance="ghost" size="sm">
                    مشاهده درخواست ها
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <div
            className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
            <div className="flex items-center flex-col w-full pt-5">
              <h3 className="text-h4 mb-4 text-[--rs-primary-800]">درخواست نیرو به صورت ویژه</h3>
              <div className="w-full h-auto mx-auto">
                <RecruitmentIcon className="mx-auto" />
              </div>
              <div className="mt-2 flex gap-2">
                <Link to={ROUTES.roles.reqRecruitment.personal}>
                  <Button appearance="primary" size="sm">
                    ثبت درخواست
                  </Button>
                </Link>
                <Link to={ROUTES.roles.reqRecruitment.list}>
                  <Button appearance="ghost" size="sm">
                    مشاهده درخواست ها
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <div
            className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
            <div className="flex items-center flex-col w-full pt-5">
              <h3 className="text-h4 mb-4 text-[--rs-primary-800]">سرمایه گذاری سودآور</h3>
              <div className="w-full h-auto mx-auto">
                <InvestmentIcon className="mx-auto" />
              </div>
              <div className="mt-2 flex gap-2">
                <Link to={ROUTES.roles.reqInvestment.personal}>
                  <Button appearance="primary" size="sm">
                    ثبت درخواست
                  </Button>
                </Link>
                <Link to={ROUTES.roles.reqInvestment.list}>
                  <Button appearance="ghost" size="sm">
                    مشاهده درخواست ها
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};
export default ChooseRole;
