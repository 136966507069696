import { FC, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
// import { useUser } from '../../contexts/user';
import { routes } from '../../router';
import { useUser } from '../../contexts/user';
// import Cookies from 'js-cookie';
import { CustomLoader } from '../basic/loader';
// import { getUserProfile } from '../../core';
// import { handleGetUserRoles } from '../../api-handler';
// import { CustomLoader } from '../basic/loader';
// import { getUserProfile } from '../../core';
// import Cookies from 'js-cookie';

// todo add auth
const RouteHandler: FC = () => {
  // const { setUser, userTrigger, logout } = useUser();
  const { userTrigger } = useUser();
  const [isPing] = useState(true);
  const [loading, setLoading] = useState(true);
  // const [isPing, setIsPing] = useState(false);

  useEffect(() => {
    setLoading(false);
    // (async () => {
    //   setLoading(true);
    //   const token = Cookies.get('token');
    //   const username = Cookies.get('username');
    //   const admin = Cookies.get('admin');
    //   const selectedRole = Cookies.get('selectedRole') ?? 'User';
    //   if (admin === 'yes') {
    //     setUser({
    //       isLoggedIn: true,
    //       Username: username,
    //       Roles: ['Admin'],
    //       selectedRole: 'Admin'
    //     });
    //   }
    //   if (token && username && !admin) {
    //     try {
    //       const { status, data } = await getUserProfile(username);
    //       if (status === 200) {
    //         const res = await handleGetUserRoles(username);
    //         setUser({
    //           ...data,
    //           isLoggedIn: true,
    //           Username: username,
    //           Roles: res.data ?? [],
    //           selectedRole
    //         });
    //       } else {
    //         setUser({ isLoggedIn: false, Username: null, Roles: [], selectedRole: 'Guest' });
    //       }
    //     } catch (e) {
    //       console.log(e);
    //       setUser({ isLoggedIn: false, Username: null, Roles: [], selectedRole: 'Guest' });
    //       logout();
    //     }
    //   } else {
    //     setUser({ isLoggedIn: false, Username: null, Roles: [], selectedRole: 'Guest' });
    //     logout();
    //   }
    //   setLoading(false);
    // })();
  }, [userTrigger]);

  const routing = useRoutes(routes(!!isPing));

  if (loading) return <CustomLoader />;
  // if (loading) return <CustomLoader />;

  return routing;
};

export default RouteHandler;
