/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Button, Modal } from 'rsuite';
import { handlePutRejectResume, handlePutAcceptResume } from '../../../../api-handler';
import { useParams } from 'react-router-dom';
export const BoughtModal = (props: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const { username } = useParams();
  return (
    <Modal backdrop={true} size="lg" keyboard={true} open={props.isOpen} onClose={props.onClose}>
      <Modal.Header>
        <Modal.Title className="text-center pe-0 !font-bold !pl-6">تعیین وضیت کارجو</Modal.Title>
      </Modal.Header>

      <Modal.Body className="!mt-8">
        <p>
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک
          است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
          تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی
          در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم
          افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در
          زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه
          راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و
          جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
        </p>
        <div className=" flex justify-evenly items-center mt-4">
          <Button
            appearance="primary"
            onClick={async () => {
              setLoading(true);
              try {
                await handlePutRejectResume(username ?? '-1');
              } catch (e) {
                console.log(e);
              }
              setLoading(false);
              props.onClose();
            }}
            loading={loading}>
            کارجو با نیاز های ما سازگار نبود
          </Button>
          <Button
            appearance="primary"
            onClick={async () => {
              setLoading(true);
              try {
                await handlePutAcceptResume(username ?? '-1');
              } catch (e) {
                console.log(e);
              }
              setLoading(true);
              props.onClose();
            }}
            loading={loading}>
            کارجو را استخدام کرده ام
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
