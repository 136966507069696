/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, Input, Checkbox } from 'rsuite';
import TextField from '../../../components/form/textfield';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlexboxGrid } from 'rsuite';
import Field from '../../../components/form/field';
import TextAreaField from '../../../components/form/textarea-field';
import { showToast } from '../../../utils/toast';
import {
  handlePostSeekerEducationBackground,
  handlePutSeekerEducationBackground
} from '../../../api-handler';
import {
  PostSeekerEducationBackgroundModel,
  defaultSeekerEducationBackgroundFormValue,
  defaultSeekerEducationBackgroundSchema
} from '../../../models';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router';
import { CountryStateCity } from './../../../components/form/country-state-city';
import { convertDateToSlashi } from '../../../utils/helpers';
import { Datepicker } from '@ijavad805/react-datepicker';

export const EducationBackgroundForm = (props: {
  readonly?: boolean;
  handleAddDoingData: (newData: PostSeekerEducationBackgroundModel) => void;
  handleUpdateDoingData: (updateData: PostSeekerEducationBackgroundModel) => void;
  defaultValue?: PostSeekerEducationBackgroundModel | null;
  hideNextButton?: boolean;
  forceReadOnly?: boolean;
  disabledMode?: boolean;
  hasEditMode?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isAdmin: boolean;
  onDelete: (id: string | number) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [disabledMode, setDisabledMode] = useState(props.hasEditMode);
  const [forceReadOnly] = useState(props.forceReadOnly);
  const [readOnly, setReadOnly] = useState(props.readonly);
  const [currentDoing, setCurrentDoing] = useState(props.defaultValue?.StudyNow);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>(
    props.defaultValue ?? defaultSeekerEducationBackgroundFormValue
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultValue, setDefaultValue] = useState<any>(
    props.defaultValue ?? defaultSeekerEducationBackgroundFormValue
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeStamp, setTimeStamp] = useState<any>(new Date());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeStampEnd, setTimeStampEnd] = useState<any>(new Date());

  const handleSubmit = useCallback(
    async (isNext: boolean, updateMode: boolean, isSkip = false) => {
      if (isSkip) {
        navigate(ROUTES.roles.reqSeeker.courseBackground);
      }
      if (formRef.current && !formRef.current.check()) {
        console.log('formError', formError);
        return;
      }

      if (!formValue) return;
      const payload = {
        ...formValue,
        StudyNow: currentDoing == true,
        Start_Date: timeStamp,
        End_Date: timeStampEnd,
        // Country: 'ایران',
        Country: formValue['Country-t'] == 'ایران' ? 'ایران' : formValue.Country ?? 'ایران'
      };
      if (payload['Country-t']) delete payload['Country-t'];

      console.log(payload);
      if (!currentDoing) {
        if (!payload.End_Date) {
          setFormError((pre) => {
            return { ...pre, End_Date: 'الزامی است!' };
          });
          return;
        }
      }
      setLoading(true);
      if (updateMode) {
        const ress = await handlePutSeekerEducationBackground(payload, 'ID', payload.ID);
        if (ress.ok) {
          showToast('فرم به روزرسانی شد!', 'success');
          setDisabledMode(true);
          // setForceReadOnly(true);
          setReadOnly(true);
          setLoading(false);
          setDefaultValue(payload);
          if (isNext) {
            navigate(ROUTES.roles.reqSeeker.courseBackground);
            return;
          }
          props.handleUpdateDoingData({ ...payload });
        }
        setLoading(false);
        return;
      }
      const res = await handlePostSeekerEducationBackground(payload);
      setLoading(false);
      if (res.ok) {
        showToast('فرم ثبت شد!', 'success');
        // or getting submitted data from backend
        if (isNext) {
          navigate(ROUTES.roles.reqSeeker.courseBackground);
          return;
        }
        props.handleAddDoingData({ ...payload, ID: res.data.ID });
        setCurrentDoing(false);
        setFormValue(defaultSeekerEducationBackgroundFormValue);
      }
    },
    [formValue, formError, timeStamp, timeStampEnd, formRef, currentDoing]
  );

  useEffect(() => {
    const startDate: any = props?.defaultValue?.Start_Date ?? null;
    const endDate: any = props?.defaultValue?.End_Date ?? null;
    setTimeStamp(startDate ? new Date(startDate) : new Date());
    setTimeStampEnd(endDate ? new Date(endDate) : new Date());
  }, [props.defaultValue]);
  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={defaultSeekerEducationBackgroundSchema}
      onSubmit={() => handleSubmit(false, false)}
      formValue={formValue}
      checkTrigger="change"
      className="mt-6 text-dark"
      readOnly={forceReadOnly ?? loading}
      disabled={disabledMode}>
      <FlexboxGrid className="bg-[--rs-primary-50] rounded-md border-[1px] border-[--rs-primary-500] p-3">
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] !px-0">
          <Field
            name="StudyNow"
            onChange={(value: string | number, checked: boolean) => {
              setCurrentDoing(checked);
            }}
            label=""
            checked={currentDoing}
            accepter={Checkbox}
            dir="rtl"
            full
            readOnly={readOnly}>
            در این محل درحال تحصیل هستم.
          </Field>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%] !px-0">
          <FlexboxGrid>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
              <TextField
                name="Name"
                label={currentDoing ? 'نام محل تحصیل فعلی' : 'نام محل تحصیل'}
                type="text"
                accepter={Input}
                dir="rtl"
                full
                placeholder=""
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
              <TextField
                name="Number"
                label={currentDoing ? 'شماره تلفن تحصیل فعلی' : 'شماره تلفن محل تحصیل'}
                type="text"
                accepter={Input}
                dir="ltr"
                full
                placeholder=""
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>

            <CountryStateCity
              hasCountry={true}
              setFormValue={setFormValue}
              countryOptions={{
                default: formValue?.Country,
                name: 'Country',
                label: 'کشور',
                colspan: 24,
                sm: 24,
                md: 8,
                readOnly
              }}
              stateOptions={{
                default: formValue?.State,
                name: 'State',
                label: 'استان',
                colspan: 24,
                sm: 24,
                md: 8,
                readOnly
              }}
              cityOptions={{
                default: formValue?.City,
                name: 'City',
                label: 'شهر',
                colspan: 24,
                sm: 24,
                md: 8,
                readOnly
              }}
            />
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="Field"
                label="رشته تحصیلی"
                type="text"
                accepter={Input}
                dir="rtl"
                full
                placeholder=""
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="Orientation"
                label="گرایش"
                type="text"
                accepter={Input}
                dir="rtl"
                full
                placeholder=""
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="Grade"
                label="مقطع"
                type="text"
                accepter={Input}
                dir="rtl"
                full
                placeholder=""
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            {!currentDoing ? (
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <TextField
                  name="Average"
                  label="معدل کل"
                  type="text"
                  accepter={Input}
                  dir="rtl"
                  full
                  placeholder=""
                  req
                  readOnly={readOnly}
                />
              </FlexboxGrid.Item>
            ) : null}
            {/* todo: */}
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <Field
                accepter={Datepicker}
                className="font-fd"
                name="Start_Date"
                lang={'fa'}
                theme={'green'}
                format={'D MMMM yyyy'}
                value={convertDateToSlashi(timeStamp)}
                input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(val: any) => setTimeStamp(new Date(val))}
                label="تاریخ شروع"
                dir="ltr"
                full
                req
              />
            </FlexboxGrid.Item>

            {!currentDoing ? (
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
                <Field
                  accepter={Datepicker}
                  className="font-fd"
                  name="End_Date"
                  lang={'fa'}
                  theme={'green'}
                  format={'D MMMM yyyy'}
                  value={convertDateToSlashi(timeStampEnd)}
                  input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(val: any) => setTimeStampEnd(new Date(val))}
                  label="تاریخ پایان"
                  dir="ltr"
                  full
                  req
                />
              </FlexboxGrid.Item>
            ) : null}
          </FlexboxGrid>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] !px-0">
          <FlexboxGrid>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
              <TextAreaField
                name="Address"
                label={currentDoing ? 'آدرس محل تحصیل فعلی' : 'آدرس محل تحصیل'}
                dir="rtl"
                full
                req
                rows={5}
                placeholder="آدرس را بدون ذکر شهر و استان نوشته و با ، جدا کنید."
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
              <TextAreaField
                name="Description"
                label={'توضیحات'}
                dir="rtl"
                full
                rows={5}
                placeholder=""
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
          {props.isLast && !props.isAdmin ? (
            <div className="flex justify-between">
              {defaultValue.ID ? (
                <Button
                  appearance="primary"
                  type="button"
                  onClick={() => {
                    setDisabledMode(false);
                    // setForceReadOnly(false);
                    setReadOnly(false);
                  }}
                  loading={loading}>
                  ویرایش
                </Button>
              ) : null}
              {!props.isFirst ? (
                <Button
                  appearance="ghost"
                  type="button"
                  className="mr-auto"
                  onClick={() => {
                    props.onDelete(defaultValue.ID ?? null);
                  }}
                  loading={loading}>
                  حذف
                </Button>
              ) : null}
            </div>
          ) : null}
          {props.hasEditMode && !props.isAdmin ? (
            <div>
              {disabledMode ? (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      setDisabledMode(false);
                      // setForceReadOnly(false);
                      setReadOnly(false);
                    }}
                    loading={loading}>
                    ویرایش
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      props.onDelete(defaultValue.ID ?? null);
                    }}
                    loading={loading}>
                    حذف
                  </Button>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      handleSubmit(false, true);
                    }}
                    loading={loading}>
                    به روزرسانی
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      setFormValue(defaultValue);
                      setDisabledMode(true);
                      // setForceReadOnly(true);
                      setReadOnly(true);
                    }}
                    loading={loading}>
                    لغو
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {!props.isLast ? null : (
        <>
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="ml-auto mt-4"
              type="button"
              onClick={() => handleSubmit(false, false)}
              loading={loading}>
              {props.hideNextButton ? 'ثبت تحصیل فعلی' : 'افزودن سابقه جدید'}
            </Button>
          </div>
          {props.hideNextButton ? null : (
            <div className="flex justify-between">
              <Button
                appearance="default"
                className="ml-auto mt-8"
                type="button"
                onClick={() => {
                  navigate(ROUTES.roles.reqSeeker.workBackground);
                }}
                loading={loading}>
                مرحله قبل
              </Button>
              <div>
                {props.isFirst ? (
                  <Button
                    appearance="default"
                    className="mr-auto mt-8 ml-2"
                    type="button"
                    onClick={() => handleSubmit(true, false, true)}
                    loading={loading}>
                    سابقه تحصیلی ندارم
                  </Button>
                ) : null}
                <Button
                  appearance="primary"
                  className="mr-auto mt-8"
                  type="button"
                  onClick={() => handleSubmit(true, false)}
                  loading={loading}>
                  ثبت و مرحله بعد
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </Form>
  );
};
