import { Col, FlexboxGrid } from 'rsuite';
// import { ReactComponent as UserIcon } from '../../assets/icons/roles/user.svg';
// import { ReactComponent as VendorIcon } from '../../assets/icons/roles/vendor.svg';
// import { ReactComponent as ConsultantIcon } from '../../assets/icons/roles/consultant.svg';
import { ReactComponent as CorporateIcon } from '../../../../assets/icons/roles/corporate.svg';
import { ReactComponent as ProjectsIcon } from '../../../../assets/icons/roles/projects.svg';
import { ReactComponent as TrainingsIcon } from '../../../../assets/icons/roles/trainings.svg';
import { ReactComponent as MarketingIcon } from '../../../../assets/icons/roles/marketing.svg';
import { ReactComponent as InqueryIcon } from '../../../../assets/icons/roles/inquery.svg';
import { ReactComponent as IdeaIcon } from '../../../../assets/icons/roles/idea.svg';
import { ReactComponent as InvestmentIcon } from '../../../../assets/icons/roles/investment.svg';
import { ReactComponent as RecruitmentIcon } from '../../../../assets/icons/roles/recruitment.svg';

import { Link } from 'react-router-dom';

export const AdminRequests = () => {
  return (
    <>
      <h4 className="text-h3 mt-4">درخواست ها</h4>

      <FlexboxGrid className="mt-4">
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <Link to={`/admin/register-organizational-projects-list`}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h4 mb-4 text-[--rs-primary-800]">پروژه های سازمانی</h3>
                <div className="w-full h-auto mx-auto">
                  <ProjectsIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <Link to={'/admin/register-founder-list'}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h4 mb-4 text-[--rs-primary-800]">دریافت سرمایه</h3>
                <div className="w-full h-auto mx-auto">
                  <CorporateIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <Link to={'/admin/register-organizational-trainings-list'}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h4 mb-4 text-[--rs-primary-800]">آموزش های سازمانی</h3>
                <div className="w-full h-auto mx-auto">
                  <TrainingsIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <Link to={`/admin/register-marketing-list`}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h4 mb-4 text-[--rs-primary-800]">بازاریابی و فروش</h3>
                <div className="w-full h-auto mx-auto">
                  <MarketingIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <Link to={'/admin/register-inquery-list'}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h4 mb-4 text-[--rs-primary-800]">استعلام و ارائه خدمات</h3>
                <div className="w-full h-auto mx-auto">
                  <InqueryIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <Link to={`/admin/register-idea-list`}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h4 mb-4 text-[--rs-primary-800]"> تبدیل ایده به محصول</h3>
                <div className="w-full h-auto mx-auto">
                  <IdeaIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <Link to={`/admin/register-recruitment-list`}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h4 mb-4 text-[--rs-primary-800]">درخواست نیرو به صورت ویژه</h3>
                <div className="w-full h-auto mx-auto">
                  <RecruitmentIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          md={12}
          lg={12}
          xl={6}
          className="max-w-[100%] mb-2">
          <Link to={`/admin/register-investment-list`}>
            <div
              className={`border-[1px] border-[--rs-primary-500] bg-[--rs-primary-50] flex flex-col items-center rounded-lg pb-6 `}>
              <div className="flex items-center flex-col w-full pt-5">
                <h3 className="text-h4 mb-4 text-[--rs-primary-800]">سرمایه گذاری سودآور</h3>
                <div className="w-full h-auto mx-auto">
                  <InvestmentIcon className="mx-auto" />
                </div>
              </div>
            </div>
          </Link>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};
