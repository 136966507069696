import { Button, Col, Form, Input, Schema } from 'rsuite';
import TextField from '../../../../components/form/textfield';
import { useCallback, useRef, useState } from 'react';
import { FlexboxGrid } from 'rsuite';
import { Hide, Show } from 'react-iconly';
import IconTextField from '../../../../components/form/icon-textfield';
import { handleSignupUser } from '../../../../api-handler';
import { formatOutgoingPhoneNumber } from '../../../../utils/helpers';
import { useUser } from '../../../../contexts/user';
import { useNavigate, useSearchParams } from 'react-router-dom';

const { StringType } = Schema.Types;
const model = Schema.Model({
  FirstName: StringType('').isRequired('الزامی است!'),
  LastName: StringType('').isRequired('الزامی است!'),
  Password: StringType('').isRequired('الزامی است!').minLength(6, 'رمزعبور باید حداقل ۶ رقم باشد!'),
  RePassword: StringType('')
    .isRequired('الزامی است!')
    .minLength(6, 'رمزعبور باید حداقل ۶ رقم باشد!'),
  Email: StringType('')
    .pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'فرمت وارد شده اشتباه است!')
    .isRequired('الزامی است!')
});

export const SignupPersonal = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setStep: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  step: any;
}) => {
  const [searchParams] = useSearchParams();
  const [redirect] = useState(searchParams.get('redirect'));
  const { setToken, userTrigger, setUserTrigger } = useUser();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    console.log('submitted', formValue);
    if (formValue.Password !== formValue.RePassword) {
      setFormError((pre) => {
        return { ...pre, RePassword: 'تکرار رمز عبور با رمزعبور متفاوت است!' };
      });
      return;
    }
    setLoading(true);
    // eslint-disable-next-line no-unused-vars
    const { RePassword, ...payload } = formValue;
    const res = await handleSignupUser({
      ...payload,
      Username: props.step.data.Username,
      CellPhone: formatOutgoingPhoneNumber(props.step.data.Username)
    });
    if (res.ok) {
      // set token and redirect
      setToken(res.data.Data, props.step.data.Username, 'no');
      setUserTrigger(!userTrigger);
      // navigate('/choose-role');
      navigate(redirect ?? '/');
    }
    setLoading(false);
    props.setStep(props.step);
  }, [formValue, formError, formRef, props]);

  //
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);

  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={model}
      onSubmit={handleSubmit}
      readOnly={loading}
      checkTrigger="change"
      className="mt-8 text-dark">
      <FlexboxGrid className="max-w-min md:max-w-[612px]">
        <FlexboxGrid.Item as={Col} sm={24} md={12} className="max-w-[100%]">
          <TextField
            name="FirstName"
            label="نام"
            type="text"
            accepter={Input}
            dir="rtl"
            placeholder=""
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} sm={24} md={12} className="max-w-[100%]">
          <TextField
            name="LastName"
            label="نام خانوادگی"
            type="text"
            accepter={Input}
            dir="rtl"
            placeholder=""
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} sm={24} md={12} className="max-w-[100%]">
          <TextField
            name="Email"
            label="ایمیل"
            type="email"
            accepter={Input}
            dir="ltr"
            placeholder=""
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} sm={24} md={12} className="max-w-[100%]">
          <TextField
            name="NID"
            label="کد ملی"
            type="number"
            accepter={Input}
            dir="ltr"
            placeholder=""
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} sm={24} md={12} className="max-w-[100%]">
          <IconTextField
            name="Password"
            label="رمز عبور"
            type={visible ? 'text' : 'password'}
            accepter={Input}
            dir="ltr"
            onIconCLick={() => setVisible(!visible)}
            eyeVisible={visible}
            onIcon={<Show size="small" />}
            offIcon={<Hide size="small" />}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} sm={24} md={12} className="max-w-[100%]">
          <IconTextField
            name="RePassword"
            label="تکرار رمز عبور"
            type={visible2 ? 'text' : 'password'}
            accepter={Input}
            dir="ltr"
            onIconCLick={() => setVisible2(!visible2)}
            eyeVisible={visible2}
            onIcon={<Show size="small" />}
            offIcon={<Hide size="small" />}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Button
        appearance="primary"
        type="submit"
        className="w-[50%] mx-auto !block mt-8"
        loading={loading}>
        ادامه
      </Button>
    </Form>
  );
};
