import { ReactNode, useEffect, useState } from 'react';
import { useUser } from '../../contexts/user';
import Cookies from 'js-cookie';
import { CustomLoader } from '../../components/basic/loader';
import { getUserProfile } from '../../core';
import { handleGetUserRoles } from '../../api-handler';
// import { CustomLoader } from '../basic/loader';
// import { getUserProfile } from '../../core';
// import Cookies from 'js-cookie';

// todo add auth guard
export const AuthGuard = (props: { children: ReactNode }) => {
  const { setUser, userTrigger, logout } = useUser();
  const [loading, setLoading] = useState(true);
  // const [isPing, setIsPing] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const token = Cookies.get('token');
      const username = Cookies.get('username');
      // const admin = Cookies.get('admin');
      const selectedRole = Cookies.get('selectedRole') ?? 'User';
      // if (admin === 'yes') {
      //   setUser({
      //     isLoggedIn: true,
      //     Username: username,
      //     Roles: ['Admin'],
      //     selectedRole: 'Admin'
      //   });
      // }
      if (token && username) {
        try {
          const { status, data } = await getUserProfile(username);
          if (status === 200) {
            const res = await handleGetUserRoles(username);
            setUser({
              ...data,
              isLoggedIn: true,
              Username: username,
              Roles: res.data ?? [],
              selectedRole
            });
          } else {
            setUser({ isLoggedIn: false, Username: null, Roles: [], selectedRole: 'Guest' });
          }
        } catch (e) {
          console.log(e);
          setUser({ isLoggedIn: false, Username: null, Roles: [], selectedRole: 'Guest' });
          logout();
        }
      } else {
        setUser({ isLoggedIn: false, Username: null, Roles: [], selectedRole: 'Guest' });
        logout();
      }
      setLoading(false);
    })();
  }, [userTrigger]);

  if (loading) return <CustomLoader />;
  return <>{props.children}</>;
};
