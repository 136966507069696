// import { useState } from 'react';
import LocationIcon from '../../../assets/icons/consultants/location.svg';
import StarIcon from '../../../assets/icons/consultants/star.svg';
import LikeIcon from '../../../assets/icons/consultants/like.svg';
import CheckIcon from '../../../assets/icons/consultants/check.svg';
import { Col, FlexboxGrid, Button, Input } from 'rsuite';
// import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AdminConsultantRegisterModel } from '../../../models/admin';
import { handleGetAdminConsultantsRegisters } from '../../../api-handler/admin';
import { CustomLoader } from './../../../components/basic/loader';
import { handleGetUserProfilePictureByUsername } from '../../../api-handler';
import { UPLOAD_FILE_URL } from '../../../core';
import { base64ToJson, formatCategory } from '../../../utils/helpers';
import { openInNewTab } from '../../../components/route-handler/openI-in-new-tab';

export const ConsultantsList = () => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AdminConsultantRegisterModel[]>([]);
  const [filteredData, setFilteredData] = useState<AdminConsultantRegisterModel[]>([]);
  const [searchCategory, setSearchCategory] = useState('');
  const [search, setSearch] = useState('');
  const [job, setJob] = useState('');
  const [city, setCity] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [pics, setPics] = useState<any>({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetAdminConsultantsRegisters();

      if (res.ok) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setData(res.data.filter((item: any) => item.Status == 'Accepted'));
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    setFilteredData(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.filter((item: any) => {
        return (
          item.Category.toLowerCase().search(searchCategory.trim().toLowerCase()) >= 0 &&
          item.FullName.toLowerCase().search(search.trim().toLowerCase()) >= 0 &&
          item.City.toLowerCase().search(city.trim().toLowerCase()) >= 0 &&
          item.Job.toLowerCase().search(job.trim().toLowerCase()) >= 0
        );
      })
    );
  }, [search, city, job, data, searchCategory]);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.forEach(async (item: any) => {
        const res = await handleGetUserProfilePictureByUsername(item.Username);
        if (res.ok) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setPics((pre: any) => {
            return { ...pre, [item.Username]: res.data };
          });
        }
      });
      // }
    })();
  }, [data]);
  return (
    <FlexboxGrid>
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] mb-3">
        <h4 className="text-h4">لیست مشاوران</h4>
      </FlexboxGrid.Item>
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            sm={24}
            md={24}
            lg={6}
            className="max-w-[100%] mb-3">
            <label>
              دسته بندی
              <Input
                type="text"
                placeholder="دسته بندی"
                className="mt-1 mb-4 !w-full"
                onChange={setSearchCategory}
                value={searchCategory}
              />
            </label>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            sm={24}
            md={24}
            lg={6}
            className="max-w-[100%] mb-3">
            <label>
              نام مشاور
              <Input
                type="text"
                placeholder="جستجو نام مشاور"
                className="mt-1 mb-4 !w-full"
                onChange={setSearch}
                value={search}
              />
            </label>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            sm={24}
            md={24}
            lg={6}
            className="max-w-[100%] mb-3">
            <label>
              دسته
              <Input
                type="text"
                placeholder="دسته"
                className="mt-1 mb-4 !w-full"
                onChange={setJob}
                value={job}
              />
            </label>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            colspan={24}
            sm={24}
            md={24}
            lg={6}
            className="max-w-[100%] mb-3">
            <label>
              شهر
              <Input
                type="text"
                placeholder="شهر"
                className="mt-1 mb-4 !w-full"
                onChange={setCity}
                value={city}
              />
            </label>
          </FlexboxGrid.Item>
          {filteredData.map((item) => {
            return (
              <FlexboxGrid.Item
                key={item.ID.toString()}
                as={Col}
                colspan={24}
                sm={24}
                md={8}
                className="max-w-[100%] mb-3">
                <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 w-full">
                  <div className="flex">
                    {pics[item.Username]?.Status === 'Accepted' ? (
                      <div
                        className="w-[80px] h-[80px] aspect-square rounded-full flex items-center justify-center overflow-hidden"
                        style={{ aspectRatio: 1 }}>
                        <img
                          src={`${UPLOAD_FILE_URL}?${base64ToJson(pics[item.Username]?.PictureLink)[0]?.url}&Attachment=False`}
                          style={{ aspectRatio: 1 }}
                          alt=""
                          className="w-full"
                        />
                      </div>
                    ) : (
                      <div
                        className="w-[80px] h-[80px] aspect-square rounded-full bg-[--rs-primary-50]"
                        style={{ aspectRatio: 1 }}></div>
                    )}
                    <div className="mr-3 mt-3 w-[50%]">
                      <h5 className="text-h5 text-[--rs-primary-800]">{item.FullName}</h5>
                      <p className="text-b1 mb-0 mt-1 text-[--rs-primary-400] ellipsis-consultant">
                        {formatCategory(item.Category)}
                      </p>
                    </div>
                    <div className="flex items-center mr-auto mb-10">
                      <img src={LocationIcon} alt="location" />
                      <p className="text-b1 text-[--rs-primary-400] ml-3">{item.City}</p>
                    </div>
                  </div>

                  <div className="flex justify-between mt-4">
                    <div className="flex items-center justify-center w-full">
                      <img src={StarIcon} alt="star" />
                      <span className="text-b3 text-[--rs-primary-500] mr-1">
                        <strong>4.9</strong> (21 نظر)
                      </span>
                    </div>
                    <div className="flex items-center justify-center w-full">
                      <img src={LikeIcon} alt="like" />
                      <span className="text-b3 text-[--rs-success-800] mr-1">
                        100٪پیشنهاد کاربران
                      </span>
                    </div>
                    <div className="flex items-center justify-center w-full">
                      <img src={CheckIcon} alt="check" />
                      <span className="text-b3 text-[--rs-info-700] mr-1">120 نوبت موفق</span>
                    </div>
                  </div>
                  <div className="flex items-center mt-6">
                    <Button
                      appearance="primary"
                      className="mx-auto min-w-[50%] mb-2 inline-block"
                      type="button"
                      onClick={
                        () => openInNewTab(`/consultants/${item.Username}`)
                        // () => navigate(`/consultants/${item.Username}`)
                      }>
                      مشاهده پروفایل
                    </Button>
                  </div>
                </div>
              </FlexboxGrid.Item>
            );
          })}
          {filteredData.length == 0 ? (
            <div className="text-center text-h4 h-[30vh] flex justify-center items-center w-full">
              مشاوری یافت نشد!
            </div>
          ) : null}
        </>
      )}
    </FlexboxGrid>
  );
};
