import { Schema } from 'rsuite';

const { StringType, BooleanType } = Schema.Types;
export interface SeekerFormValueModel {
  FirstName: String;
  LastName: String;
  NID: Number;
  // Sex: 'خانم' | 'آقا';
  MilitaryService: String;
  FathersName: String;
  Email: String;
  // CountryE: String;
  // CountryB: String;
  StateE: String;
  CityE: String;
  CellPhone: Number;
  Website: String;
  // Birth_Date: String | Date;
  RID: Number;
  EduDegree: String;
  Religion: String;
  Nationality: String;
  HealthStatus: String;
  ResidentialUse: String;
  Marriage: String;
  NIDPic: String;
  EDUPic: String;
}

export interface PostSeekerPersonalModel extends SeekerFormValueModel {
  Sex: Boolean;
  CountryB: String;
  CountryE: String;
  Birth_Date: String | Date;
}

export const SeekerPersonalFormSchema = Schema.Model({
  FirstName: StringType('').isRequired('الزامی است!'),
  LastName: StringType('').isRequired('الزامی است!'),
  NID: StringType('').isRequired('الزامی است!'),
  // Sex: StringType('').isRequired('الزامی است!'),
  MilitaryService: StringType(''),
  FathersName: StringType('').isRequired('الزامی است!'),
  Email: StringType('')
    .isRequired('الزامی است!')
    .pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'فرمت وارد شده اشتباه است!'),
  // CountryB: StringType('').isRequired('الزامی است!'),
  StateE: StringType(''),
  CityE: StringType('').isRequired('الزامی است!'),
  CellPhone: StringType('')
    .isRequired('الزامی است!')
    .pattern(/^0[0-9]{10}$/, 'شماره تلفن را با صفر و بدون کد کشور وارد کنید!'),
  Website: StringType('').pattern(
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/,
    'فرمت وبسایت وارد شده اشتباه است! مثال https://cbcs.ir'
  ),
  // Birth_Date: StringType('').isRequired('الزامی است!'),
  RID: StringType('').isRequired('الزامی است!'),
  EduDegree: StringType('').isRequired('الزامی است!'),
  Religion: StringType('').isRequired('الزامی است!'),
  Nationality: StringType('').isRequired('الزامی است!'),
  HealthStatus: StringType('').isRequired('الزامی است!'),
  ResidentialUse: StringType('').isRequired('الزامی است!'),
  Marriage: StringType('').isRequired('الزامی است!'),
  NIDPic: StringType(''),
  EDUPic: StringType('')
});

// work
export interface SeekerWorkBackgroundFormValueModel {
  ID?: String;
  CurrentWorkName: String;
  CurrentWorkNumber: String;
  Role: Number;
  // Country: String;
  State: String;
  City: String;
  // Start_Date?: String | Date;
  // End_Date?: String | Date;
  Address: String;
  Duration?: String;
  AgreeToShow?: Boolean;
  Insurance?: Boolean;
  TimeInsurance?: Boolean;
  LastSalary?: String;
  CurrentWork?: Boolean;
  ReasonLeaveJob?: String;
}

export interface PostSeekerWorkBackgroundModel extends SeekerWorkBackgroundFormValueModel {
  Country: String;
  Start_Date?: String | Date;
  End_Date?: String | Date;
}

export const seekerWorkBackgroundFormModel = Schema.Model({
  CurrentWorkName: StringType('').isRequired('الزامی است!'),
  CurrentWorkNumber: StringType('').isRequired('الزامی است!'),
  Role: StringType('').isRequired('الزامی است!'),
  // Country: StringType('').isRequired('الزامی است!'),
  State: StringType(''),
  City: StringType('').isRequired('الزامی است!'),
  // Start_Date: StringType('').isRequired('الزامی است!'),
  // End_Date: StringType('').isRequired('الزامی است!'),
  Address: StringType('').isRequired('الزامی است!'),
  Duration: StringType(''),
  AgreeToShow: BooleanType('').isRequired('الزامی است!'),
  CurrentWork: BooleanType('').isRequired('الزامی است!'),
  Insurance: BooleanType('').isRequired('الزامی است!'),
  TimeInsurance: StringType(''),
  LastSalary: StringType(''),
  ReasonLeaveJob: StringType('')
});
export const defaultSeekerWorkBackgroundFormValue = {
  CurrentWorkName: '',
  CurrentWorkNumber: '',
  Role: '',
  State: '',
  City: '',
  Country: 'ایران',
  Address: '',
  Duration: '',
  TimeInsurance: '',
  LastSalary: '',
  ReasonLeaveJob: '',
  Start_Date: new Date(),
  CurrentWork: false,
  AgreeToShow: false,
  Insurance: false
};

// education
export interface SeekerEducationBackgroundFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Number: String;
  // Country: String;
  State: String;
  City: String;
  Address: String;
  Description: String;
  Field: String;
  Orientation: String;
  Grade: String;
  Section: String;
  Average: String;
  // StudyNow: boolean;
  // Start_Date: String;
  // End_Date: String;
}
export const defaultSeekerEducationBackgroundFormValue = {
  Name: '',
  Number: '',
  State: '',
  City: '',
  Address: '',
  Description: '',
  Field: '',
  Orientation: '',
  Grade: '',
  Average: '',
  Start_Date: new Date(),
  End_Date: new Date()
};

export const defaultSeekerEducationBackgroundSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Number: StringType('').isRequired('الزامی است!'),
  // Country: StringType('').isRequired('الزامی است!'),
  State: StringType(''),
  City: StringType('').isRequired('الزامی است!'),
  // Start_Date: StringType('').isRequired('الزامی است!'),
  // End_Date: StringType('').isRequired('الزامی است!'),
  Address: StringType('').isRequired('الزامی است!'),
  Description: StringType(''),
  Field: StringType('').isRequired('الزامی است!'),
  Orientation: StringType(''),
  Grade: StringType('').isRequired('الزامی است!'),
  Average: StringType('')
});

export interface PostSeekerEducationBackgroundModel
  extends SeekerEducationBackgroundFormValueModel {
  StudyNow: boolean;
  Country: String;
  Start_Date: String;
  End_Date?: String;
}

// Course

export interface SeekerCourseBackgroundFormValueModel {
  // Username: String;
  ID?: String;
  CourseName: String;
  InstitutionName: String;
  Category: String;
  CertificationPic?: string;
  Priod: String;
  Description?: String;
}
export const defaultSeekerCourseBackgroundFormValue = {
  CourseName: '',
  InstitutionName: '',
  Category: '',
  CertificationPic: '',
  Priod: '',
  Description: ''
};

export const defaultSeekerCourseBackgroundSchema = Schema.Model({
  CourseName: StringType('').isRequired('الزامی است!'),
  InstitutionName: StringType('').isRequired('الزامی است!'),
  Category: StringType('').isRequired('الزامی است!'),
  Priod: StringType('').isRequired('الزامی است!'),
  Description: StringType(''),
  CertificationPic: StringType('')
});

export interface PostSeekerCourseBackgroundModel extends SeekerCourseBackgroundFormValueModel {}

// Skill
export interface SeekerSkillBackgroundFormValueModel {
  // Username: String;
  ID?: String;
  Skills: String;
  Level: String;
  Description?: String;
}
export const defaultSeekerSkillBackgroundFormValue = {
  Skills: '',
  Level: '',
  Description: ''
};

export const defaultSeekerSkillBackgroundSchema = Schema.Model({
  Skills: StringType('').isRequired('الزامی است!'),
  Level: StringType('').isRequired('الزامی است!'),
  Description: StringType('')
});

export interface PostSeekerSkillBackgroundModel extends SeekerSkillBackgroundFormValueModel {}

// Desire

export interface SeekerDesireFormValueModel {
  // Username: String;
  ID?: String;
  LayerOne: String;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Layertwo: any;
  LayerThree: String;
}
export const defaultSeekerDesireFormValue = {
  LayerOne: '',
  Layertwo: '',
  LayerThree: ''
};

export const defaultSeekerDesireSchema = Schema.Model({
  LayerOne: StringType('').isRequired('الزامی است!'),
  Layertwo: StringType('').isRequired('الزامی است!'),
  LayerThree: StringType('')
});

export interface PostSeekerDesireModel extends SeekerDesireFormValueModel {}

// social
export interface SeekerSocialFormValueModel {
  // Username: String;
  Instagram?: String;
  WhatsApp?: String;
  Linkedin?: String;
  Telegram?: String;
  Github?: String;
  Karlancer?: String;
}
export const defaultSeekerSocialFormValue = {
  Instagram: '',
  WhatsApp: '',
  Linkedin: '',
  Telegram: '',
  Github: '',
  Karlancer: ''
};
export interface PostSeekerSocialModel extends SeekerSocialFormValueModel {}
