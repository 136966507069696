import { Divider } from 'rsuite';
import { UPLOAD_FILE_URL } from '../../../../core';
import { base64ToJson } from '../../../../utils/helpers';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const SeekerCourseBackgroundView = ({ data, canSeeFull }: any) => {
  return (
    <>
      {/* <h4 className="mb-4">دوره های گذرانده</h4> */}
      <div className="w-full">
        <Divider className="text-[20px] font-bold">دوره های گذرانده</Divider>
      </div>

      <ol style={{ listStyle: 'auto', paddingRight: '12px' }}>
        {data?.map((course: any) => {
          return (
            <li key={course.id} className="mb-4">
              <p className="text-[16px]">
                دوره <strong>{course.CourseName}</strong> از{' '}
                <strong>{course.InstitutionName}</strong> به مدت <strong>{course.Priod}</strong>{' '}
                <strong className="text-[--rs-primary-500]">
                  {base64ToJson(course.CertificationPic)[0]?.url ? '(قابل ارائه گواهی)' : ''}
                </strong>
              </p>
              {course.Description ? <>- {course.Description}</> : ''}
              {canSeeFull && base64ToJson(course.CertificationPic)[0]?.url ? (
                <>
                  <br />
                  <a
                    target="_blank"
                    href={`${UPLOAD_FILE_URL}?${base64ToJson(course.CertificationPic)[0]?.url}&Attachment=False`}
                    className="text-primary hover:text-primary-dark"
                    rel="noreferrer">
                    مشاهده/دریافت گواهی
                  </a>
                </>
              ) : (
                ''
              )}
            </li>
          );
        })}
      </ol>
    </>
  );
};
