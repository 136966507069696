import Cookies from 'js-cookie';

export const APP_BASE_URL = 'https://api.cbcs.atlasrnd.group';
// export const APP_BASE_URL = 'http://127.0.0.1:5567';
export const AUTH_BASE_URL = 'https://api.auth.cbcs.atlasrnd.group';
// export const AUTH_BASE_URL = 'http://127.0.0.1:5566';
export const UPLOAD_FILE_URL = 'https://api.arkheion.atlasrnd.group';
// export const UPLOAD_FILE_URL = 'http://185.208.175.55:5568';
export const IMAGE_BASE_URL = '';
export const VIDEO_BASE_URL = '';
export const DISABLE_BUYING_RESUME = true;
export const getUsername = () => {
  return Cookies.get('username');
};
