import { Button, Checkbox } from 'rsuite';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router';

export const ConsultantFirst = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  return (
    <>
      <h4 className="text-h4">تعهدات زیر را با دقت بخوانید</h4>

      <div className="flex flex-col mt-4">
        <div className="max-h-[50vh] overflow-y-auto p-4 rounded-md bg-[--rs-primary-50] border-[1px] border-[--rs-primary-500] text-justify pretty-scroll mb-3">
          - مشاور مکلف است که اطلاعات صحیح و دقیق خود را اعلام نماید و در صورت مشاهده هر گونه مغایرت
          در اطلاعات مشاور، مطلب از صفحه ایشان حذف و در صورت تکرار، پروفایل ایشان بسته خواهد شد.
          <br />
          <br />
          - مشاور، مکلف است که اطلاعات مشاوره گیرنده را محرمانه تلقی کند و در حفظ آن کوشا باشد و
          امانت دار اطلاعات ایشان باشد.
          <br />
          <br />
          - پس از برگزاری جلسات مشاوره به مشکلات هر جلسه رسیدگی خواهد شد و گزارش جلسات مشاوره می
          بایست توسط مشاور و مشاوره گیرنده در سامانه ثبت و اعلام گردد.
          <br />
          <br />
          - پس از پرداخت هزینه و دریافت زمان مشاوره، توافقنامه تحت هیچ شرایطی قابل فسخ نبوده و مبلغ
          پرداختی به هیچ عنوان مسترد نمی‌گردد.
          <br />
          <br />
          مبلغ حق الزحمه مشاور به صورت ۷۰ درصد مبلغ مشاوره و با کسر ۱۰ درصد ارزش افزوده در نظر گرفته
          شود. و تسویه هر ماه در ابتدای ماه بعد انجام خواهد شد.
        </div>
        <Checkbox
          checked={isChecked}
          dir="rtl"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(_value: any, checked: boolean, _event: any) => setIsChecked(checked)}>
          تمام موارد را خواندم و با تمامی موارد موافق هستم.
        </Checkbox>
        <div className="flex justify-between">
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="button"
            onClick={() => {
              navigate(ROUTES.roles.reqConsultant.personal);
            }}
            disabled={!isChecked}>
            مرحله بعد
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConsultantFirst;
