import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, InputPicker, FlexboxGrid } from 'rsuite';
import Field from '../../components/form/field';

import { showToast } from '../../utils/toast';

import {
  handlePostCorporationIntro,
  handleGetCorporationIntro,
  handlePutCorporationIntro
} from '../../api-handler';
import { CorporationIntroFormSchema } from '../../models';

import { ROUTES } from '../../router';
import { base64ToJson, jsonToBase64 } from '../../utils/helpers';
import { UploadField } from '../../components/form/uploader';
import TextAreaField from '../../components/form/textarea-field';
import CategoryOptions from '../../data/corporate/category.json';

export const CorporationIntro = (props: {
  disabledMode?: boolean;
  isAdmin?: boolean;
  username?: string;
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initial, setInitial] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});

  const [loadingUpload, setLoadingUpload] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedPICLogoUrl, setUploadedPICLogoUrl] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initPICLogoJson, setInitPICLogoJson] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const normalizeFormValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fv: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const PICLogoJson: any = [...initPICLogoJson];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(uploadedPICLogoUrl).forEach((file: any) => {
        PICLogoJson.push(uploadedPICLogoUrl[file]);
      });
      console.log(PICLogoJson);
      const PICLogoEncoded = jsonToBase64(PICLogoJson);

      const payload = {
        ...fv,
        PICLogo: PICLogoEncoded
      };

      if (payload.File) delete payload.File;

      return payload;
    },
    [uploadedPICLogoUrl, initPICLogoJson]
  );

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    console.log(formValue);
    if (!formValue) return;
    const payload = normalizeFormValue(formValue);
    if (!payload) return;
    setLoading(true);
    if (editMode) {
      // todo check update
      const updateRes = await handlePutCorporationIntro(payload, 'ID', initial.ID);
      if (updateRes.ok) {
        showToast('مشخصات فردی به روزرسانی شد!', 'success');
        navigate(ROUTES.roles.reqCorporation.good);
      }
      setLoading(false);
      return;
    }
    console.log(payload);
    const res = await handlePostCorporationIntro(payload);
    if (res.ok) {
      showToast('مشخصات فردی ثبت شد!', 'success');
      navigate(ROUTES.roles.reqCorporation.good);
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [formValue, formError, formRef, uploadedPICLogoUrl, initPICLogoJson]);

  const handleGetInitialData = async (username: string | null | undefined) => {
    setUploadedPICLogoUrl({});
    setLoading(true);
    const res = await handleGetCorporationIntro(username);
    if (res.ok) {
      setInitial(res.data);
      setFormValue(res.data);
      setEditMode(true);
      setInitPICLogoJson(base64ToJson(res.data?.PICLogo));
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetInitialData(props.isAdmin ? props.username : null);
    })();
  }, [props.username]);

  // if (loading) {
  //   return <CustomLoader />;
  // }
  return (
    <>
      <h4 className="text-h4">{props.isAdmin ? 'معرفی کسب و کار' : 'معرفی کسب و کار'}</h4>

      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={CorporationIntroFormSchema}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading}
        disabled={props.disabledMode}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField name="History" label="تاریخچه" dir="rtl" full placeholder="" />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField name="Value" label="ارزش‌ها" dir="rtl" full placeholder="" />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <TextAreaField name="Sight" label="چشم انداز" dir="rtl" full placeholder="" />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] !px-0">
            <FlexboxGrid>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <TextAreaField
                  name="Activity"
                  label="فعالیت ما"
                  dir="rtl"
                  full
                  req
                  placeholder=""
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] !px-0">
            <FlexboxGrid>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <Field
                  accepter={InputPicker}
                  data={[
                    { label: 'کشاورزی', value: 'کشاورزی' },
                    { label: 'صنعتی', value: 'صنعتی' },
                    { label: 'سلامت', value: 'سلامت' },
                    { label: 'خدمات', value: 'خدمات' },
                    { label: 'سایر', value: 'سایر' }
                  ]}
                  name="MajorEconomic"
                  label="بخش عمده فعالیت اقتصادی"
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
                <Field
                  accepter={InputPicker}
                  data={CategoryOptions}
                  name="Category"
                  label="دسته بندی کسب و کار"
                  dir="rtl"
                  full
                  req
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] font-en">
            <TextAreaField
              name="IntroVideo"
              label="لینک آپارات ویدیو معرفی"
              dir="ltr"
              full
              placeholder="مانند: https://www.aparat.com/v/3r69l"
            />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
            <UploadField
              label="تصویر بک گراند"
              req
              full
              setLoadingUpload={setLoadingUpload}
              name="PICLogo"
              fileName="PICLogo"
              domain="Uploads/"
              initJson={initPICLogoJson}
              setInitJson={setInitPICLogoJson}
              uploadedUrl={uploadedPICLogoUrl}
              setUploadedUrl={setUploadedPICLogoUrl}
              readOnly={props.isAdmin ?? props.disabledMode}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        {!props.disabledMode ? (
          <div className="flex justify-between">
            <Button
              appearance="default"
              className="ml-auto mt-8"
              type="button"
              onClick={() => navigate(ROUTES.roles.reqCorporation.personal)}
              loading={loading || loadingUpload}>
              مرحله قبل
            </Button>
            <Button
              appearance="primary"
              className="mr-auto mt-8"
              type="submit"
              loading={loading || loadingUpload}>
              مرحله بعد
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
};
