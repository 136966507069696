/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from 'rsuite';

export const ConsultantConsultationBackgroundView = ({ data }: any) => {
  return (
    <>
      {/* <h4 className="mb-4">سوابق تحصیلی</h4> */}
      <div className="w-full">
        <Divider className="text-[20px] font-bold">سوابق مشاوره ای</Divider>
      </div>
      <ol style={{ listStyle: 'auto', paddingRight: '12px' }}>
        {data?.map((consult: any) => {
          return (
            <li key={consult.id} className="mb-4">
              <p className="text-[16px]">
                در زمینه <strong>{consult.Field}</strong> به{' '}
                <strong>{consult.NameCounselor}</strong> مشاوره داده شد.
                <br />
                کار انجام شده: <strong>{consult.WorkedOn}</strong>
                <br />
                نتیجه: <strong>{consult.Result}</strong>
              </p>
              <p className="text-justify">
                {consult.Description ? <>- {consult.Description}</> : ''}
              </p>
            </li>
          );
        })}
      </ol>
    </>
  );
};
