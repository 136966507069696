import { useEffect, useState } from 'react';
import { LoginForm } from './components/login';
import { Card } from '../../components/basic/card';
import Logo from '../../assets/images/brand/rcg.png';
import Cookies from 'js-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const Auth = (props: { isAdminMode: boolean }) => {
  const [step, setStep] = useState({ action: 'login', mode: 'personal', role: 'user', data: {} });
  const [searchParams] = useSearchParams();
  const [redirect] = useState(searchParams.get('redirect'));
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const token = Cookies.get('token');
    if (token) {
      navigate(redirect ?? '/');
    }
    setLoading(false);
  }, [redirect]);

  return loading ? null : (
    <div>
      <div className="h-[146px]">
        <img
          src={Logo}
          alt="Logo"
          className="block mx-auto mb-[-20px] mt-0 xl:mt-[-32px] max-w-[200px] w-full"
        />
      </div>
      <Card className="mx-auto relative">
        <LoginForm step={step} setStep={setStep} isAdminMode={props.isAdminMode} />
      </Card>
    </div>
  );
};

export default Auth;
