import { Button, Checkbox } from 'rsuite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router';

export const SeekerFirst = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <h4 className="text-h4">تعهدات زیر را با دقت بخوانید</h4>

      <div className="flex flex-col mt-4">
        <div className="max-h-[50vh] overflow-y-auto p-4 rounded-md bg-[--rs-primary-50] border-[1px] border-[--rs-primary-500] text-justify pretty-scroll mb-3">
          - در صورت استفاده از شماره تماس و یا کلمات غیر اخلاقی، در بخش‌های غیر مرتبط، رزومه کارجو
          به صورت کامل حذف و کارجو در لیست سیاه قرار می‌گیرد تا از ثبت نام مجدد وی در سامانه جلوگیری
          به عمل آید.
          <br />
          <br />
          - کارجو با ثبت رزومه در سامانه اجازه دیدن اطلاعات رزومه خود را به کارفرما می‌دهد.
          <br />
          <br />
          - اگر کارجو مایل نباشد رزومه‌اش در بانک رزومه‌ها قرار گیرد و بخواهد نمایش عمومی آن محدود
          باشد، باید در بخش تنظیمات پنل خود و در قسمت حریم خصوصی رزومه خود را از حالت عمومی خارج
          نماید تا فقط کارفرمایانی که کارجو خود برای آنها رزومه ارسال می‌کند، امکان دیدن اطلاعات
          رزومه فرد را داشته باشند.
          <br />
          <br />
          - سامانه cbcs.ir تمامی تلاش خود را به کار می‌بندد تا بهترین و مناسب‌ترین فرصت‌های شغلی را
          در اختیار کارجویان گرامی قرار دهد، اما بدیهی است که سامانه cbcs.ir قادر نخواهد بود ضمانتی
          در مورد یافتن شغل برای کارجو ارائه نماید.
          <br />
          <br />
          - سامانه جامع مشاوره هیچگونه مسئولیتی در قبال قرارداد بین کارفرما و کارجو ندارد.
          <br />
          <br />
          - در صورتی که کارجو جهت ارائه رزومه به کارفرما، تاییدیه اعلام کند و در جلسه مصاحبه حضور
          نیابد، رزومه کارجو از صفحه سامانه حذف خواهد شد.
          <br />
          <br />- در صورت ارائه اطلاعات نادرست توسط کارجو، پروفایل کارجو، مسدود و در لیست سیاه قرار
          خواهد گرفت.
        </div>
        <Checkbox
          checked={isChecked}
          dir="rtl"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(_value: any, checked: boolean, _event: any) => setIsChecked(checked)}>
          تمام موارد را خواندم و با تمامی موارد موافق هستم.
        </Checkbox>
        <div className="flex justify-between">
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="button"
            onClick={() => navigate(ROUTES.roles.reqSeeker.personal)}
            disabled={!isChecked}>
            مرحله بعد
          </Button>
        </div>
      </div>
    </>
  );
};
