import { appNetworkManager } from './network-manager';
import { getUsername } from './config';

export const getWorkshops = () => {
  return appNetworkManager.get(`/workshopdetailsforms`);
};
export const getWorkshop = (id: string) => {
  return appNetworkManager.get(`/workshopdetailsform?ID=${id}`);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postWorkshop = (payload: any) => {
  return appNetworkManager.post(`/workshopdetailsform`, payload);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putWorkshop = (payload: any) => {
  const { eventID, ...rest } = payload;
  return appNetworkManager.put(`/workshopdetailsform`, { ...rest, ID: eventID });
};
export const getUserBoughtEvents = () => {
  return appNetworkManager.get(`/workshopuser?Username=${getUsername()}`);
};
export const getWorkshopBoughts = (id: string | undefined) => {
  return appNetworkManager.get(`/workshopuser?WorkShopID=${id}`);
};

export const deleteWorkshop = (id: number | string) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.delete(`/workshopdetailsform?ID=${id}`, {});
};
