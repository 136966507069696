/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useState } from 'react';
// import LocationIcon from '../../../assets/icons/consultants/location.svg';
// import StarIcon from '../../../assets/icons/consultants/star.svg';
// import LikeIcon from '../../../assets/icons/consultants/like.svg';
// import CheckIcon from '../../../assets/icons/consultants/check.svg';
// import EducationIcon from '../../../assets/icons/consultants/education.svg';
import { Col, FlexboxGrid, Button } from 'rsuite';
import { useParams } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import { Spacer } from './../../../components/basic/spacer';
import { useUser } from '../../../contexts/user';
// import { ROLE_CONVERTOR } from '../../../utils/helpers';
import {
  // eslint-disable-next-line no-unused-vars
  handlePostCorporationBuycv,
  handleGetCorporationBuycv,
  // eslint-disable-next-line no-unused-vars
  handleGetSeekerRegisterRequest,
  handleGetSeekerRegisterRequestByUsername,
  handleGetSeekerDesire,
  handleGetSeekerCourseBackground,
  handleGetSeekerEducationBackground,
  handleGetSeekerSkillBackground,
  handleGetSeekerWorkBackground,
  handleGetSeekerPersonal,
  handleGetSeekerSocial
} from '../../../api-handler';
import { APP_BASE_URL, getUsername } from '../../../core';
import { SeekerCourseBackgroundView } from './view/seeker-course-background';
import { SeekerDesiredFieldsView } from './view/seeker-desired-fields';
import { SeekerEducationBackgroundView } from './view/seeker-education-background';
import { SeekerSkillBackgroundView } from './view/seeker-skill-background';
import { SeekerWorkBackgroundView } from './view/seeker-work-background';
import { SeekerPersonalView } from './view/seeker-personal';
import { BoughtModal } from './popups/bought-modal';
import { openInNewTab } from '../../../components/route-handler/openI-in-new-tab';

export const SingleSeeker = () => {
  const { user } = useUser();
  const { username } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const [showRequestConsult, setShowRequestConsult] = useState(false);
  const [isBoughtBeMe, setIsBoughtBeMe] = useState(false);
  const [isBought, setIsBought] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const [isWorking, setIsWorking] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleBuySeeker = useCallback(async () => {
    if (!username) return;
    // const res = await handlePostCorporationBuycv(username);
    // // todo add payment around here
    // if (res.ok) {
    //   setIsBought(true);
    // }
    window.open(`
      ${APP_BASE_URL}/buycv?EmployerUsername=${getUsername()}&JobSeekerUsername=${username}`);
  }, [username]);

  useEffect(() => {
    (async () => {
      const res = await handleGetCorporationBuycv(username ?? '-1');
      if (res.ok) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const bought = res.data.filter((item: any) => item.JobSeekerUsername == username);
        setIsBought(bought.length > 0); // check again
      } else {
        setIsBought(false);
      }
    })();
  }, [username]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetSeekerRegisterRequestByUsername(username ?? '-1');
      if (res.ok) {
        setIsLocked(res.data.IsLocked === 'Locked' || res.data.IsLocked === 'Working');
        setIsWorking(res.data.IsLocked === 'Working');
        setIsBoughtBeMe(res.data.BuyerUsername == user.Username);
      } else {
        setIsLocked(true);
        setIsWorking(false);
        setIsBoughtBeMe(false);
      }
      setLoading(false);
    })();
  }, [username]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [seekerDesired, setSeekerDesired] = useState<any>([]);
  const [seekerCourseBackground, setSeekerCourseBackground] = useState<any>([]);
  const [seekerEducationBackground, setSeekerEducationBackground] = useState<any>([]);
  const [seekerSkillBackground, setSeekerSkillBackground] = useState<any>([]);
  const [seekerWorkBackground, setSeekerWorkBackground] = useState<any>([]);
  const [seekerPersonal, setSeekerPersonal] = useState<any>(null);
  const [seekerSocial, setSeekerSocial] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const res = await handleGetSeekerSocial(username);
      if (res.ok) {
        setSeekerSocial(res.data);
      } else {
        setSeekerSocial(null);
      }
    })();
  }, [username]);
  useEffect(() => {
    (async () => {
      const res = await handleGetSeekerDesire(username);
      if (res.ok) {
        setSeekerDesired(res.data);
      } else {
        setSeekerDesired([]);
      }
    })();
  }, [username]);

  useEffect(() => {
    (async () => {
      const res = await handleGetSeekerCourseBackground(username);
      if (res.ok) {
        setSeekerCourseBackground(res.data);
      } else {
        setSeekerCourseBackground([]);
      }
    })();
  }, [username]);

  useEffect(() => {
    (async () => {
      const res = await handleGetSeekerEducationBackground(username);
      if (res.ok) {
        setSeekerEducationBackground(res.data);
      } else {
        setSeekerEducationBackground([]);
      }
    })();
  }, [username]);

  useEffect(() => {
    (async () => {
      const res = await handleGetSeekerSkillBackground(username);
      if (res.ok) {
        setSeekerSkillBackground(res.data);
      } else {
        setSeekerSkillBackground([]);
      }
    })();
  }, [username]);

  useEffect(() => {
    (async () => {
      const res = await handleGetSeekerWorkBackground(username);
      if (res.ok) {
        setSeekerWorkBackground(res.data);
      } else {
        setSeekerWorkBackground([]);
      }
    })();
  }, [username]);

  useEffect(() => {
    (async () => {
      const res = await handleGetSeekerPersonal(username);
      if (res.ok) {
        setSeekerPersonal(res.data);
      } else {
        setSeekerPersonal(null);
      }
    })();
  }, [username]);

  const [isOpenBought, setIsOpenBought] = useState(false);

  return (
    <FlexboxGrid className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-full">
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        <h4 className="text-h4 flex justify-between items-center text-[--rs-primary-800] mb-3">
          {user.Username == username ? (
            <>
              رزومه شما
              <Button
                appearance="primary"
                onClick={() => {
                  openInNewTab(`${APP_BASE_URL}/pdfcv?Username=${username}`);
                }}
                loading={loading}>
                دانلود رزومه من
              </Button>
            </>
          ) : null}
          {isBought && isBoughtBeMe && !isWorking ? (
            <>
              تعیین وضعیت کارجو
              <Button
                appearance="primary"
                onClick={() => {
                  setIsOpenBought(true);
                }}
                loading={loading}>
                رزومه کارجو را برسی کردم
              </Button>
            </>
          ) : (
            <></>
          )}
          {isBought ? (
            <>
              <Button
                appearance="primary"
                onClick={() => {
                  openInNewTab(`${APP_BASE_URL}/pdfcv?Username=${username}`);
                }}
                loading={loading}>
                دانلود رزومه کارجو
              </Button>
            </>
          ) : null}
        </h4>
        {/* <div className="bg-[--rs-white] border-[--rs-primary-300] rounded-lg border-[1px] p-4 md:p-8 w-full">
          <>
            <Tabs activeKey={activeKey} onSelect={setActiveKey} appearance="subtle">
              <Tabs.Tab eventKey="Personal" title="اطلاعات پایه">
                <Spacer s={4} />
                {!isBought ? null : (
                  <SeekerPersonal disabledMode={true} isAdmin={true} username={username} />
                )}
              </Tabs.Tab>
            </Tabs>
          </>
        </div> */}
      </FlexboxGrid.Item>

      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        <SeekerPersonalView
          data={seekerPersonal}
          socialData={seekerSocial}
          canSeeFull={isBought}
          isLocked={isLocked}
          isWorking={isWorking}
          isBought={isBought}
          loading={loading}
          handleBuySeeker={handleBuySeeker}
        />
      </FlexboxGrid.Item>

      <Spacer s={4} />

      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        <SeekerWorkBackgroundView data={seekerWorkBackground} canSeeFull={isBought} />
      </FlexboxGrid.Item>

      <Spacer s={4} />

      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        <SeekerEducationBackgroundView data={seekerEducationBackground} canSeeFull={isBought} />
      </FlexboxGrid.Item>

      <Spacer s={4} />

      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        <SeekerDesiredFieldsView data={seekerDesired} canSeeFull={isBought} />
      </FlexboxGrid.Item>

      <Spacer s={4} />

      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        <SeekerCourseBackgroundView data={seekerCourseBackground} canSeeFull={isBought} />
      </FlexboxGrid.Item>

      <Spacer s={4} />

      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
        <SeekerSkillBackgroundView data={seekerSkillBackground} canSeeFull={isBought} />
      </FlexboxGrid.Item>

      <BoughtModal
        onOpen={() => setIsOpenBought(true)}
        onClose={() => {
          setIsOpenBought(false);
        }}
        isOpen={isOpenBought}
      />
    </FlexboxGrid>
  );
};
