import { Button } from 'rsuite';
import { useCallback, useEffect, useState } from 'react';

import {
  handlePostSeekerRegisterRequest,
  handleGetSeekerRegisterRequest,
  handlePutSeekerRegisterRequest
} from '../../api-handler';
import { showToast } from '../../utils/toast';
import { useNavigate } from 'react-router-dom';
import { CustomLoader } from './../../components/basic/loader';
import { ROUTES } from '../../router';
import { useUser } from '../../contexts/user';
import { SeekerPersonal } from './seeker-personal';
import { SeekerWorkBackground } from './seeker-work-background';
import { SeekerEducationBackground } from './seeker-education-background';
import { SeekerCourseBackground } from './seeker-course-background';
import { SeekerSkillBackground } from './seeker-skill-background';
import { SeekerDesire } from './seeker-desiere';
import { Spacer } from './../../components/basic/spacer';
import SeekerSocial from './seeker-social';

export const SeekerTnC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (editMode) {
      const res = await handlePutSeekerRegisterRequest({
        // Group: 'Seekers',
        Status: 'Pending'
      });
      if (res.ok) {
        showToast('درخواست شما با موفقیت بروزرسانی شد.', 'success');
        // todo: navigate to next step
        navigate('/choose-role');
      }
      setLoading(false);
      return;
    }
    const res = await handlePostSeekerRegisterRequest();
    if (res.ok) {
      showToast('درخواست شما با موفقیت ثبت شد.', 'success');
      // todo: navigate to next step
      navigate('/choose-role');
    }
    setLoading(false);
  }, [editMode]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetSeekerRegisterRequest();
      if (res.ok) {
        setEditMode(true);
      }
      setLoading(false);
    })();
  }, []);
  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4 text-center">از صحت اطلاعات وارد شده مطمئن شوید</h4>

      <SeekerPersonal disabledMode={true} isAdmin={true} username={user.Username} />
      <Spacer s={4} />
      <SeekerWorkBackground disabledMode={true} isAdmin={true} username={user.Username} />
      <Spacer s={4} />
      <SeekerEducationBackground disabledMode={true} isAdmin={true} username={user.Username} />
      <Spacer s={4} />
      <SeekerCourseBackground disabledMode={true} isAdmin={true} username={user.Username} />
      <Spacer s={4} />
      <SeekerSkillBackground disabledMode={true} isAdmin={true} username={user.Username} />
      <Spacer s={4} />
      <SeekerDesire disabledMode={true} isAdmin={true} username={user.Username} />
      <Spacer s={4} />
      <SeekerSocial disabledMode={true} isAdmin={true} username={user.Username} />

      <div className="flex flex-col mt-4">
        <div className="flex justify-between">
          <Button
            appearance="default"
            className="ml-auto mt-8"
            type="button"
            onClick={() => {
              navigate(ROUTES.roles.reqSeeker.social);
            }}
            loading={loading}>
            مرحله قبل
          </Button>
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="button"
            onClick={handleSubmit}
            loading={loading}>
            ثبت درخواست
          </Button>
        </div>
      </div>
    </>
  );
};
