import { Divider } from 'rsuite';

const pages: {
  [key: string]: { title: string; subtitle: string; items: (string | null)[] };
} = {
  default: {
    title: '',
    subtitle: '',
    items: [null]
  },
  admin: {
    title: 'ادمین',
    subtitle: '',
    items: [null]
  },
  chooseRole: {
    title: 'انتخاب نقش',
    subtitle: '',
    items: [null]
  },
  consultant: {
    subtitle: 'لطفا مراحل زیر را کامل کنید',
    title: 'پروفایل کاربری مشاور',
    items: [
      'تعهدات',
      'مشخصات فردی ',
      'سوابق شغلی',
      'سوابق تحصیلی',
      'سوابق مشاوره‌ای',
      'شبکه‌های اجتماعی',
      'مرور'
    ]
  },
  seeker: {
    subtitle: 'لطفا مراحل زیر را کامل کنید',
    title: 'پروفایل کاربری کارجو',
    items: [
      'تعهدات',
      'مشخصات فردی ',
      'سوابق شغلی',
      'سوابق تحصیلی',
      'دوره‌های گذرانده',
      'مهارت ها',
      'زمینه‌های موردعلاقه',
      'شبکه‌های اجتماعی',
      'مرور'
    ]
  },
  corporation: {
    subtitle: 'لطفا مراحل زیر را کامل کنید',
    title: 'پروفایل کسب و کار',
    items: ['تعهدات', 'مشخصات کسب و کار', 'معرفی کسب و کار', 'محصولات', 'خدمات', 'مرور']
  },
  founder: {
    subtitle: 'لطفا مراحل زیر را کامل کنید',
    title: 'درخواست',
    items: ['مشخصات', 'محصولات', 'خدمات', 'تعهدات']
  },
  idea: {
    subtitle: 'لطفا مراحل زیر را کامل کنید',
    title: 'درخواست',
    items: ['مشخصات', 'تعهدات']
  }
};

export const Sidebar = (props: { page: string; step: number }) => {
  return (
    <div className="bg-[--rs-white] px-8 py-6 rounded-md border-[1px] border-[--rs-primary-300] mr-2 md:mr-8 ml-2 md:ml-0 mb-4 md:mb-0">
      <div className="text-b2 text-center text-[--rs-primary-900]">
        {pages[props.page].title}
        <Divider />
        <div className="text-b1 font-bold text-right mb-4 text-[--rs-primary-800]">
          {pages[props.page].subtitle}
        </div>
        {pages[props.page].items.map((item: string | null, index: number) =>
          item ? (
            <div key={index} className="flex items-center mb-4 mr-4">
              <span
                className={`w-6 h-6 flex items-center justify-center rounded-full ml-2 text-[--rs-white] ${index + 1 === props.step ? 'bg-[--rs-primary-500]' : 'bg-[--rs-primary-200]'}`}>
                {index + 1}
              </span>
              <div
                className={`text-b2 text-right  ${index + 1 === props.step ? 'text-[--rs-primary-500]' : 'text-[--rs-primary-200]'}`}>
                {item}
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};
