import { Button, Col, FlexboxGrid, Form, Input, InputPicker } from 'rsuite';
import TextField from '../../../components/form/textfield';
import { useCallback, useRef, useState } from 'react';
import Field from '../../../components/form/field';
import TextAreaField from '../../../components/form/textarea-field';
import { showToast } from '../../../utils/toast';
import {
  handlePostSeekerCourseBackground,
  handlePutSeekerCourseBackground
} from '../../../api-handler';
import {
  PostSeekerCourseBackgroundModel,
  defaultSeekerCourseBackgroundFormValue,
  defaultSeekerCourseBackgroundSchema
} from '../../../models';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router';
import { base64ToJson, jsonToBase64 } from '../../../utils/helpers';
import { UploadField } from '../../../components/form/uploader';

export const CourseBackgroundForm = (props: {
  readonly?: boolean;
  handleAddDoingData: (newData: PostSeekerCourseBackgroundModel) => void;
  handleUpdateDoingData: (updateData: PostSeekerCourseBackgroundModel) => void;
  defaultValue?: PostSeekerCourseBackgroundModel | null;
  hideNextButton?: boolean;
  forceReadOnly?: boolean;
  disabledMode?: boolean;
  hasEditMode?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isAdmin: boolean;
  onDelete: (id: string | number) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [disabledMode, setDisabledMode] = useState(props.hasEditMode);
  const [forceReadOnly] = useState(props.forceReadOnly);
  const [readOnly, setReadOnly] = useState(props.readonly);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});

  const [loadingUpload, setLoadingUpload] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>(
    props.defaultValue ?? defaultSeekerCourseBackgroundFormValue
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedCertificationPicUrl, setUploadedCertificationPicUrl] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initCertificationPicJson, setInitCertificationPicJson] = useState<any>(
    base64ToJson(props.defaultValue?.CertificationPic ?? '')
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultValue, setDefaultValue] = useState<any>(
    props.defaultValue ?? defaultSeekerCourseBackgroundFormValue
  );

  const handleSubmit = useCallback(
    async (isNext: boolean, updateMode: boolean, isSkip = false) => {
      if (isSkip) {
        navigate(ROUTES.roles.reqSeeker.skillBackground);
      }
      if (formRef.current && !formRef.current.check()) {
        console.log('formError', formError);
        return;
      }

      if (!formValue) return;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const CertificationPicJson: any = [...initCertificationPicJson];
      console.log('initCertificationPicJson', initCertificationPicJson, 'should be empty now');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.keys(uploadedCertificationPicUrl).forEach((file: any) => {
        CertificationPicJson.push(uploadedCertificationPicUrl[file]);
      });
      console.log(uploadedCertificationPicUrl, CertificationPicJson, 'should have data(s)');
      const CertificationPicEncoded = jsonToBase64(CertificationPicJson);
      const payload = {
        ...formValue,
        CertificationPic: CertificationPicEncoded
      };
      if (payload.File) delete payload.File;

      console.log(payload);
      setLoading(true);
      if (updateMode) {
        if (payload.Username) delete payload.Username;
        const ress = await handlePutSeekerCourseBackground(payload, 'ID', payload.ID);
        if (ress.ok) {
          showToast('فرم به روزرسانی شد!', 'success');
          setDisabledMode(true);
          // setForceReadOnly(true);
          setReadOnly(true);
          setLoading(false);
          setDefaultValue(payload);
          if (isNext) {
            navigate(ROUTES.roles.reqSeeker.skillBackground);
            return;
          }
          props.handleUpdateDoingData({ ...payload });
        }
        setLoading(false);
        return;
      }
      const res = await handlePostSeekerCourseBackground(payload);
      setLoading(false);
      if (res.ok) {
        showToast('فرم ثبت شد!', 'success');
        // or getting submitted data from backend
        if (isNext) {
          navigate(ROUTES.roles.reqSeeker.skillBackground);
          return;
        }
        props.handleAddDoingData({ ...payload, ID: res.data.ID });
        setFormValue(defaultSeekerCourseBackgroundFormValue);
        setUploadedCertificationPicUrl({});
        setInitCertificationPicJson([]);
      }
    },
    [formValue, formError, formRef, uploadedCertificationPicUrl, initCertificationPicJson]
  );

  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={defaultSeekerCourseBackgroundSchema}
      onSubmit={() => handleSubmit(false, false)}
      formValue={formValue}
      checkTrigger="change"
      className="mt-6 text-dark"
      readOnly={forceReadOnly ?? loading}
      disabled={disabledMode}>
      <FlexboxGrid className="bg-[--rs-primary-50] rounded-md border-[1px] border-[--rs-primary-500] p-3">
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%] !px-0">
          <FlexboxGrid>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
              <TextField
                name="CourseName"
                label="نام دوره"
                type="text"
                accepter={Input}
                dir="rtl"
                full
                placeholder=""
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
              <TextField
                name="InstitutionName"
                label="نام موسسه آموزشی"
                type="text"
                accepter={Input}
                dir="rtl"
                full
                placeholder=""
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
              <Field
                name="Category"
                label="دسته‌بندی"
                accepter={InputPicker}
                data={[{ label: 'دسته‌بندی 1', value: 'دسته‌بندی 1' }]}
                dir="rtl"
                full
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} className="max-w-[100%]">
              <TextField
                name="Priod"
                label="مدت زمان دوره"
                type="text"
                accepter={Input}
                dir="rtl"
                full
                placeholder="برای مثال یک ماه"
                req
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] !px-0">
          <FlexboxGrid>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
              <TextAreaField
                name="Description"
                label={'توضیحات (مواردی که آموختید)'}
                dir="rtl"
                full
                rows={5}
                placeholder=""
                readOnly={readOnly}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={16} className="max-w-[100%]">
          <UploadField
            label="گواهی"
            full
            setLoadingUpload={setLoadingUpload}
            name="CertificationPic"
            fileName="CertificationPic"
            domain="Uploads/"
            initJson={initCertificationPicJson}
            setInitJson={setInitCertificationPicJson}
            uploadedUrl={uploadedCertificationPicUrl}
            setUploadedUrl={setUploadedCertificationPicUrl}
            readOnly={readOnly}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
          {props.isLast && !props.isAdmin ? (
            <div className="flex justify-between">
              {defaultValue.ID ? (
                <Button
                  appearance="primary"
                  type="button"
                  onClick={() => {
                    setDisabledMode(false);
                    // setForceReadOnly(false);
                    setReadOnly(false);
                  }}
                  loading={loading || loadingUpload}>
                  ویرایش
                </Button>
              ) : null}
              {!props.isFirst ? (
                <Button
                  appearance="ghost"
                  type="button"
                  className="mr-auto"
                  onClick={() => {
                    props.onDelete(defaultValue.ID ?? null);
                  }}
                  loading={loading || loadingUpload}>
                  حذف
                </Button>
              ) : null}
            </div>
          ) : null}
          {props.hasEditMode && !props.isAdmin ? (
            <div>
              {disabledMode ? (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      setDisabledMode(false);
                      // setForceReadOnly(false);
                      setReadOnly(false);
                    }}
                    loading={loading || loadingUpload}>
                    ویرایش
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      props.onDelete(defaultValue.ID ?? null);
                    }}
                    loading={loading || loadingUpload}>
                    حذف
                  </Button>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Button
                    appearance="primary"
                    type="button"
                    onClick={() => {
                      handleSubmit(false, true);
                    }}
                    loading={loading || loadingUpload}>
                    به روزرسانی
                  </Button>
                  <Button
                    appearance="ghost"
                    type="button"
                    onClick={() => {
                      setFormValue(defaultValue);
                      setDisabledMode(true);
                      // setForceReadOnly(true);
                      setReadOnly(true);
                    }}
                    loading={loading || loadingUpload}>
                    لغو
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {!props.isLast ? null : (
        <>
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="ml-auto mt-4"
              type="button"
              onClick={() => handleSubmit(false, false)}
              loading={loading || loadingUpload}>
              {props.hideNextButton ? 'ثبت دوره' : 'افزودن دوره جدید'}
            </Button>
          </div>
          {props.hideNextButton ? null : (
            <div className="flex justify-between">
              <Button
                appearance="default"
                className="ml-auto mt-8"
                type="button"
                onClick={() => {
                  navigate(ROUTES.roles.reqSeeker.educationBackground);
                }}
                loading={loading || loadingUpload}>
                مرحله قبل
              </Button>
              <div>
                {props.isFirst ? (
                  <Button
                    appearance="default"
                    className="mr-auto mt-8 ml-2"
                    type="button"
                    onClick={() => handleSubmit(true, false, true)}
                    loading={loading}>
                    دوره‌ه ای نگذرانده ام
                  </Button>
                ) : null}
                <Button
                  appearance="primary"
                  className="mr-auto mt-8"
                  type="button"
                  onClick={() => handleSubmit(true, false)}
                  loading={loading || loadingUpload}>
                  ثبت و مرحله بعد
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </Form>
  );
};
