/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import { FC, createContext, useState, ReactChild, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
interface UserContext {
  user?: any;
  setUser?: any;
  userTrigger?: any;
  setUserTrigger?: any;
  rePing?: any;
  setRePing?: any;
  rePinging?: any;
  setRePinging?: any;
  logout?: any;
  setToken?: any;
  isUserRole?: any;
  isUserAdmin?: any;
  handleSetSelectedRole?: any;
}

const context = createContext<UserContext>({});

const { Provider } = context;

interface Props {
  children: ReactChild | ReactChild[];
}

export const UserProvider: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>({
    isLoggedIn: false,
    Username: null,
    FirstName: null,
    LastName: null,
    selectedRole: 'Guest',
    Roles: []
  });
  const [userTrigger, setUserTrigger] = useState<boolean>();
  const [rePing, setRePing] = useState<boolean>(false);
  const [rePinging, setRePinging] = useState<boolean>(false);

  const setToken = (token: string, username: string, admin: string) => {
    Cookies.set('token', token);
    Cookies.set('username', username);
    Cookies.set('admin', admin);
    console.log('setting...');
  };
  const logout = () => {
    setUser({
      isLoggedIn: false,
      Username: null,
      selectedRole: null,
      FirstName: null,
      LastName: null,
      Roles: []
    });
    Cookies.remove('selectedRole');
    Cookies.remove('admin');
    Cookies.remove('username');
    Cookies.remove('token');
    console.log('Removed...');
    navigate('/auth/login');
  };
  const isUserRole = (role: string) => {
    if (!user) return false;
    return user.selectedRole === role;
    // return user.Roles.includes(role);
  };
  const isUserAdmin = () => {
    return Cookies.get('admin') == 'yes';
    // return user.Roles.includes(role);
  };

  const handleSetSelectedRole = (role: string) => {
    Cookies.set('selectedRole', role);
    setUser((pre: any) => {
      return { ...pre, selectedRole: role };
    });
  };

  return (
    <Provider
      value={{
        user,
        setUser,
        userTrigger,
        setUserTrigger,
        rePing,
        setRePing,
        rePinging,
        setRePinging,
        logout,
        isUserRole,
        isUserAdmin,
        setToken,
        handleSetSelectedRole
      }}>
      {children}
    </Provider>
  );
};

export const useUser = () => useContext(context);
