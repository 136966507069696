/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Col, FlexboxGrid } from 'rsuite';
import { ROUTES } from '../../router';
import { useState } from 'react';
import { useUser } from '../../contexts/user';

export const ProxyPages = () => {
  const navigate = useNavigate();
  const [pages] = useState<any>({
    'خدمات مشاوره مدیریت کسب‌و‌کار': {
      category: 'خدمات مشاوره',
      title: 'مدیریت کسب‌و‌کار',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/business.svg',
      chartPath: './assets/charts/consult.png'
    },
    'خدمات مشاوره بازاریابی و فروش': {
      category: 'خدمات مشاوره',
      title: 'بازاریابی و فروش',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/selling.svg',
      chartPath: './assets/charts/consult.png'
    },
    'خدمات مشاوره تبلیغات': {
      category: 'خدمات مشاوره',
      title: 'تبلیغات',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/cart.svg',
      chartPath: './assets/charts/consult.png'
    },
    'خدمات مشاوره منابع انسانی': {
      category: 'خدمات مشاوره',
      title: 'منابع انسانی',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/hr.svg',
      chartPath: './assets/charts/consult.png'
    },
    'خدمات مشاوره حسابداری و مالی': {
      category: 'خدمات مشاوره',
      title: 'حسابداری و مالی',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/money.svg',
      chartPath: './assets/charts/consult.png'
    },
    'خدمات مشاوره حقوقی': {
      category: 'خدمات مشاوره',
      title: 'حقوقی',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/law.svg',
      chartPath: './assets/charts/consult.png'
    },
    'خدمات مشاوره تولید': {
      category: 'خدمات مشاوره',
      title: 'تولید',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/product.svg',
      chartPath: './assets/charts/consult.png'
    },
    'خدمات مشاوره برندسازی': {
      category: 'خدمات مشاوره',
      title: 'برندسازی',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/branding.svg',
      chartPath: './assets/charts/consult.png'
    },
    'خدمات مشاوره فناوری اطلاعات': {
      category: 'خدمات مشاوره',
      title: 'فناوری اطلاعات',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/programmer.svg',
      chartPath: './assets/charts/consult.png'
    },
    'خدمات مشاوره سیستم سازی': {
      category: 'خدمات مشاوره',
      title: 'سیستم سازی',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/team.svg',
      chartPath: './assets/charts/consult.png'
    },
    //
    'خدمات کاریابی و استخدام جذب نیروی کار': {
      category: 'خدمات کاریابی و استخدام',
      title: 'جذب نیروی متخصص',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqRecruitment.personal,
      imagePath: './assets/img/seeker.svg',
      chartPath: './assets/charts/recruitment.png'
    },
    'خدمات کاریابی و استخدام جذب نیروی ویژه': {
      category: 'خدمات کاریابی و استخدام',
      title: 'جذب نیروی ویژه',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqRecruitment.personal,
      imagePath: './assets/img/seeker.svg',
      chartPath: './assets/charts/recruitment.png'
    },
    'خدمات کاریابی و استخدام کاریابی': {
      category: 'خدمات کاریابی و استخدام',
      title: 'کاریابی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqRecruitment.personal,
      imagePath: './assets/img/resume.svg',
      chartPath: './assets/charts/recruitment.png'
    },

    //
    'پروژه‌های سازمانی': {
      category: 'پروژه‌های سازمانی',
      title: 'پروژه‌های سازمانی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/research.svg',
      chartPath: './assets/charts/organizationalProjects.png'
    },
    'پروژه‌های سازمانی تحقیقات بازار': {
      category: 'پروژه‌های سازمانی',
      title: 'تحقیقات بازار',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/research.svg',
      chartPath: './assets/charts/organizationalProjects.png'
    },
    'پروژه‌های سازمانی برند سازی': {
      category: 'پروژه‌های سازمانی',
      title: 'برند سازی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/branding.svg',
      chartPath: './assets/charts/organizationalProjects.png'
    },
    'پروژه‌های سازمانی کمپین تبلیغاتی': {
      category: 'پروژه‌های سازمانی',
      title: 'کمپین تبلیغاتی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/ad.svg',
      chartPath: './assets/charts/organizationalProjects.png'
    },
    'پروژه‌های سازمانی ارزیابی عملکرد': {
      category: 'پروژه‌های سازمانی',
      title: 'ارزیابی عملکرد',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/evaluate.svg',
      chartPath: './assets/charts/organizationalProjects.png'
    },
    'پروژه‌های سازمانی طرح کسب‌و‌کار': {
      category: 'پروژه‌های سازمانی',
      title: 'طرح کسب‌و‌کار',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/manager.svg',
      chartPath: './assets/charts/organizationalProjects.png'
    },
    'پروژه‌های سازمانی سایر': {
      category: 'پروژه‌های سازمانی',
      title: 'سایر',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/other.svg',
      chartPath: './assets/charts/organizationalProjects.png'
    },
    //
    'آموزش ها سازمانی': {
      category: 'آموزش های سازمانی',
      title: 'آموزش های سازمانی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/manager.svg',
      chartPath: './assets/charts/organizationalTrainings.png'
    },
    'آموزش ها سازمانی مدیریتی': {
      category: 'آموزش های سازمانی',
      title: 'مدیریتی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/manager.svg',
      chartPath: './assets/charts/organizationalTrainings.png'
    },
    'آموزش ها سازمانی رفتار سازمانی': {
      category: 'آموزش های سازمانی',
      title: 'رفتار سازمانی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/intract.svg',
      chartPath: './assets/charts/organizationalTrainings.png'
    },
    'آموزش ها سازمانی بازاریابی و فروش': {
      category: 'آموزش های سازمانی',
      title: 'بازاریابی و فروش',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/sales.svg',
      chartPath: './assets/charts/organizationalTrainings.png'
    },
    'آموزش ها سازمانی مدیریت استراتژیک': {
      category: 'آموزش های سازمانی',
      title: 'مدیریت استراتژیک',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/research.svg',
      chartPath: './assets/charts/organizationalTrainings.png'
    },
    'آموزش ها سازمانی تبلیغات': {
      category: 'آموزش های سازمانی',
      title: 'تبلیغات',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/ad.svg',
      chartPath: './assets/charts/organizationalTrainings.png'
    },
    'آموزش ها سازمانی مدیریت منابع انسانی': {
      category: 'آموزش های سازمانی',
      title: 'مدیریت منابع انسانی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/hr.svg',
      chartPath: './assets/charts/organizationalTrainings.png'
    },
    'آموزش ها سازمانی ارزیابی عملکرد': {
      category: 'آموزش های سازمانی',
      title: 'ارزیابی عملکرد',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/investigate.svg',
      chartPath: './assets/charts/organizationalTrainings.png'
    },
    'آموزش ها سازمانی فروشنده حرفه‌ای': {
      category: 'آموزش های سازمانی',
      title: 'فروشنده حرفه‌ای',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/seller.svg',
      chartPath: './assets/charts/organizationalTrainings.png'
    },
    //
    'بازاریابی و فروش': {
      category: 'بازاریابی و فروش',
      title: 'بازاریابی و فروش',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqMarketing.personal,
      imagePath: './assets/img/team.svg',
      chartPath: './assets/charts/team.png'
    },
    'بازاریابی و فروش تیم سازی فروش (درون سازمانی)': {
      category: 'بازاریابی و فروش',
      title: 'تیم سازی فروش (درون سازمانی)',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqMarketing.personal,
      imagePath: './assets/img/team.svg',
      chartPath: './assets/charts/team.png'
    },
    'بازاریابی و فروش فروش (برون سپاری)': {
      category: 'بازاریابی و فروش',
      title: 'فروش (برون سپاری)',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqMarketing.personal,
      imagePath: './assets/img/marketing.svg',
      chartPath: './assets/charts/marketing.png'
    },
    //
    'استعلام و ارزیابی': {
      category: 'استعلام و ارزیابی',
      title: 'استعلام و ارزیابی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqInquery.personal,
      imagePath: './assets/img/service.svg',
      chartPath: './assets/charts/inquery.png'
    },
    'استعلام و ارزیابی استعلام و ارائه خدمات': {
      category: 'استعلام و ارزیابی',
      title: 'تامین کالا و خدمات به صورت ویژه',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqInquery.personal,
      imagePath: './assets/img/service.svg',
      chartPath: './assets/charts/inquery.png'
    },
    'استعلام و ارزیابی ارزیابی تامین کننده': {
      category: 'استعلام و ارزیابی',
      title: 'استعلام تامین کننده',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqInquery.personal,
      imagePath: './assets/img/filter.svg',
      chartPath: './assets/charts/inquery.png'
    },
    //
    'سرمایه گذاری': {
      category: 'سرمایه گذاری',
      title: 'سرمایه گذاری',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqFounder.personal,
      imagePath: './assets/img/money-2.svg',
      chartPath: './assets/charts/founder.png'
    },
    'سرمایه گذاری جذب سرمایه گذار': {
      category: 'سرمایه گذاری',
      title: 'جذب سرمایه گذار',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqFounder.personal,
      imagePath: './assets/img/money-2.svg',
      chartPath: './assets/charts/founder.png'
    },
    'سرمایه گذاری سرمایه گذاری': {
      category: 'سرمایه گذاری',
      title: 'سرمایه گذاری',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqFounder.personal,
      imagePath: './assets/img/investing.svg',
      chartPath: './assets/charts/founder.png'
    },
    //
    'ایده تا محصول ایده تا محصول': {
      category: 'ایده تا محصول',
      title: 'ایده تا محصول',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqIdea.personal,
      imagePath: './assets/img/idea.svg',
      chartPath: './assets/charts/idea.png'
    },
    'ایده تا محصول': {
      category: 'ایده تا محصول',
      title: 'ایده تا محصول',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqIdea.personal,
      imagePath: './assets/img/idea.svg',
      chartPath: './assets/charts/idea.png'
    },

    '-': {
      title: '-',
      subtitle: '',
      description: '',
      url: '/',
      imagePath: '-'
    }
  });
  const { service } = useParams();
  const { user } = useUser();
  return (
    <section className="container mx-auto">
      <FlexboxGrid className="!items-center mt-5  min-h-[60vh]">
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          sm={24}
          md={12}
          className="flex flex-column items-center">
          <h1 className="heading-text">{pages[service ?? '-']?.title}</h1>
          <h3 className="heading-text">{pages[service ?? '-']?.subtitle}</h3>
          <p className="sub-heading-text">
            آینده کسب‌وکارتان را با خدمات مشاوره حرفه‌ای در سامانه جامع مشاوره کسب‌و‌کار تضمین کنید.
            با تخصص و تجربه تیم گروه مشاوره دکتر رمضانی، به بهره‌وری بیشتر و هزینه‌های کمتر دست
            یابید و موفقیت را تجربه کنید.
          </p>
          <div className="flex items-center md:justify-start justify-center flex-row">
            <Button
              appearance="primary"
              size="lg"
              className="mt-5 w-fit"
              onClick={() => {
                if (user.isLoggedIn) {
                  navigate(`${pages[service ?? '-']?.url}`);
                } else {
                  navigate(`/auth/login?redirect=${pages[service ?? '-']?.url}`);
                }
              }}>
              شروع کنید
            </Button>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          sm={24}
          md={12}
          className="flex flex-column items-center justify-center">
          <img src={pages[service ?? '-']?.imagePath} alt="" className="w-[80%] mx-auto" />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid className="!items-center mt-5">
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          sm={24}
          md={24}
          className="flex flex-column items-center justify-center bg-white border-[1px] rounded-md border-[--rs-primary-300] p-4 mt-6">
          <img
            src={pages[service ?? '-']?.chartPath}
            alt=""
            className="w-[100%] md:w-[85%] lg:w-[75%] mx-auto"
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </section>
  );
};
