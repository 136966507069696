/* eslint-disable @typescript-eslint/naming-convention */
import { Col, FlexboxGrid, Button } from 'rsuite';
import { useEffect, useState } from 'react';
// import { STATUS_CONVERTOR } from '../../../utils/helpers';

import { handleGetPictures, handlePutUserProfilePictureAdmin } from '../../../api-handler';
// import Logo from '../../../assets/images/brand/rcg2.svg';
import { useModal } from '../../../contexts/modal';
import { base64ToJson } from '../../../utils/helpers';
import { UPLOAD_FILE_URL } from '../../../core';
export const AdminProfiles = () => {
  const [data, setData] = useState([]);
  const [, setLoading] = useState(false);
  const { handleOpen } = useModal();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [overrides, setOverrides] = useState<any>({});
  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetPictures();
      if (res.ok) {
        setData(res.data);
      }
      setLoading(false);
    })();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRejectProfile = (id: any, description: string) => {
    return async (i = id, d = description) => {
      const res = await handlePutUserProfilePictureAdmin(i, 'Rejected', d);
      if (res.ok) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setOverrides((pre: any) => {
          return { ...pre, [i]: 'Rejected' };
        });
      }
    };
  };
  const handleAcceptProfile = async (i: number) => {
    const res = await handlePutUserProfilePictureAdmin(i, 'Accepted', null);
    if (res.ok) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setOverrides((pre: any) => {
        return { ...pre, [i]: 'Accepted' };
      });
    }
  };

  return (
    <>
      <h4 className="text-h4 mb-6">لیست درخواست های پروفایل</h4>
      <FlexboxGrid>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {data.map((item: any) => {
          const rejected = overrides[item.ID]
            ? overrides[item.ID] === 'Rejected'
            : item.Status === 'Rejected';
          const pending = overrides[item.ID]
            ? overrides[item.ID] === 'Pending'
            : item.Status === 'Pending';
          return (
            <FlexboxGrid.Item
              key={item.ID}
              as={Col}
              colspan={24}
              sm={12}
              md={8}
              lg={6}
              xl={4}
              className="max-w-[100%] mb-3">
              <div
                className={`${pending ? 'bg-[--rs-primary-200]' : rejected ? 'bg-[--rs-error-100]' : 'bg-[--rs-success-100]'} rounded-lg`}
                style={{ overflow: 'hidden' }}>
                <div
                  className="w-full bg-[--rs-primary-100] flex justify-center items-center p-4"
                  style={{ aspectRatio: 1, overflow: 'hidden' }}>
                  <img
                    className="w-full"
                    src={`${UPLOAD_FILE_URL}?${base64ToJson(item.PictureLink)[0]?.url}&Attachment=False`}
                    alt=""
                  />
                </div>
                <div className="flex justify-between items-center p-3">
                  <Button
                    appearance="primary"
                    className="w-[40%]"
                    disabled={!pending && !rejected}
                    onClick={() => handleAcceptProfile(item.ID)}>
                    تایید
                  </Button>
                  <Button
                    appearance="ghost"
                    className="w-[40%]"
                    disabled={!pending && rejected}
                    onClick={() =>
                      handleOpen(
                        handleRejectProfile,
                        item.ID,
                        `علت رد پروفایل`,
                        `رد پروفایل`,
                        'رد پروفایل',
                        'بستن',
                        false,
                        true
                      )
                    }>
                    رد
                  </Button>
                </div>
              </div>
            </FlexboxGrid.Item>
          );
        })}
      </FlexboxGrid>
    </>
  );
};
