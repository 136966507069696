/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from 'rsuite';
import { SEEKER_CATGORY1_CONVERTOR, SEEKER_CATGORY2_CONVERTOR } from '../../../../utils/helpers';

export const SeekerDesiredFieldsView = ({ data }: any) => {
  return (
    <>
      {/* <h4 className="mb-4">زمینه های مورد علاقه</h4> */}
      <div className="w-full">
        <Divider className="text-[20px] font-bold">زمینه های موردعلاقه</Divider>
      </div>
      <div className="flex flex-wrap">
        {data?.map((desired: any) => {
          return (
            <div
              key={desired.id}
              className="bg-[--rs-primary-100] text-[--rs-primary-600] rounded-md font-bold py-2 px-4 text-center  mx-1 mb-3">
              {SEEKER_CATGORY1_CONVERTOR(desired.LayerOne)}
              {' - '}
              {SEEKER_CATGORY2_CONVERTOR(desired.LayerOne, desired.Layertwo)}
            </div>
          );
        })}
      </div>
    </>
  );
};
