// import { Button } from 'rsuite';
// import { useState } from 'react';
import { Button, Divider, Input, Radio, RadioGroup } from 'rsuite';
import { Spacer } from '../../../components/basic/spacer';
import { SeekerEducationBackground } from '../../seekers/seeker-education-background';
import { SeekerCourseBackground } from '../../seekers/seeker-course-background';
import { SeekerPersonal } from '../../seekers/seeker-personal';
import { SeekerWorkBackground } from '../../seekers/seeker-work-background';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  handleAcceptSeeker,
  handleGetSeekerRequest,
  handleTerminateSeeker
} from '../../../api-handler';
import { ValueType } from 'rsuite/esm/Checkbox';
import { useNavigate } from 'react-router-dom';
import { STATUS_CONVERTOR } from '../../../utils/helpers';
import { showToast } from '../../../utils/toast';
import { SeekerDesire } from '../../seekers/seeker-desiere';
import { useData } from '../../../contexts/data';
import { SeekerSkillBackground } from './../../seekers/seeker-skill-background';
import { UserBasicDataView } from '../users/basic-data';
import { SeekerSocial } from './../../seekers/seeker-social';

export const AdminRegisterSeekerSingle = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { seekerDesired, seekerPersonal } = useData();
  const { username } = useParams();
  const [accept, setAccept] = useState<ValueType>('Pending');
  const [wasAccept, setWasAccept] = useState<ValueType>('Pending');
  const [whyReject, setWhyReject] = useState('');

  const onAcceptSeeker = useCallback(async () => {
    if (username) {
      setLoading(true);
      if (accept === 'Terminated') {
        const res = await handleTerminateSeeker(username, whyReject);
        if (res.ok) {
          showToast(
            `وضعیت با موفقیت به "${STATUS_CONVERTOR[accept]?.default}" تغییر کرد`,
            'success'
          );
          navigate('/admin/register-seeker-list');
        }
        setLoading(false);
        return;
      }

      // todo update desired fields status
      const res = await handleAcceptSeeker(
        accept,
        username,
        whyReject,
        wasAccept === 'Accepted',
        seekerDesired,
        seekerPersonal
      );
      if (res.ok) {
        showToast(`وضعیت با موفقیت به "${STATUS_CONVERTOR[accept]?.default}" تغییر کرد`, 'success');
        navigate('/admin/register-seeker-list');
      }
      setLoading(false);
    }
  }, [username, accept, whyReject, wasAccept, seekerDesired, seekerPersonal]);

  useEffect(() => {
    (async () => {
      const res = await handleGetSeekerRequest(username ?? '');
      if (res.ok) {
        setAccept(res.data.Status);
        setWasAccept(res.data.Status);
        setWhyReject(res.data.Description);
      }
    })();
  }, [username]);

  return (
    <>
      <h4 className="text-h4"></h4>
      {username ? (
        <>
          <UserBasicDataView username={username ?? '-1'} />

          <SeekerPersonal disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <SeekerWorkBackground disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <SeekerEducationBackground disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <SeekerCourseBackground disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <SeekerSkillBackground disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <SeekerDesire disabledMode={true} isAdmin={true} username={username} />
          <Spacer s={4} />
          <SeekerSocial disabledMode={true} isAdmin={true} username={username} />

          <Spacer s={2} />
          <Divider />
          <h4 className="text-h4">وضعیت مورد نظر را انتخاب کنید</h4>
          <RadioGroup
            className="flex mb-4"
            value={accept}
            onChange={(value: ValueType, _event: SyntheticEvent<Element, Event>) =>
              setAccept(value)
            }>
            <div className="flex">
              <Radio className="" value={'Accepted'} dir="rtl">
                تایید
              </Radio>
              <Radio className="" value={'Pending'}>
                در انتظار تایید
              </Radio>
              <Radio className="" value={'Rejected'}>
                رفع نقص
              </Radio>
              <Radio className="" value={'Suspended'}>
                تعلیق
              </Radio>
              <Radio className="" value={'Terminated'}>
                مختومه (پاک کردن تمام اطلاعات)
              </Radio>
            </div>
          </RadioGroup>
          {accept === 'Accepted' ? (
            <>
              {/* <AdminRegisterSeeker username={username} onAcceptSeeker={onAcceptSeeker} /> */}
              <Button
                appearance="primary"
                className="mr-auto mt-8 w-[120px]"
                type="button"
                onClick={() => onAcceptSeeker()}
                loading={loading}>
                تایید کارجو
              </Button>
            </>
          ) : accept === 'Rejected' || accept === 'Terminated' || accept === 'Suspended' ? (
            <>
              <span>علت</span>
              <Input as="textarea" value={whyReject} onChange={setWhyReject} rows={4} />
              <Button
                appearance="primary"
                className="mr-auto mt-8 w-[120px]"
                type="button"
                onClick={() => onAcceptSeeker()}
                loading={loading}>
                {accept === 'Terminated' ? 'رد' : 'تعلیق'} کارجو
              </Button>
            </>
          ) : (
            <Button
              appearance="primary"
              className="mr-auto mt-8 w-[120px]"
              type="button"
              onClick={() => onAcceptSeeker()}
              loading={loading}>
              ثبت در انتظار
            </Button>
          )}
        </>
      ) : null}
    </>
  );
};
