import './bs.css';
import './styles.css';
import samanhehiLogo from '../../assets/images/samandehi.png';
export const Footer = () => {
  return (
    <>
      <section className="bootstrapp container-fluid mt-5 bg-[--rs-primary-500] px-6 lg:px-10 py-10 pb-0">
        <div className="row text-[#fff]">
          <div className="col-12 col-lg-4 mx-auto pe-unset lg:pr-10">
            <div>
              <h5>درباره ما</h5>
              <p className="text-justify mt-2">
                شرکت پژواک جاوید مدیریت و نوآوری&nbsp;در سال 1395 فعالیت خود را به طور رسمی آغاز
                نمود. یکی از زیر مجموعه های اصلی این شرکت،&nbsp;گروه مشاوره دکتر رمضانی&nbsp;است. در
                تلاشیم تا با گرد هم آوردن تیمی مجرب و متخصص در زمینه مشاوره و آموزش مدیریت، گامی
                مفید در راستای کمک به مدیران و کسب و کارها در ایران برداریم.
              </p>
            </div>
            <div className="mb-4 mt-4">
              <span className="font-bold mt-2"> شماره های تماس: </span>{' '}
              <a href="tel:07136350136" className="text-[#fff] hover:text-[#fff]">
                07136350136
              </a>{' '}
              و{' '}
              <a href="tel:09032538616" className="text-[#fff] hover:text-[#fff]">
                09032538616
              </a>
            </div>
            <div className="mb-4">
              <span className="font-weight: bold">ایمیل:</span>{' '}
              <a href="mailto:info@cbcs.ir" className="text-[#fff] hover:text-[#fff]">
                info@cbcs.ir
              </a>
            </div>
            <div className="mb-4 flex gap-1">
              <a href="https://www.linkedin.com/company/%D8%B3%D8%A7%D9%85%D8%A7%D9%86%D9%87-%D8%AC%D8%A7%D9%85%D8%B9-%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87-%DA%A9%D8%B3%D8%A8-%D9%88-%DA%A9%D8%A7%D8%B1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    fill="#a5647c"
                    d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                  />
                </svg>
              </a>
              <a href="https://www.aparat.com/Ramezani.Consulting.Group">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_726_2594)">
                    <path
                      d="M1.59668 11.9952C1.61156 6.23318 6.26775 1.56707 12.0644 1.59682C17.7917 1.62657 22.433 6.27285 22.4033 12.0497C22.3785 17.7819 17.7273 22.4282 11.9454 22.3985C6.22313 22.3687 1.61156 17.7373 1.59668 11.9952ZM9.03966 4.37368C7.41817 4.38856 6.11899 5.64806 6.06445 7.25963C6.0099 8.88112 7.31404 10.2695 8.9504 10.3092C10.5669 10.3489 11.9653 9.09434 12 7.3836C12.0297 5.84145 10.8198 4.41335 9.03966 4.37368ZM14.9603 19.6167C16.5669 19.6067 17.881 18.3572 17.9405 16.7307C18 15.1142 16.7008 13.7307 15.0595 13.6712C13.4628 13.6167 12.0793 14.896 12.0049 16.5026C11.9355 18.0993 13.1554 19.577 14.9603 19.6167ZM4.54709 15.2084C4.54213 16.8348 5.87106 18.1687 7.50742 18.1737C9.11899 18.1836 10.4727 16.8547 10.4826 15.258C10.4925 13.5968 9.18346 12.253 7.55205 12.2431C5.89089 12.2282 4.55701 13.5472 4.54709 15.2084ZM19.4529 8.77699C19.438 7.12575 18.0992 5.80178 16.4628 5.81666C14.8116 5.83153 13.4925 7.17037 13.5223 8.84641C13.5471 10.4629 14.876 11.7671 16.5273 11.7522C18.1388 11.7373 19.4678 10.3886 19.4529 8.77699ZM13.2644 12.0695C13.2644 11.3505 12.6694 10.7505 11.9504 10.7505C11.2264 10.7505 10.6314 11.3406 10.6264 12.0596C10.6215 12.7836 11.2165 13.3786 11.9454 13.3886C12.6595 13.3935 13.2644 12.7886 13.2644 12.0695Z"
                      fill="#A5647C"
                    />
                    <path
                      d="M23.2116 10.5471C22.7653 7.44294 21.3125 4.92889 18.8579 2.97021C19.6563 3.18344 20.4348 3.37683 21.2034 3.59501C22.7009 4.02145 23.6133 5.00823 23.9356 6.52559C24.0645 7.13055 23.9951 7.7355 23.8265 8.3355C23.6331 9.02972 23.4546 9.73385 23.2662 10.433C23.2563 10.4677 23.2513 10.5074 23.2116 10.5471Z"
                      fill="#A5647C"
                    />
                    <path
                      d="M13.6113 23.2713C15.0841 23.0283 16.4626 22.5374 17.7419 21.7738C19.0212 21.0101 20.1122 20.0283 21.0295 18.8481C20.9452 19.1605 20.8609 19.4779 20.7766 19.7903C20.6179 20.3705 20.5088 20.9705 20.2956 21.5357C19.7849 22.8746 18.813 23.6779 17.3998 23.9407C16.7898 24.0548 16.1898 23.9705 15.5948 23.8068C14.9502 23.6283 14.3006 23.4597 13.651 23.2862C13.6411 23.2812 13.6262 23.2762 13.6113 23.2713Z"
                      fill="#A5647C"
                    />
                    <path
                      d="M3.0498 4.81474C3.18369 4.32383 3.31261 3.83293 3.4465 3.34202C3.57047 2.88582 3.66468 2.42466 3.89774 2.00317C4.48782 0.922181 5.3903 0.267636 6.60518 0.0544129C7.19526 -0.0497194 7.77047 0.0147434 8.34567 0.17838C8.98038 0.356892 9.62005 0.520529 10.2597 0.689124C10.2895 0.699041 10.3192 0.699041 10.349 0.728793C9.80352 0.808132 9.26799 0.917223 8.74237 1.07094C6.95228 1.59656 5.38534 2.504 4.04154 3.79326C3.71923 4.09574 3.41675 4.42301 3.1341 4.7602C3.11427 4.78499 3.10931 4.83954 3.0498 4.81474Z"
                      fill="#A5647C"
                    />
                    <path
                      d="M4.89895 20.9605C4.17994 20.7721 3.48573 20.5986 2.80143 20.4002C1.30887 19.9738 0.391513 18.987 0.0691985 17.4696C-0.0597272 16.8647 0.00473565 16.2647 0.173331 15.6696C0.336967 15.0845 0.490686 14.4944 0.644405 13.9093C0.654322 13.8796 0.66424 13.8548 0.679116 13.8052C1.18986 16.7258 2.59812 19.0911 4.89895 20.9605Z"
                      fill="#A5647C"
                    />
                    <path
                      d="M3.05445 4.81006C3.05445 4.81502 3.05941 4.81502 3.05941 4.81998V4.81502C3.05445 4.81502 3.05445 4.81502 3.05445 4.81006Z"
                      fill="#A5647C"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_726_2594">
                      <rect width="24" height="23.9901" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a href="mailto:info@cbcs.ir">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#a5647c">
                  <g id="style=bulk">
                    <g id="email">
                      <path
                        id="vector (Stroke)"
                        fillRule="evenodd"
                        fill="#a5647c"
                        clipRule="evenodd"
                        d="M2.86466 4.1379C3.92465 3.15363 5.38503 2.75 7 2.75H17C18.615 2.75 20.0754 3.15363 21.1353 4.1379C22.2054 5.13152 22.75 6.60705 22.75 8.5V15.5C22.75 17.393 22.2054 18.8685 21.1353 19.8621C20.0754 20.8464 18.615 21.25 17 21.25H7C5.38503 21.25 3.92465 20.8464 2.86466 19.8621C1.79462 18.8685 1.25 17.393 1.25 15.5V8.5C1.25 6.60705 1.79462 5.13152 2.86466 4.1379Z"
                      />
                      <path
                        id="vector (Stroke)_2"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3633 7.31026C19.6166 7.63802 19.5562 8.10904 19.2285 8.3623L13.6814 12.6486C12.691 13.4138 11.3089 13.4138 10.3185 12.6486L4.77144 8.3623C4.44367 8.10904 4.38328 7.63802 4.63655 7.31026C4.88982 6.98249 5.36083 6.9221 5.6886 7.17537L11.2356 11.4616C11.6858 11.8095 12.3141 11.8095 12.7642 11.4616L18.3113 7.17537C18.6391 6.9221 19.1101 6.98249 19.3633 7.31026Z"
                        fill="#7f2144"
                      />
                    </g>
                  </g>
                </svg>
              </a>
              <a href="tel:07136350136">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
                    fill="#a5647c"
                  />
                </svg>
              </a>
              <a href="https://instagram.com/Framezani.consulting.group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#a5647c"
                  viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
            </div>
            <div className="mb-4">
              <span className="font-weight: bold">آدرس:</span> شیراز، بلوار دکتر حسابی (صنایع)، شهرک
              آرین، پارک علم و فناوری فارس، ساختمان مرکز رشد جامع، طبقه دوم، واحد 6208
            </div>
          </div>
          <div className="col-12 col-lg-2 mx-auto pr-unset lg:pr-4 footer-pages mt-8 lg:mt-0">
            <h5>صفحات اصلی</h5>
            <ul className="mt-2" style={{ lineHeight: '200%' }}>
              <li>
                <a className="nav-link" href="https://cbcs.ir/">
                  خانه
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://cbcs.ir/about-us.html">
                  درباره ما
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://cbcs.ir/contact-us.html">
                  ارتباط با ما
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/consultants">
                  لیست مشاوران
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/corporations">
                  لیست کسب‌و‌کارها
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://cbcs.ir/privecy.html">
                  حریم خصوصی
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://cbcs.ir/tnc.html">
                  قوانین و مقررات
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-2 mx-auto footer-pages mt-8 lg:mt-0">
            <h5>خدمات کسب‌و‌کار</h5>
            <ul className=" mt-2 list-style-none" style={{ lineHeight: '200%' }}>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/services/خدمات کاریابی">
                  خدمات کاریابی
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/services/پروژه‌های سازمانی">
                  پروژه‌های سازمانی
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/services/آموزش ها سازمانی">
                  آموزش‌های سازمانی
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/services/بازاریابی و فروش">
                  بازاریابی و فروش
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/services/استعلام و ارزیابی">
                  استعلام، ارزیابی و تامین
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/services/سرمایه گذاری">
                  سرمایه گذاری
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/services/ایده تا محصول">
                  ایده تا محصول
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-2 mx-auto footer-pages mt-8 lg:mt-0">
            <h5>خدمات مشاوره</h5>
            <ul className="mt-2" style={{ lineHeight: '200%' }}>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/services/خدمات مشاوره برندسازی">
                  برند سازی
                </a>
              </li>
              <li>
                <a className="nav-link" href="https://panel.cbcs.ir/services/خدمات مشاوره حقوقی">
                  حقوقی
                </a>
              </li>
              <li>
                <a
                  className="nav-link"
                  href="https://panel.cbcs.ir/services/خدمات مشاوره فناوری اطلاعات">
                  فناوری اطلاعات
                </a>
              </li>
              <li>
                <a
                  className="nav-link"
                  href="https://panel.cbcs.ir/services/خدمات مشاوره سیستم سازی">
                  سیستم سازی
                </a>
              </li>
              <li>
                <a
                  className="nav-link"
                  href="https://panel.cbcs.ir/services/خدمات مشاوره منابع انسانی">
                  منابع انسانی
                </a>
              </li>

              <li>
                <a
                  className="nav-link"
                  href="https://panel.cbcs.ir/services/خدمات مشاوره بازاریابی و فروش">
                  بازاریابی و فروش
                </a>
              </li>
              <li>
                <a
                  className="nav-link"
                  href="https://panel.cbcs.ir/services/خدمات مشاوره حسابداری و مالی">
                  حسابداری و مالی
                </a>
              </li>
            </ul>
          </div>
          <div
            className="col-12 col-lg-2 mx-auto d-flex flex-column p-0"
            style={{ maxWidth: '360px' }}>
            <div className="d-flex flex-row" style={{ width: '100%' }}>
              <div style={{ width: '46%', display: 'flex', alignItems: 'center' }}>
                <a
                  referrerPolicy="origin"
                  target="_blank"
                  href="https://trustseal.enamad.ir/?id=488145&amp;Code=3RW5XQeDduhA7kSf1gl2gbxUOOKNvjZJ"
                  rel="noreferrer">
                  <img
                    referrerPolicy="origin"
                    src="https://trustseal.enamad.ir/logo.aspx?id=488145&amp;Code=3RW5XQeDduhA7kSf1gl2gbxUOOKNvjZJ"
                    alt=""
                    style={{ cursor: 'pointer' }}
                    // code="3RW5XQeDduhA7kSf1gl2gbxUOOKNvjZJ"
                  />
                </a>
              </div>
              <div style={{ width: '6%' }}></div>
              <div style={{ width: '46%', display: 'flex', alignItems: 'center' }}>
                <img
                  style={{ width: '100%' }}
                  referrerPolicy="origin"
                  id="rgvjfukzoeukwlaojzpenbqe"
                  onClick={() =>
                    window.open(
                      'https://logo.samandehi.ir/Verify.aspx?id=368472&amp;p=xlaogvkamcsiaodsjyoeuiwk',
                      'Popup',
                      'toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=465, height=693, top=30'
                    )
                  }
                  alt="logo-samandehi"
                  src={samanhehiLogo}
                />
              </div>
            </div>
            <div className="d-flex flex-row" style={{ width: '100%' }}>
              <div style={{ width: '46%', display: 'flex', alignItems: 'center' }}>
                <img
                  style={{ cursor: 'pointer', width: '100%' }}
                  onClick={() => window.open('https://www.fstp.ir/')}
                  src="https://cbcs.ir/assets/img/certificates/fstp.png"
                />
              </div>
              <div style={{ width: '6%' }}></div>
              <div style={{ width: '46%', display: 'flex', alignItems: 'center' }}>
                <img
                  style={{ cursor: 'pointer', width: '100%' }}
                  onClick={() => window.open('https://cbcs.ir/about-us.html#certificates')}
                  src="https://cbcs.ir/assets/img/certificates/logo-jobhori.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 text-center py-4 text-light pt-6"
          style={{ fontSize: '14px', fontWeight: 'bold' }}>
          تمامی حقوق مادی و معنوی این وبسایت متعلق به شرکت پژواک جاوید مدیریت و نوآوری (سهامی خاص)
          می باشد.
        </div>
      </section>
      <div
        className="col-12 text-center py-1 text-[--rs-primary-900] pt-2"
        style={{ fontSize: '14px', fontWeight: 'bold', backgroundColor: '#d9bcc7' }}>
        طراحی و اجرا توسط گروه تحقیق و توسعه اطلس
      </div>
    </>
  );
};
