import { Button, Loader, Tooltip, Whisper } from 'rsuite';
import { useEffect, useState } from 'react';
// import { FlexboxGrid } from 'rsuite';
import {
  durationToHour,
  FormatDateTime,
  SESSION_STATUS_CONVERTOR,
  sortData
} from '../../../utils/helpers';

import { Table, Cell, Column, HeaderCell } from 'rsuite-table';
import { handleGetConsults } from '../../../api-handler/admin';
import { faIR } from 'date-fns-jalali/locale';
import { useNavigate } from 'react-router-dom';

export const AdminConsultsList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  // const [loading] = useState(false);
  const [sortColumn, setSortColumn] = useState('ID');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sortType, setSortType] = useState<any>('asc');
  const [tableLoading, setTableLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const [defaultData, setDefaultData] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setTableLoading(true);
    setTimeout(() => {
      setTableLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };
  useEffect(() => {
    (async () => {
      setTableLoading(true);
      const res = await handleGetConsults();
      if (res.ok) {
        setData(res.data);
      }
      setTableLoading(false);
    })();
  }, []);

  // const [search, setSearch] = useState("");

  // useEffect(() => {
  //   setData(defaultData.filter(d=>(d.Book_Name.toLowerCase().search(search.toLowerCase())>=0)))
  // }, [search]);

  return (
    <>
      <h4 className="text-h4 mb-6">لیست درخواست های مشاوره</h4>

      <Table
        data={sortData(data, sortColumn, sortType)}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={tableLoading}
        // affixHeader
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        shouldUpdateScroll={(_evnt: any) => {
          return { y: 0, x: 1000 };
        }}
        renderLoading={() => (
          <div className="flex items-center justify-center h-[100%]">
            <Loader size="md" />
          </div>
        )}
        renderEmpty={() => (
          <div className="flex items-center justify-center h-[100%]">مشاوره ای موجود نیست</div>
        )}
        autoHeight
        affixHorizontalScrollbar>
        <Column width={100} verticalAlign="middle" align="center">
          <HeaderCell>شماره</HeaderCell>
          <Cell dataKey="ID" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>موضوع</HeaderCell>
          <Cell dataKey="Topic">
            {(rowData) => {
              return (
                <>
                  <Whisper
                    placement="top"
                    controlId={`topic-hover-${rowData.ID}`}
                    trigger="hover"
                    speaker={<Tooltip>{rowData.Topic}</Tooltip>}>
                    <Button size="xs" className="!border-[0px] !hover:border-[0px]">
                      {rowData.Topic}
                    </Button>
                  </Whisper>
                </>
              );
            }}
          </Cell>
        </Column>
        <Column minWidth={100} flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نوع مشاوره</HeaderCell>
          <Cell dataKey="Type" />
        </Column>
        <Column minWidth={150} flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>مشاوره دهنده</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return (
                <Button
                  appearance="primary"
                  size="xs"
                  onClick={() => {
                    navigate(`/admin/profile/${rowData.ConsultantName?.Username}`);
                  }}>
                  {rowData.ConsultantName?.FirstName} {rowData.ConsultantName?.LastName}
                </Button>
              );
            }}
          </Cell>
        </Column>
        <Column minWidth={150} flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>مشاوره گیرنده</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return (
                <Button
                  appearance="primary"
                  size="xs"
                  onClick={() => {
                    navigate(`/admin/profile/${rowData.UsernameName?.Username}`);
                  }}>
                  {rowData.UsernameName?.FirstName} {rowData.UsernameName?.LastName}
                </Button>
              );
            }}
          </Cell>
        </Column>
        <Column minWidth={160} flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>وضعیت</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {SESSION_STATUS_CONVERTOR[rowData.Status]}</>;
            }}
          </Cell>
        </Column>
        <Column minWidth={200} flexGrow={1} verticalAlign="middle" align="center font-fd">
          <HeaderCell>زمان جلسه</HeaderCell>
          <Cell dataKey="">
            {(rowData) => (
              <>
                {!rowData.SessionTimeStamp
                  ? 'تنظیم نشده'
                  : FormatDateTime(rowData.SessionTimeStamp, faIR)}
              </>
            )}
          </Cell>
        </Column>
        <Column minWidth={200} flexGrow={1} verticalAlign="middle" align="center font-fd">
          <HeaderCell>زمان پرداخت</HeaderCell>
          <Cell dataKey="">
            {(rowData) => (
              <>
                {!rowData.BoughtTimeStamp
                  ? 'پرداخت نشده'
                  : FormatDateTime(rowData.BoughtTimeStamp, faIR)}
              </>
            )}
          </Cell>
        </Column>
        <Column minWidth={200} flexGrow={1} verticalAlign="middle" align="center font-fd">
          <HeaderCell>زمان ثبت زمان های خالی</HeaderCell>
          <Cell dataKey="">
            {(rowData) => (
              <>
                {!rowData.TimeTableTimeStamp
                  ? 'ثبت نشده'
                  : FormatDateTime(rowData.TimeTableTimeStamp, faIR)}
              </>
            )}
          </Cell>
        </Column>
        <Column minWidth={100} flexGrow={1} verticalAlign="middle" align="center ">
          <HeaderCell>مدت زمان</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return durationToHour(rowData.Duration);
            }}
          </Cell>
        </Column>
        <Column minWidth={120} flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>دسته بندی</HeaderCell>
          <Cell dataKey="Category" />
        </Column>
        <Column minWidth={120} flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>مکان</HeaderCell>
          <Cell dataKey="Location" />
        </Column>
        {/* <Column width={90} verticalAlign="middle" align="center">
          <HeaderCell>مشاهده</HeaderCell>
          <Cell dataKey="">
            {(rowData) => (
              <Button
                appearance="primary"
                size="xs"
                type="button"
                onClick={() => navigate(`/admin/consult-sessions/${rowData.ID}`)}>
                مشاهده
              </Button>
            )}
          </Cell>
        </Column> */}
      </Table>
    </>
  );
};
