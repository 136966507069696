import { Button, Checkbox } from 'rsuite';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router';

export const CorporationFirst = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  return (
    <>
      <h4 className="text-h4">تعهدات زیر را با دقت بخوانید</h4>

      <div className="flex flex-col mt-4">
        <div className="max-h-[50vh] overflow-y-auto p-4 rounded-md bg-[--rs-primary-50] border-[1px] border-[--rs-primary-500] text-justify pretty-scroll mb-3">
          - کارفرما مکلف است که اطلاعات دقیق، صحیح و درست از شرکت خود را در سامانه ثبت نماید.
          مسئولیت هرگونه اطلاعات غلط و یا ادعای ناصحیح بر عهده کارفرما است. هرچند که سامانه جامع
          مشاوره، تمام تلاش خود را جهت صحت سنجی اطلاعات به کار می گیرد اما مسئولیت کلیه اطلاعات ثبت
          شده در پروفایل کسب و کاربر عهده کارفرما می باشد.
          <br />
          <br />
          - سامانه جامع مشاوره، صرفا از جنبه تبلیغاتی و معرفی شرکت ها اقدام می نماید و مسئولیتی در
          قبال توافق ها و قراردادهای بین شرکت ها در خصوص طرح ها و پروژه های ایشان ندارد.
          <br />
          <br />- پس از دریافت رزومه کارجوی مورد نظر توسط کسب و کار، در صورتی که تا یک هفته نتیجه
          مصاحبه توسط کارفرما اعلام نشود، پشتیبانی سامانه cbcs.ir بعد از پیگیری یک مهلت سه روزه به
          کارفرما میدهد. بعد از اتمام مهلت سه روزه، رزومه به صورت اتوماتیک از صفحه کارفرما خارج شده
          و در بخش نمایش عمومی مجددا قرار میگیرد.
          <br />
          <br />
          - کارفرما مکلف است کسب و کار خود را با شفافیت معرفی نماید.
          <br />
          <br />
          - اگر کارجوی معرفی شده به کارفرما با اطلاعات فرد دیگری ثبت نام کرده و اقدام به ایجاد رزومه
          کرده باشد، سامانه cbcs.ir مسئولیتی در این زمینه نخواهد داشت و تنها در صورت احراز هویت فرد،
          اقدام به حذف رزومه خواهد کرد.
          <br />
          <br />
          - مسئولیت تماس و هماهنگی با متقاضیان جهت جلسه مصاحبه و استخدام به عهده کارفرما می‌باشد.
          <br />
          <br />
          - با توجه به کسب اجازه از کارجو توسط سامانه جامع با کارجو در خصوص ارایه رزومه به کارفرما و
          علاقه و نیاز کارجو جهت مصاحبه و استخدام، در صورتی که فرد صاحب رزومه علاقمند به شرکت در
          مصاحبه آن شرکت نباشد، سامانه cbcs.ir در این خصوص مسئولیتی نخواهد داشت.
          <br />
          <br />
          - کارفرما می‌بایست تمام اطلاعات دریافتی خود را از کارجو صرفاً جهت موارد استخدامی استفاده
          نماید و از فاش نمودن اطلاعات خودداری نماید، در غیر این صورت مورد پیگرد قانونی قرار خواهد
          گرفت. همچنین مسئولیت سوء استفاده پرسنل شرکت از اطلاعات رزومه‌ها، کاملاً به عهده شخص
          کارفرما می‌باشد.
          <br />
          <br />
          - سامانه cbcs.ir تمامی تلاش خود را به کار می‌بندد تا بهترین و مناسب‌ترین نیروی کار را در
          اختیار کارفرمایان گرامی قرار دهد. اما بدیهی است که سامانه cbcs.ir قادر نخواهد بود ضمانتی
          در مورد یافتن نیروی کار برای کارفرما ارائه نماید.
          <br />
          <br />- پس از پرداخت هزینه و خرید رزومه، توافقنامه تحت هیچ شرایطی قابل فسخ نبوده و مبلغ
          پرداختی به هیچ عنوان مسترد نمی گردد.
        </div>
        <Checkbox
          checked={isChecked}
          dir="rtl"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(_value: any, checked: boolean, _event: any) => setIsChecked(checked)}>
          تمام موارد را خواندم و با تمامی موارد موافق هستم.
        </Checkbox>
        <div className="flex justify-between">
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="button"
            onClick={() => navigate(ROUTES.roles.reqCorporation.personal)}
            disabled={!isChecked}>
            مرحله بعد
          </Button>
        </div>
      </div>
    </>
  );
};
