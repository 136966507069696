/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteCorporationGood,
  deleteCorporationService,
  getCorporationBuycv,
  getCorporationGood,
  getCorporationIntro,
  getCorporationPersonal,
  getCorporationRegisterRequest,
  getCorporationService,
  getCorporations,
  postCorporationBuycv,
  postCorporationGood,
  postCorporationIntro,
  postCorporationPersonal,
  postCorporationRegisterRequest,
  postCorporationService,
  putCorporationRegisterRequest,
  getCorporationsWithProduct
} from '../core/corporation';
import {
  PostCorporationGoodModel,
  PostCorporationIntroModel,
  PostCorporationPersonalModel,
  PostCorporationServiceModel
} from '../models';
import { showToast } from '../utils/toast';
import { handleUpdateAny } from './global';

export const handlePostCorporationPersonal = async (payload: PostCorporationPersonalModel) => {
  try {
    const { data, status } = await postCorporationPersonal(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetCorporationPersonal = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getCorporationPersonal(username);
    // return { ok: true, payload };
    if (status === 200) {
      return {
        data: {
          ...data.Data,
          CellPhone: `0${data.Data.CellPhone.substring(3)}`
        },
        ok: true
      };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutCorporationPersonal = (
  payload: PostCorporationPersonalModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'CorporationP');
};

export const handlePostCorporationIntro = async (payload: PostCorporationIntroModel) => {
  try {
    const { data, status } = await postCorporationIntro(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetCorporationIntro = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getCorporationIntro(username);
    // return { ok: true, payload };
    if (status === 200) {
      return {
        data: data.Data,
        ok: true
      };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutCorporationIntro = (
  payload: PostCorporationIntroModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'CorporationI');
};

// good
export const handlePostCorporationGood = async (payload: PostCorporationGoodModel) => {
  try {
    const { data, status } = await postCorporationGood(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetCorporationGood = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getCorporationGood(username);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutCorporationGood = async (
  payload: PostCorporationGoodModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'CorporationG');
};
export const handleDeleteCorporationGood = async (id: number | string) => {
  try {
    const { data, status } = await deleteCorporationGood(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// service
export const handlePostCorporationService = async (payload: PostCorporationServiceModel) => {
  try {
    const { data, status } = await postCorporationService(payload);
    if (status === 200) {
      return { data: data.Data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetCorporationService = async (username: string | null | undefined) => {
  try {
    const { data, status } = await getCorporationService(username);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePutCorporationService = async (
  payload: PostCorporationServiceModel,
  selector: string,
  selectorVal: string | number
) => {
  return handleUpdateAny(payload, selector, selectorVal, 'CorporationS');
};
export const handleDeleteCorporationService = async (id: number | string) => {
  try {
    const { data, status } = await deleteCorporationService(id);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: {}, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// request
export const handlePostCorporationRegisterRequest = async () => {
  try {
    const { data, status } = await postCorporationRegisterRequest();
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutCorporationRegisterRequest = async (payload: { Status: string }) => {
  try {
    const { data, status } = await putCorporationRegisterRequest(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetCorporationRegisterRequest = async () => {
  try {
    const { data, status } = await getCorporationRegisterRequest();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetCorporations = async () => {
  try {
    const { data, status } = await getCorporations();
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetCorporationsWithProduct = async (search: string) => {
  try {
    const { data, status } = await getCorporationsWithProduct(search);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePostCorporationBuycv = async (seekerUsername: string) => {
  try {
    const { data, status } = await postCorporationBuycv(seekerUsername);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetCorporationBuycv = async (jobSeekerUsername: string) => {
  try {
    const { data, status } = await getCorporationBuycv(jobSeekerUsername);
    // return { ok: true, payload };
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: [], ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
