import { Button, Form, Input, Schema } from 'rsuite';
import { useCallback, useEffect, useRef, useState } from 'react';
// import { Link } from 'react-router-dom';
import { handleChangePassword, handleReqChangePassword } from '../../../../api-handler';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Show, Hide } from 'react-iconly';
import IconTextField from '../../../../components/form/icon-textfield';
import TextField from '../../../../components/form/textfield';

import { Card } from '../../../../components/basic/card';
import Logo from '../../../../assets/images/brand/rcg.png';
import { showToast } from '../../../../utils/toast';
import { ReactComponent as BackIcon } from '../../../../assets/icons/left.svg';

const { StringType } = Schema.Types;
const model = Schema.Model({
  Token: StringType('').isRequired('الزامی است!'),
  Password: StringType('').isRequired('الزامی است!').minLength(6, 'رمزعبور باید حداقل ۶ رقم باشد!'),
  Password2: StringType('').isRequired('الزامی است!').minLength(6, 'رمزعبور باید حداقل ۶ رقم باشد!')
});
const DEFAULT_SECONDS = 120;
const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { Username } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [seconds, setSeconds] = useState(DEFAULT_SECONDS);
  const [triggerCounter, setTriggerCounter] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }

    if (formValue.Password != formValue.Password2) {
      setFormError((pre) => {
        return { ...pre, Password2: 'رمز عبور و تکرار آن با یکدیگر مطابقت ندارد!' };
      });
      return;
    }
    console.log('submitted', formValue);
    setLoading(true);
    const res = await handleChangePassword({
      Username,
      Token: formValue.Token,
      Password: formValue.Password
    });
    if (res.ok && res.data.Data) {
      navigate('/');
    } else {
      showToast('کد تایید اشتباه است!', 'error');
      console.log('error', res);
    }
    setLoading(false);
  }, [formValue, formError, Username]);

  useEffect(() => {
    setSeconds(DEFAULT_SECONDS);
    let secondds = DEFAULT_SECONDS;
    const timer = setInterval(() => {
      setSeconds((prev) => prev - 1);
      secondds--;
      console.log('here', secondds);
      if (secondds <= 0) {
        console.log('here');
        clearInterval(timer);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [Username, triggerCounter]);

  const sendAgain = async () => {
    setLoading(true);
    const res = await handleReqChangePassword({ Username });
    if (res.ok) {
      showToast('کد تایید با موفقیت ارسال شد.', 'success');
      setTriggerCounter(!triggerCounter);
    } else {
      console.log(res);
    }
    setLoading(false);
  };
  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={model}
      onSubmit={handleSubmit}
      checkTrigger="change"
      readOnly={loading}
      className="mt-4 text-dark">
      <h2 className="text-h2 text-primary text-dark text-center mb-4">فراموشی رمز عبور</h2>
      <TextField
        name="Token"
        label={`کد ارسال شده به (${Username}) را وارد کنید`}
        type="text"
        accepter={Input}
        dir="ltr"
        placeholder=""
        className="mb-t"
      />
      <IconTextField
        name="Password"
        label="رمز عبور حدید"
        type={visible ? 'text' : 'password'}
        accepter={Input}
        dir="ltr"
        onIconCLick={() => setVisible(!visible)}
        eyeVisible={visible}
        onIcon={<Show size="small" />}
        offIcon={<Hide size="small" />}
      />
      <div className="mb-2"></div>
      <IconTextField
        name="Password2"
        label="تکرار رمز عبور حدید"
        type={visible2 ? 'text' : 'password'}
        accepter={Input}
        dir="ltr"
        className="mt-2"
        onIconCLick={() => setVisible2(!visible2)}
        eyeVisible={visible2}
        onIcon={<Show size="small" />}
        offIcon={<Hide size="small" />}
      />

      <Link className="text-b3 text-[--rs-primary-500] mt-1 block" to="/">
        بازگشت{' '}
      </Link>
      <Button
        appearance={`primary`}
        type="button"
        className="w-[50%] mx-auto !block mt-4"
        onClick={sendAgain}
        loading={loading}
        disabled={seconds != 0}>
        {seconds
          ? `${seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60} : ${Math.floor(seconds / 60)}`
          : `ارسال مجدد کد`}
      </Button>
      <Button
        appearance="primary"
        type="submit"
        className="w-[50%] mx-auto !block mt-4"
        loading={loading}>
        تایید
      </Button>
    </Form>
  );
};

export const ResetPassword = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="h-[146px]">
        <img
          src={Logo}
          alt="Logo"
          className="block mx-auto mb-[-20px] mt-0 xl:mt-[-32px] max-w-[200px] w-full"
        />
      </div>
      <Card className="mx-auto relative">
        <span
          className="absolute  flex gap-1 items-center cursor-pointer"
          style={{ left: '12px', top: ' 12px' }}
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            navigate('/auth/login')
          }>
          <span className="w-[28px]">
            <BackIcon />
          </span>
        </span>
        <ResetPasswordForm />
      </Card>
    </div>
  );
};
