import { useEffect, useState } from 'react';
import { handleGetUserProfile } from '../../../api-handler';
import { CustomLoader } from './../../../components/basic/loader';
import { Col, FlexboxGrid } from 'rsuite';
import { formatIncomingPhoneNumber } from '../../../utils/helpers';

export const UserBasicDataView = (props: { username: string }) => {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetUserProfile(props.username);
      if (res.ok) {
        setData(res.data.Data);
      }
      setLoading(false);
    })();
  }, [props.username]);

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <h4 className="text-h4">اطلاعات کاربر</h4>
          <FlexboxGrid className="text-center mb-5 mt-5">
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="mb-4">
              <h5 className="mb-3">کد ملی</h5>
              <h4>{data.NID}</h4>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="mb-4">
              <h5 className="mb-3">نام و نام خانوادگی</h5>
              <h4>
                {data.FirstName} {data.LastName}
              </h4>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="mb-4">
              <h5 className="mb-3">شماره تماس:</h5>
              <h4 dir="ltr">{formatIncomingPhoneNumber(data.CellPhone)}</h4>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="mb-4">
              <h5 className="mb-3">ایمیل</h5>
              <h4>{data.Email}</h4>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="mb-4">
              <h5 className="mb-3">نام کاربری</h5>
              <h4>{data.Username}</h4>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </>
      )}
    </>
  );
};
