import { useParams } from 'react-router-dom';
import { Spacer } from '../../../components/basic/spacer';
import ConsultantConsultationBackground from '../../consultants/consultant-consultation-background';
import ConsultantEducationBackground from '../../consultants/consultant-education-background';
import ConsultantPersonal from '../../consultants/consultant-personal';
import ConsultantSocial from '../../consultants/consultant-social';
import ConsultantWorkBackground from '../../consultants/consultant-work-background';
import { SeekerPersonal } from '../../seekers/seeker-personal';
import { SeekerWorkBackground } from '../../seekers/seeker-work-background';
import { SeekerEducationBackground } from './../../seekers/seeker-education-background';
import { SeekerCourseBackground } from './../../seekers/seeker-course-background';
import { SeekerSkillBackground } from './../../seekers/seeker-skill-background';
import { SeekerDesire } from './../../seekers/seeker-desiere';
import { CorporationPersonal } from '../../corporation/corporation-personal';
import { CorporationIntro } from '../../corporation/corporation-intro';
import { CorporationGood } from '../../corporation/corporation-good';
import { CorporationService } from '../../corporation/corporation-service';
import { UserBasicDataView } from '../users/basic-data';
import SeekerSocial from '../../seekers/seeker-social';

export const AdminOverallProfile = () => {
  const { Username } = useParams();
  return (
    <>
      <UserBasicDataView username={Username ?? '-1'} />
      <h3 className="px-4 py-2 bg-[--rs-primary-100] rounded-md w-fit text-center mx-auto mb-2 text-[--rs-primary-600]">
        اطلاعات نقش کارجو
      </h3>
      <SeekerPersonal disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <SeekerWorkBackground disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <SeekerEducationBackground disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <SeekerCourseBackground disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <SeekerSkillBackground disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <SeekerDesire disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <SeekerSocial disabledMode={true} isAdmin={true} username={Username} />

      {/* مشاور */}
      <div
        style={{
          borderTop: '2px solid var(--rs-primary-500)',
          padding: 20,
          marginTop: 40
        }}></div>

      <h3 className="px-4 py-2 bg-[--rs-primary-100] rounded-md w-fit text-center mx-auto mb-2 text-[--rs-primary-600]">
        اطلاعات نقش مشاور
      </h3>
      <ConsultantPersonal disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <ConsultantWorkBackground disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <ConsultantEducationBackground disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <ConsultantConsultationBackground disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <ConsultantSocial disabledMode={true} isAdmin={true} username={Username} />

      <div
        style={{
          borderTop: '2px solid var(--rs-primary-500)',
          padding: 20,
          marginTop: 40
        }}></div>
      {/* کسب و کار */}
      <h3 className="px-4 py-2 bg-[--rs-primary-100] rounded-md w-fit text-center mx-auto mb-2 text-[--rs-primary-600]">
        اطلاعات نقش کسب و کار
      </h3>
      <CorporationPersonal disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <CorporationIntro disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <CorporationGood disabledMode={true} isAdmin={true} username={Username} />
      <Spacer s={4} />
      <CorporationService disabledMode={true} isAdmin={true} username={Username} />
    </>
  );
};
