import { Button, Checkbox } from 'rsuite';
import { useCallback, useEffect, useState } from 'react';

import {
  handlePostInqueryRegisterRequest,
  // handleGetInqueryRegisterRequest,
  handlePutInqueryRegisterRequest
} from '../../../api-handler';
import { showToast } from '../../../utils/toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomLoader } from '../../../components/basic/loader';
import { ROUTES } from '../../../router';

export const InqueryTnC = () => {
  const [searchParams] = useSearchParams();
  const [FormID] = useState(searchParams.get('FormID'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editMode] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (editMode) {
      const res = await handlePutInqueryRegisterRequest({
        // Group: 'Inquerys',
        Status: 'Pending',
        FormID
      });
      if (res.ok) {
        showToast('درخواست شما با موفقیت بروزرسانی شد.', 'success');
        // todo: navigate to next step
        navigate('/choose-role');
      }
      setLoading(false);
      return;
    }
    const res = await handlePostInqueryRegisterRequest(FormID);
    if (res.ok) {
      showToast('درخواست شما با موفقیت ثبت شد.', 'success');
      // todo: navigate to next step
      navigate('/choose-role');
    }
    setLoading(false);
  }, [editMode, FormID]);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      // const res = await handleGetInqueryRegisterRequest();
      // if (res.ok) {
      //   setEditMode(true);
      //   setIsChecked(true);
      // }
      setLoading(false);
    })();
  }, []);
  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4 flex justify-between items-center">
        <div>تعهدات زیر را با دقت بخوانید</div>
        <Button appearance="primary" onClick={() => navigate('/')}>
          لغو درخواست و بازگشت
        </Button>
      </h4>

      <div className="flex flex-col mt-4">
        <div className="max-h-[50vh] overflow-y-auto p-4 rounded-md bg-[--rs-primary-50] border-[1px] border-[--rs-primary-500] text-justify pretty-scroll mb-3">
          - سامانه جامع مشاوره کسب و کار با مجوز رسمی از وزارت تعاون، کار و رفاه اجتماعی مشغول به
          فعالیت می‌باشد.
          <br />
          <br />
          - سامانه جامع مشاوره کسب و کار پلتفرمی جهت ارتباط مشاوران، سرمایه‌گذاران و کارجویان با
          کارفرمایان و همچنین شرکت‌های متقاضی جهت جذب نیروی کار می‌باشد.
          <br />
          <br />
          - ارائه خدمات در این سایت بر مبنای قوانین و آیین نامه‌های موجود در تجارت الکترونیک و با
          رعایت کامل تمام قوانین جمهوری اسلامی ایران صورت می‌پذیرد.
          <br />
          <br />
          - کلیه حقوق برای شرکت پژواک جاوید مدیریت و نوآوری و سامانهcbcs.ir محفوظ بوده و هرگونه
          استفاده از مطالب صرفاً با مجوز کتبی این شرکت، مجاز است.
          <br />
          <br />
          - سامانه cbcs.ir مسئولیتی در رابطه با مطالب منتشر شده توسط کاربران ندارد.
          <br />
          <br />
          - سامانه cbcs.ir مسئولیتی در رابطه با مراحل جذب نیرو و توافق‌های صورت گرفته بین کارجویان و
          کارفرمایان ندارد.
          <br />
          <br />
          - سامانه cbcs.ir به حریم خصوصی کاربران خود احترام می‌گذارد و متعهد به حفاظت از اطلاعات
          شخصی است که شما در اختیار آن می‌گذارید.
          <br />
          <br />
          - کلیه کاربران می‌بایست با توجه به اصول قانونی، حرفه‌ای و اخلاقی، به صورت کاملاً صادقانه
          اقدام به ارائه اطلاعات خود نمایند.
          <br />
          <br />
          - با توجه به محدود بودن امکانات و گستردگی بسیار زیاد کاربران، سامانه cbcs.ir نمی‌تواند
          هیچگونه تعهدی نسبت به صحت، دقت و کامل بودن اطلاعات ثبت شده از سوی کارجویان و کارفرمایان در
          سامانه داشته باشد. اگرچه تا حد ممکن نسبت به حصول اطمینان از صحت و درستی اطلاعات ثبت شده
          توسط کاربران تلاش می‌کنیم، اما بدیهی است که بررسی صحت و درستی اطلاعات بر عهده کاربران
          خواهد بود.
          <br />
          <br />
          - مسئولیت کامل درج مطالب مغایر با قانون بر عهده شخص آگهی دهنده می‌باشد و سامانه cbcs.irدر
          این خصوص هیچ گونه مسئولیتی ندارد و در صورت درج مطالب مغایر قوانین جمهوری اسلامی ایران، از
          صفحه کاربر، حذف و در صورت تکرار، صفحه کاربر نیز بسته خواهد شد.
          <br />
          <br />
          - درج هرگونه مطالب مخالف عرف و شئونات اسلامی در سامانهcbcs.ir ممنوع است و سامانه حق دارد
          اطلاعات مزبور را حذف نماید. کلیه کاربران سامانه ملزم به رعایت حقوق اجتماعی و صیانت از
          ارزش‌های انسانی، اسلامی، ملی، فرهنگی و اجتماعی ایران می‌باشند.
          <br />
          <br />
          - در صورت مشاهده مطالب مغایر با قانون از سوی آگهی دهندگان ضمن اخطار به آگهی دهنده، از
          ادامه انتشار این نوع مطالب جلوگیری خواهد شد.
          <br />
          <br />
          - تمامی کاربران می‌بایست در هنگام استفاده از سامانه از عکس‌های مناسب و شخصی جهت استفاده در
          صفحه خود استفاده نمایند. کلیه این عکس‌ها بایستی مطابق با قوانین جمهوری اسلامی ایران باشد و
          کسی حق استفاده از عکس‌های اشخاص دیگر را ندارد.
          <br />
          <br />
          - تمام اطلاعات درج شده در سامانه cbcs.ir محفوظ بوده و نشر اطلاعات سایت برای هیچ فرد حقوقی
          و حقیقی مجاز نیست.
          <br />
          <br />
          - سامانه cbcs.ir این حق را داشته که در هر زمان کلیه فعالیت‌های سایت را متوقف نموده و با
          پرداخت باقیمانده حساب اعضا، کلیه فرایندهای سایت و فعالیت‌های مربوطه را به طور دائمی متوقف
          نماید و کاربر عضو حق هیچ گونه اعتراضی نسبت به این امر ندارد.
          <br />
          <br />
          - ثبت نام شما به عنوان کارجو، کسب و کار و یا مشاور در سامانه به منزله پذیرش کامل قوانین و
          مقررات مندرج روی سایت، به علاوه تبصره‌هایی است که ممکن است سامانه cbcs.ir به مجموعه قوانین
          مقررات اضافه کند.
          <br />
          <br />
          - سامانه cbcs.ir حق دارد شرایط این توافق‌نامه و قوانین و مقررات مندرج در تمامی بخش‌های
          سایت را بر حسب نیاز و صلاحدید خود اصلاح کرده و یا ویرایش نماید.
          <br />
          <br />
          - مسئولیت اطلاع از قوانین و مقررات سامانه cbcs.ir بر عهده استفاده کننده از سایت است و
          سامانه cbcs.ir هیچگونه مسئولیتی در قبال عدم اطلاع از قوانین مقررات را بر عهده ندارد.
          <br />
          <br />
        </div>
        <Checkbox
          checked={isChecked}
          dir="rtl"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(_value: any, checked: boolean, _event: any) => setIsChecked(checked)}>
          تمام موارد را خواندم و با تمامی موارد موافق هستم.
        </Checkbox>
        <div className="flex justify-between">
          <Button
            appearance="default"
            className="ml-auto mt-8"
            type="button"
            onClick={() => {
              navigate(`${ROUTES.roles.reqInquery.service}?FormID=${FormID}`);
            }}
            loading={loading}>
            مرحله قبل
          </Button>
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="button"
            onClick={handleSubmit}
            disabled={!isChecked}
            loading={loading}>
            ثبت درخواست
          </Button>
        </div>
      </div>
    </>
  );
};
