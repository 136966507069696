/* eslint-disable @typescript-eslint/no-explicit-any */
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

SwiperCore.use([Autoplay, Pagination]);
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Button, Col, FlexboxGrid } from 'rsuite';
// import { UPLOAD_FILE_URL } from '../../../core';
// import { base64ToJson } from '../../../utils/helpers';
import { useEffect, useState, useCallback } from 'react';
// import { handleGetCorporationGood } from '../../../api-handler';
import { CustomLoader } from '../../../components/basic/loader';
import { RegisterEvent } from './register-event';
// import { FormatDateTime } from '../../../utils/helpers';
// import { faIR } from 'date-fns-jalali/locale';
import { useParams, useNavigate } from 'react-router-dom';
import { handleGetWorkshop } from '../../../api-handler/workshop';
import { AdminWorkshopUsersList } from '../../admin/workshop-users/workshop-users-list';
import { useUser } from '../../../contexts/user';
import { UPLOAD_FILE_URL } from '../../../core';
import { base64ToJson } from '../../../utils/helpers';

export const Event = () => {
  const { isUserAdmin } = useUser();
  const navigate = useNavigate();
  const { EventID } = useParams();

  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const handleGetInitialData = useCallback(async (eventID: any) => {
    setLoading(true);
    const res3 = await handleGetWorkshop(eventID); //todo: change the api
    if (res3.ok) {
      setSelectedEvent(res3.data);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    handleGetInitialData(EventID);
  }, [EventID]);
  if (loading) return <CustomLoader />;
  return (
    <FlexboxGrid>
      <FlexboxGrid.Item
        as={Col}
        colspan={24}
        sm={24}
        md={24}
        className="max-w-[100%] mt-[-10px] !px-0">
        <div className="px-4 py-4">
          {selectedEvent && isUserAdmin() ? (
            <Button appearance={'primary'} onClick={() => navigate(`/admin/event/${EventID}`)}>
              ویرایش رویداد
            </Button>
          ) : null}
        </div>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true
          }}
          modules={[Autoplay]}>
          <SwiperSlide className=" p-0 overflow-hidden relative bg-[--rs-primary-50]">
            <div
              className="w-[70%] max-w-[500px] mx-auto  flex items-center justify-center aspect-[.7] sm:aspect-[.7] md:aspect-[.7] mt-8 border-[1px] border-[var(--rs-primary-300)] rounded-md"
              style={{ overflow: 'hidden' }}>
              <img
                src={`${UPLOAD_FILE_URL}?${base64ToJson(selectedEvent.PosterLink)[0]?.url}&Attachment=False`}
                className="min-w-[100%] min-h-[100%]"
                alt=""
              />
              <div className="absolute top-[20%] md:top-[40%] right-[5%] w-full"></div>
            </div>
          </SwiperSlide>
        </Swiper>
        {selectedEvent ? <RegisterEvent event={selectedEvent} /> : null}
        <div className="max-w-[1200px] w-[90%] mx-auto ">
          {selectedEvent && isUserAdmin() ? <AdminWorkshopUsersList /> : null}
        </div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};
