/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react';
import { EducationBackgroundForm } from './components/education-background-form';
import { PostSeekerEducationBackgroundModel } from '../../models';
import { Button } from 'rsuite';
import {
  handleDeleteSeekerEducationBackground,
  handleGetSeekerEducationBackground
} from '../../api-handler';
import { useNavigate } from 'react-router-dom';
// import { showToast } from '../../utils/toast';
import { CustomLoader } from './../../components/basic/loader';
import { showToast } from '../../utils/toast';
import { ROUTES } from '../../router';
import { SeekerEducationBackgroundView } from '../public/seekers/view/seeker-education-background';

export const SeekerEducationBackground = (props: {
  disabledMode?: boolean;
  isAdmin: boolean;
  username?: string;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(true);

  const [data, setData] = useState<(PostSeekerEducationBackgroundModel | null)[]>([]);
  const [showNew, setShowNew] = useState(true);

  const handleAddDoingData = (newData: PostSeekerEducationBackgroundModel | null) => {
    return setData((prev) => {
      return [...prev, newData];
    });
  };

  const handleUpdateDoingData = useCallback(
    (updatedData: PostSeekerEducationBackgroundModel) => {
      const theUpdatedData = data.map((item: any) =>
        item.ID === updatedData.ID ? { ...updatedData } : item
      );
      return setData(theUpdatedData);
    },
    [data]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);

      const res = await handleGetSeekerEducationBackground(props.isAdmin ? props.username : null);
      if (res.ok) {
        setData(res.data);
        console.log(editMode);
        setEditMode(true);
        setShowNew(false);
      }
      setLoading(false);
    })();
  }, [props.username]);

  const onDelete = async (id: string | number | null) => {
    if (!id) {
      setShowNew(false);
    } else {
      const dataCopy = [...data];
      setData(dataCopy.filter((item) => item?.ID !== id));
      try {
        const res = await handleDeleteSeekerEducationBackground(id);
        if (res.ok) {
          showToast('با موفقیت حذف شد.', 'success');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <>
      <h4 className="text-h4">
        {props.isAdmin ? 'سوابق تحصیلی' : 'سوابق تحصیلی خود را وارد کنید'}
      </h4>

      {data.map((item) => {
        return (
          <EducationBackgroundForm
            key={`${item?.ID}`}
            handleAddDoingData={handleAddDoingData}
            handleUpdateDoingData={handleUpdateDoingData}
            readonly={true}
            hideNextButton={false}
            defaultValue={item}
            isAdmin={props.isAdmin}
            disabledMode={props.disabledMode}
            hasEditMode={editMode}
            onDelete={onDelete}
          />
        );
      })}

      {editMode && showNew && !props.isAdmin ? (
        <EducationBackgroundForm
          handleAddDoingData={handleAddDoingData}
          handleUpdateDoingData={handleUpdateDoingData}
          forceReadOnly={loading}
          isAdmin={props.isAdmin}
          disabledMode={false}
          isLast={true}
          isFirst={data.length < 1}
          onDelete={onDelete}
        />
      ) : null}

      {editMode && !showNew && !props.isAdmin ? (
        <Button
          appearance="primary"
          className="ml-auto mt-4"
          type="button"
          onClick={() => setShowNew(true)}>
          افزودن سابقه جدید
        </Button>
      ) : null}

      {!editMode && data.length && showNew ? (
        <EducationBackgroundForm
          handleAddDoingData={handleAddDoingData}
          handleUpdateDoingData={handleUpdateDoingData}
          forceReadOnly={loading}
          disabledMode={props.disabledMode}
          isLast={true}
          isAdmin={props.isAdmin}
          isFirst={data.length < 2}
          onDelete={onDelete}
        />
      ) : !editMode && !props.disabledMode ? (
        <Button
          appearance="primary"
          className="ml-auto mt-4"
          type="button"
          onClick={() => handleAddDoingData(null)}>
          افزودن سابقه جدید
        </Button>
      ) : null}

      {editMode && !props.disabledMode && !showNew ? (
        <div className="flex justify-between">
          <Button
            appearance="default"
            className="ml-auto mt-8"
            type="button"
            onClick={() => {
              navigate(ROUTES.roles.reqSeeker.workBackground);
            }}
            loading={loading}>
            مرحله قبل
          </Button>
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="button"
            onClick={() => {
              navigate(ROUTES.roles.reqSeeker.courseBackground);
            }}
            loading={loading}>
            مرحله بعد
          </Button>
        </div>
      ) : null}

      {data.length ? (
        <div className="mt-5">
          <div className="bg-[--rs-primary-100] text-[--rs-primary-500] font-bold py-2 text-center rounded-md mb-3">
            پیش نمایش
          </div>
          <SeekerEducationBackgroundView data={data} canSeeFull={true} />
        </div>
      ) : null}
    </>
  );
};
