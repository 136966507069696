/* eslint-disable @typescript-eslint/no-explicit-any */
import { faIR } from 'date-fns-jalali/locale';
import { FormatDateToBeautifulMonthAndYear } from '../../../../utils/helpers';
import { Divider } from 'rsuite';

export const SeekerEducationBackgroundView = ({ data }: any) => {
  return (
    <>
      {/* <h4 className="mb-4">سوابق تحصیلی</h4> */}
      <div className="w-full">
        <Divider className="text-[20px] font-bold">سوابق تحصیلی</Divider>
      </div>
      <ol style={{ listStyle: 'auto', paddingRight: '12px' }}>
        {data?.map((education: any) => {
          return (
            <li key={education.id} className="mb-4">
              <p className="text-[16px]">
                دانشجوی رشته <strong>{education.Field}</strong>{' '}
                {education.Orientation ? (
                  <>
                    با گرایش <strong>{education.Orientation}</strong>{' '}
                  </>
                ) : null}
                در مقطع <strong>{education.Grade}</strong>، از{' '}
                <strong>{FormatDateToBeautifulMonthAndYear(education.Start_Date, faIR)}</strong>{' '}
                تحصیلات خود را در <strong>{education.Name}</strong> آغاز کرده‌ام{' '}
                {education.StudyNow ? (
                  <>
                    و هم اکنون <strong> مشغول به تحصیل </strong> هستم.
                  </>
                ) : (
                  <>
                    و با معدل <strong> {education.Average}</strong>
                    {'، '}
                    <strong>
                      {FormatDateToBeautifulMonthAndYear(education.End_Date, faIR)}
                    </strong>{' '}
                    تحصیلات خود را به پایان رسانده‌ام.
                  </>
                )}
                <br />
                <strong>{education.Name}</strong>، در کشور <strong>{education.Country}</strong>
                {'، '}
                {education.State ? (
                  <>
                    {' '}
                    استان <strong>{education.State}</strong>،{' '}
                  </>
                ) : (
                  ''
                )}
                شهر <strong>{education.City}</strong>، <strong>{education.Address}</strong> واقع شده
                است. (شماره تماس محل تحصیل: <strong>{education.Number}</strong>)
              </p>
              <p className="text-justify">
                {education.Description ? <>- {education.Description}</> : ''}
              </p>
            </li>
          );
        })}
      </ol>
    </>
  );
};
