import { Button } from 'rsuite';
import { useCallback, useEffect, useState } from 'react';

import {
  handlePostConsultantRegisterRequest,
  handleGetConsultantRegisterRequest,
  handlePutConsultantRegisterRequest
} from '../../api-handler';
import { showToast } from '../../utils/toast';
import { useNavigate } from 'react-router-dom';
import { CustomLoader } from './../../components/basic/loader';
import { ROUTES } from '../../router';
import ConsultantPersonal from './consultant-personal';
import { Spacer } from '../../components/basic/spacer';
import ConsultantWorkBackground from './consultant-work-background';
import ConsultantEducationBackground from './consultant-education-background';
import { ConsultantConsultationBackground } from './consultant-consultation-background';
import ConsultantSocial from './consultant-social';
import { useUser } from '../../contexts/user';

export const ConsultantTnC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (editMode) {
      const res = await handlePutConsultantRegisterRequest({
        Group: 'Consultants',
        Status: 'Pending'
      });
      if (res.ok) {
        showToast('درخواست شما با موفقیت بروزرسانی شد.', 'success');
        // todo: navigate to next step
        navigate('/choose-role');
      }
      setLoading(false);
      return;
    }
    const res = await handlePostConsultantRegisterRequest({ Group: 'Consultants' });
    if (res.ok) {
      showToast('درخواست شما با موفقیت ثبت شد.', 'success');
      // todo: navigate to next step
      navigate('/choose-role');
    }
    setLoading(false);
  }, [editMode]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetConsultantRegisterRequest();
      if (res.ok) {
        setEditMode(true);
      }
      setLoading(false);
    })();
  }, []);
  if (loading) {
    return <CustomLoader />;
  }
  return (
    <>
      <h4 className="text-h4 text-center">از صحت اطلاعات وارد شده مطمئن شوید</h4>

      <ConsultantPersonal disabledMode={true} isAdmin={true} username={user.Username} />
      <Spacer s={4} />
      <ConsultantWorkBackground disabledMode={true} isAdmin={true} username={user.Username} />
      <Spacer s={4} />
      <ConsultantEducationBackground disabledMode={true} isAdmin={true} username={user.Username} />
      <Spacer s={4} />
      <ConsultantConsultationBackground
        disabledMode={true}
        isAdmin={true}
        username={user.Username}
      />
      <Spacer s={4} />
      <ConsultantSocial disabledMode={true} isAdmin={true} username={user.Username} />

      <div className="flex flex-col mt-4">
        <div className="flex justify-between">
          <Button
            appearance="default"
            className="ml-auto mt-8"
            type="button"
            onClick={() => {
              navigate(ROUTES.roles.reqConsultant.social);
            }}
            loading={loading}>
            مرحله قبل
          </Button>
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="button"
            onClick={handleSubmit}
            loading={loading}>
            ثبت درخواست
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConsultantTnC;
